/**
 * Labstep
 *
 * @module components/JupyterInstance/RunStatus
 * @desc Displays a jupyterInstance run status
 */

import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import { JupyterInstanceStatus } from 'labstep-web/models/jupyter-instance.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { RunStatusProps } from './types';

export const RunStatus: React.FC<RunStatusProps> = ({
  jupyterInstance,
}) => {
  if (
    jupyterInstance &&
    jupyterInstance.ended_at &&
    jupyterInstance.status === JupyterInstanceStatus.success
  ) {
    return (
      <Icon
        name={ICONS.jupyter_instance.status.success}
        color="green"
        popup={{
          content: `Run successfully on ${humanReadableDate(
            jupyterInstance.ended_at,
          )}`,
        }}
      />
    );
  }
  if (
    jupyterInstance &&
    jupyterInstance.ended_at &&
    jupyterInstance.status === JupyterInstanceStatus.error
  ) {
    return (
      <Icon
        name={ICONS.jupyter_instance.status.error}
        color="red"
        popup={{
          content: `Run with errors on ${humanReadableDate(
            jupyterInstance.ended_at,
          )}`,
        }}
      />
    );
  }
  return null;
};

export default RunStatus;
