/**
 * Labstep
 */

import UserPreview from 'labstep-web/components/User/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  OrderRequest,
  OrderRequestStatus,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';
import React from 'react';

export const colDefLogAuthor = (
  status: OrderRequestStatus,
): ColDef<OrderRequest> => ({
  colId: `${status}_log_author`,
  headerName: `${OrderRequestStatusValues[status]} by`,
  headerComponentParams: {
    icon: ICONS.user.primary,
  },
  cellRenderer: (params) => {
    const user = params.data.getStatusLog(status)?.author;
    return user ? <UserPreview user={user} /> : null;
  },
  valueGetter: (params) =>
    params.data.getStatusLog(status)?.author?.idAttr || null,
  editable: false,
});
