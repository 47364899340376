/**
 * Labstep
 *
 * @module models/jupyter-notebook
 * @desc Typescript export class for JupyterNotebook
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { JupyterInstance } from 'labstep-web/models/jupyter-instance.model';

export class JupyterNotebook extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'jupyter_notebook';

  get entityName(): string {
    return JupyterNotebook.entityName;
  }

  get label(): string {
    return this.name || 'Untitled Notebook';
  }

  name: string;

  data: any;

  @Type(() => JupyterInstance)
  jupyter_instance_edit: JupyterInstance;

  @Type(() => JupyterInstance)
  jupyter_instance_run: JupyterInstance;

  static icon = '/img/jupyter-notebook/python.svg';

  constructor(data: Partial<JupyterNotebook> = {}) {
    super();
    Object.assign(this, data);
  }
}
