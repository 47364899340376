import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import Select from 'labstep-web/core/Select';
import { useToggle } from 'labstep-web/hooks/toggle';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import React, { useState } from 'react';
import { FormatOptionLabelMeta } from 'react-select';
import EntityActionDelete from '../../Entity/Action/Delete';
import { EntityUserRoleEditModal } from '../EditModal';
import styles from './styles.module.scss';
import { IEntityUserRoleSelectPanelProps, Option } from './types';
import { getPlaceholder } from './utils';

export const Group: React.FC<{ label: string }> = (props) => {
  return (
    <div>
      <div className={styles.groupHeading}>
        {props.label}
        <hr />
      </div>
      {props.children}
    </div>
  );
};

export const EntityUserRoleSelectPanel: React.FC<
  IEntityUserRoleSelectPanelProps
> = ({
  entityType,
  systemOptions,
  group,
  onChange,
  onBlur,
  allowDelete,
  defaultSelectedRoleId,
  openMenuOnFocus,
}) => {
  const [selectedEntityUserRoleId, setSelectedEntityUserRoleId] =
    useState<EntityUserRole['id'] | null>(
      defaultSelectedRoleId ?? null,
    );

  const selectedEntityUserRole = selectedEntityUserRoleId
    ? group.entity_user_roles.find(
        (e) => e.id === selectedEntityUserRoleId,
      )
    : null;

  const [searchText, setSearchText] = useState<string>('');

  const newRoleOption: Option = {
    label: searchText === '' ? 'New Role' : `New Role: ${searchText}`,
    value: 'custom',
    description: '',
    icon: 'add',
  };

  const options: Option[] = [
    ...group.entity_user_roles.map(
      (role) =>
        ({
          label: role.name,
          value: role.id,
          description: role.description,
          icon: 'user circle',
          entity: role,
        }) as Option,
    ),
    newRoleOption,
  ];

  const handleInputChange = (searchInput: string) => {
    setSearchText(searchInput);
  };

  const [isToggled, toggle] = useToggle();

  return (
    <EntityCreateContainer
      entityName={EntityUserRole.entityName}
      parentId={group.id}
      parentName={group.entityName}
    >
      {({ create, status }) => (
        <>
          <Select
            isCreateable
            autoFocus
            openMenuOnFocus={openMenuOnFocus}
            onBlur={!isToggled && onBlur}
            isDisabled={status && status.isFetching}
            onChange={(option: Option) => {
              if (option.value === 'custom') {
                create(
                  { name: searchText || null },
                  {
                    onSuccess: ({ response }) => {
                      const id = response.result;
                      setSelectedEntityUserRoleId(id);
                    },
                  },
                );
                toggle();
              }
              onChange(option.entity);
              setSelectedEntityUserRoleId(
                option.value as EntityUserRole['id'],
              );
            }}
            onInputChange={handleInputChange}
            options={options}
            components={{
              Group,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            formatOptionLabel={(
              option: Option,
              data: FormatOptionLabelMeta<Option>,
            ) => {
              return (
                <div className={styles.groupOption}>
                  {option.icon && <Icon name={option.icon} />}
                  <div>
                    <p className={styles.optionLabel}>
                      {option.label}
                    </p>
                    <p className={styles.optionDescription}>
                      {option.description}
                    </p>
                  </div>
                  <div className={styles.configureButton}>
                    {!systemOptions[0].options.includes(option) &&
                      option.value !== 'custom' && (
                        <Dropdown
                          data-test-id="entity-user-role-configure-dropdown"
                          pointing="top right"
                          icon={
                            data.context === 'menu'
                              ? 'ellipsis horizontal'
                              : 'chevron down'
                          }
                          trigger=""
                        >
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedEntityUserRoleId(
                                option.entity
                                  ?.id as EntityUserRole['id'],
                              );
                              toggle();
                            }}
                          >
                            <Icon name="cog" />
                            Configure
                          </Dropdown.Item>
                          {allowDelete && option.entity && (
                            <EntityActionDelete
                              entity={option.entity}
                            />
                          )}
                        </Dropdown>
                      )}
                  </div>
                </div>
              );
            }}
            value={
              selectedEntityUserRole
                ? options.filter(
                    (option) =>
                      option.entity === selectedEntityUserRole,
                  )
                : getPlaceholder(entityType)
            }
            formatSelectedOption={(option: Option) => (
              <div className={styles.groupOption}>
                <div>
                  <p className={styles.optionLabel}>{option.label}</p>
                  <p className={styles.optionDescription}>
                    {option.description}
                  </p>
                </div>
              </div>
            )}
          />
          {isToggled && selectedEntityUserRole && (
            <EntityUserRoleEditModal
              entityUserRole={selectedEntityUserRole}
              onClose={() => {
                onChange(selectedEntityUserRole);
                setSelectedEntityUserRoleId(null);
              }}
            />
          )}
        </>
      )}
    </EntityCreateContainer>
  );
};
