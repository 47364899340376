/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Text/Count
 * @desc Count text for organization plan card
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import SubHeader from 'labstep-web/core/SubHeader';
import { OrganizationPlanCardTextCountProps } from './types';

export const OrganizationPlanCardTextCount: React.FC<
  OrganizationPlanCardTextCountProps
> = ({ count, text }) => (
  <Flex vAlignContent="center">
    <Header noMargin>{count}</Header>
    <SubHeader style={{ marginLeft: 10 }}>{text}</SubHeader>
  </Flex>
);

export default OrganizationPlanCardTextCount;
