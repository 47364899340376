/**
 * Labstep
 *
 * @module components/OutboundOrderRequest/Form/ShowEdit/Price
 * @desc ShowEdit form for order request currency and price
 */

import React from 'react';
import ShowEditAmount from 'labstep-web/core/Form/ShowEdit/Amount';
import { fieldOutboundOrderRequestAmount } from 'labstep-web/components/OutboundOrderRequest/Form/fields';
import { IOutboundOrderRequestFormShowEditAmountProps } from './types';

export const OutboundOrderRequestFormShowEditAmount: React.FC<
  IOutboundOrderRequestFormShowEditAmountProps
> = ({ outboundOrderRequest }) => (
  <ShowEditAmount
    currency={outboundOrderRequest.currency}
    entity={outboundOrderRequest}
    entityName={outboundOrderRequest.entityName}
    field={fieldOutboundOrderRequestAmount}
  >
    {outboundOrderRequest.printAmount}
  </ShowEditAmount>
);

export default OutboundOrderRequestFormShowEditAmount;
