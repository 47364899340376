/**
 * Labstep
 *
 * @module screens/Resource/Index/LeftPane
 * @desc Resource Index LeftPane
 */

import ResourceFilterPane from 'labstep-web/components/Resource/Filter/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import React from 'react';
import EntityTemplatePane from 'labstep-web/components/Entity/Template/Pane';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import { Resource } from 'labstep-web/models/resource.model';
import { IResourceIndexLeftPaneProps } from './types';

export const ResourceIndexLeftPane: React.FC<
  IResourceIndexLeftPaneProps
> = ({ links }) => (
  <LayoutLeftPane links={links}>
    <EntityTemplatePane
      entityPreview={({ entity, ...rest }): React.ReactElement => (
        <ResourceTemplatePreview
          resource={entity as Resource}
          {...rest}
        />
      )}
      entityName={Resource.entityName}
    />
    <ResourceFilterPane />
  </LayoutLeftPane>
);

export default ResourceIndexLeftPane;
