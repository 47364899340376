/**
 * Labstep
 *
 * @module core/Modal/Wizard
 * @desc Wizard modal
 */

import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import Modal from '..';
import ModalConfirmation from '../Confirmation';
import { IModalWizardProps } from './types';
import ModalWizardHeader from './Header';
import ModalWizardStep from './Step';
import { ModalWizardContext } from './context';
import styles from './styles.module.scss';

export const ModalWizard = <TMode extends 'mini' | 'fullscreen'>({
  routeId,
  steps,
  title,
  onFinish: onFinishProp,
  mode,
  onClose: onCloseProp,
  status,
  trigger,
  startIndex = 0,
  children,
  modalConfirmationProps,
  className,
  closeIcon = true,
  ...rest
}: IModalWizardProps<TMode>) => {
  const { open, toggleModal } = useModalRoute(routeId);
  const [activeStepIndex, setActiveStepIndex] = useState(startIndex);
  const [errorMessages, setErrorMessages] = React.useState<string[]>(
    [],
  );
  const [showConfirmationModal, setShowConfirmationModal] =
    useState(false);

  const resetConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const reset = useCallback(() => {
    setActiveStepIndex(0);
    setErrorMessages([]);
    toggleModal();
    resetConfirmationModal();
  }, [
    setActiveStepIndex,
    setErrorMessages,
    toggleModal,
    resetConfirmationModal,
  ]);

  const onClose = useCallback(() => {
    onCloseProp?.();
    reset();
  }, [onCloseProp, reset]);

  const onCloseWithConfirmation = useCallback(() => {
    if (modalConfirmationProps) {
      setShowConfirmationModal(true);
    } else {
      onClose();
    }
  }, [modalConfirmationProps, onClose]);

  const onFinish = useCallback(() => {
    onFinishProp?.();
    onClose();
  }, [onFinishProp, onClose]);

  const isLastStep = useMemo(() => {
    return activeStepIndex === steps.length - 1;
  }, [activeStepIndex, steps.length]);

  const goBack = useCallback(() => {
    setActiveStepIndex(activeStepIndex - 1);
  }, [activeStepIndex, setActiveStepIndex]);

  const goForward = useCallback(
    (onContinue) => {
      const fn = () => {
        if (isLastStep) {
          onFinish?.();
        } else {
          setActiveStepIndex(activeStepIndex + 1);
        }
        setErrorMessages([]);
      };
      if (onContinue) {
        onContinue({
          goForward: fn,
          goBack,
          setErrorMessages,
        });
      } else {
        fn();
      }
    },
    [
      activeStepIndex,
      setActiveStepIndex,
      onFinish,
      isLastStep,
      goBack,
    ],
  );

  const value = useMemo(
    () => ({
      activeStepIndex,
      setActiveStepIndex,
      goBack,
      goForward,
      startIndex,
      isLastStep,
      status,
      errorMessages,
      steps,
    }),
    [
      activeStepIndex,
      setActiveStepIndex,
      goBack,
      goForward,
      startIndex,
      isLastStep,
      status,
      errorMessages,
      steps,
    ],
  );

  return (
    <>
      <ModalConfirmation
        {...modalConfirmationProps}
        open={showConfirmationModal}
        onClose={resetConfirmationModal}
        onCancel={resetConfirmationModal}
        onConfirm={() => {
          modalConfirmationProps?.onConfirm?.({
            toggleModal: onClose,
          });
        }}
      />
      <Modal
        className={classNames(className, {
          [styles.mini]: mode === 'mini',
        })}
        size={mode === 'fullscreen' ? mode : 'large'}
        closeIcon={closeIcon}
        open={open}
        onClose={onCloseWithConfirmation}
        trigger={trigger?.({ toggleModal })}
        {...rest}
      >
        <ModalWizardContext.Provider value={value}>
          <ModalWizardHeader
            title={title}
            steps={steps}
            activeStepIndex={activeStepIndex}
          />
          {children}
        </ModalWizardContext.Provider>
      </Modal>
    </>
  );
};

ModalWizard.Step = ModalWizardStep;

export default ModalWizard;
