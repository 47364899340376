/**
 * Labstep
 *
 * @desc This card is for displaying information and selecting different pricing tiers.
 * It can contain many feature details and can display an optional discount in the pricing section.
 */

import classNames from 'classnames';
import { intervalAdverb } from 'labstep-web/services/date.service';
import React from 'react';
import { Image } from 'semantic-ui-react';
import Card from 'labstep-web/core/Card/Card';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import { IPricingTierCardProps } from './types';
import styles from './styles.module.scss';

export const PricingTierCard: React.FC<IPricingTierCardProps> = ({
  items,
  logoSrc,
  description,
  customLabel,
  priceMonthly,
  priceBeforeDiscount,
  billingPeriod,
  onClick,
  isSelected = false,
  priceColor = 'blue',
}) => (
  <Card
    className={classNames(styles.card, {
      [styles.selected]: isSelected,
    })}
    onClick={onClick}
  >
    <Image src={logoSrc} className={styles.logo} />
    {description}
    <div className={styles.features}>
      {items.map((item) => (
        <TextWithIcon
          key={item.id}
          text={item.text}
          icon={item.icon}
          iconProps={{
            color: item.icon === 'check' ? 'green' : 'grey',
          }}
        />
      ))}
    </div>

    <div className={styles.pricing}>
      <div className={styles.priceBeforeDiscount}>
        {priceBeforeDiscount}
      </div>
      <div
        className={classNames(styles.price, {
          [styles[priceColor]]: true,
        })}
      >
        {customLabel}
        {priceMonthly}
      </div>
      {priceMonthly && (
        <div className={styles.billingPeriod}>
          <span className={styles.userPerMonth}>user / month</span>
          <br />
          {/**
           * TODO: Use TextSecondary but this needs to be refactored first.
           * See https://linear.app/labstep/issue/LAB-540/replace-textsecondary-with-span-and-create-actual-textsecondary.
           */}
          <span className={styles.textSecondary}>
            billed {intervalAdverb[billingPeriod]}
          </span>
        </div>
      )}
    </div>
  </Card>
);
export default PricingTierCard;
