/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Price/Select/Interval
 * @desc Select interval
 */

import React from 'react';
import {
  intervalLabels,
  supportedIntervals,
} from 'labstep-web/constants/organization-plan-price';
import LabelToggle from 'labstep-web/core/Label/Toggle';
import Label from 'labstep-web/core/Label';
import { PricingService } from 'labstep-web/services/pricing.service';
import styles from '../styles.module.scss';
import { OrganizationPlanManagerPriceSelectIntervalProps } from './types';

export const OrganizationPlanManagerPriceSelectInterval: React.FC<
  OrganizationPlanManagerPriceSelectIntervalProps
> = ({ activeInterval, setActiveInterval }) => (
  <div className={styles.container}>
    <LabelToggle
      header="Billing Cycle"
      items={supportedIntervals.map((interval) => {
        const discount = PricingService.intervalDiscount[interval];
        return {
          id: interval,
          text: intervalLabels[interval],
          action: discount ? (
            <Label size="mini" color="green">
              Save {discount * 100}%
            </Label>
          ) : null,
        };
      })}
      onClick={(item) =>
        setActiveInterval(
          item.id as (typeof supportedIntervals)[number],
        )
      }
      activeItemId={activeInterval}
    />
  </div>
);

export default OrganizationPlanManagerPriceSelectInterval;
