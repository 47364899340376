/**
 * Labstep
 *
 * @module components/OutboundPurchaseOrder/Form/ShowEdit/Discount
 * @desc ShowEdit form for order request currency and handling
 */

import React from 'react';
import ShowEditAmount from 'labstep-web/core/Form/ShowEdit/Amount';
import { fieldOutboundPurchaseOrderDiscountAmount } from 'labstep-web/components/OutboundPurchaseOrder/Form/fields';
import { IOutboundPurchaseOrderFormShowEditDiscountProps } from './types';

export const OutboundPurchaseOrderFormShowEditDiscount: React.FC<
  IOutboundPurchaseOrderFormShowEditDiscountProps
> = ({ outboundPurchaseOrder }) => (
  <ShowEditAmount
    entity={outboundPurchaseOrder}
    entityName={outboundPurchaseOrder.entityName}
    field={fieldOutboundPurchaseOrderDiscountAmount}
    currency={outboundPurchaseOrder.currency}
  >
    {outboundPurchaseOrder.printDiscount}
  </ShowEditAmount>
);

export default OutboundPurchaseOrderFormShowEditDiscount;
