/**
 * Labstep
 */

import { orderBy } from 'lodash';
import { format } from 'labstep-web/services/date.service';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { IEvent, IResource } from './types';

/**
 * Formats experimentWorkflow into FullCalendar events
 * experimentWorkflow is passed through extendedProps
 *
 * @function
 * @param  {ExperimentWorkflow} experimentWorkflow
 */
export const formatEvent = (
  experimentWorkflow: ExperimentWorkflow,
  disabled?: boolean,
): IEvent => {
  return {
    id: experimentWorkflow.id.toString(),
    title: experimentWorkflow.displayName,
    start: experimentWorkflow.activeStart,
    end: experimentWorkflow.activeEnd,
    extendedProps: { experimentWorkflow },
    resourceId: experimentWorkflow.id.toString(),
    editable: !disabled,
    resourceEditable: false,
  };
};

/**
 * Formats experimentWorkflow into FullCalendar resources
 * experimentWorkflow is passed through extendedProps
 *
 * @function
 * @param  {ExperimentWorkflow} experimentWorkflow
 */
export const formatResource = (
  experimentWorkflow: ExperimentWorkflow,
  index: number,
): IResource => {
  return {
    id: experimentWorkflow.id.toString(),
    extendedProps: { experimentWorkflow },
    sortOrder: index + 1,
  };
};

/**
 * Convert experimentWorkflows into events and resources for FullCalendar
 * Add createAction and showMoreAction to resources
 *
 * @function
 * @param  {ExperimentWorkflow[]} experimentWorkflows
 */
export const createEventsAndResources = (
  experimentWorkflows: ExperimentWorkflow[],
  experimentWorkflowsCanEdit: { [key: number]: boolean },
  disabled?: boolean,
): [IEvent[], IResource[]] => {
  const events = experimentWorkflows.map((experimentWorkflow) =>
    formatEvent(
      experimentWorkflow,
      disabled || !experimentWorkflowsCanEdit[experimentWorkflow.id],
    ),
  );
  const experimentWorkflowsSorted = orderBy(experimentWorkflows, [
    'started_at',
    'start_planned_at',
  ]);
  const resources = experimentWorkflowsSorted.map(formatResource);

  resources.push({
    id: 'createActionTop',
    extendedProps: { experimentWorkflow: null },
    sortOrder: 0,
  });

  resources.push({
    id: 'showMoreAction',
    extendedProps: { experimentWorkflow: null },
    sortOrder: experimentWorkflows.length + 1,
  });

  resources.push({
    id: 'createActionBottom',
    extendedProps: { experimentWorkflow: null },
    sortOrder: experimentWorkflows.length + 2,
  });

  return [events, resources];
};

/**
 * Creates body based on experimentWorkflow status
 *
 * @function
 * @param  {ExperimentWorkflow} experimentWorkflow
 * @param  {string} start
 * @param  {string} end
 */
export const createBody = (
  experimentWorkflow: ExperimentWorkflow,
  start: string,
  end: string,
) => {
  let body = {};
  const [fStart, fEnd] = [format(start), format(end)];
  if (experimentWorkflow.statusType === 'unstarted') {
    body = { start_planned_at: fStart, end_planned_at: fEnd };
  }
  if (experimentWorkflow.statusType === 'started') {
    body = { started_at: fStart, end_planned_at: fEnd };
  }
  if (experimentWorkflow.statusType === 'completed') {
    body = { started_at: fStart, ended_at: fEnd };
  }
  return body;
};
