/**
 * Labstep
 *
 * @module models/outbound-order-request
 * @desc Typescript export class for OutboundPurchaseOrder
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { User } from 'labstep-web/models/user.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { OutboundOrderRequest } from 'labstep-web/models/outbound-order-request.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';

export class OutboundPurchaseOrder extends Entity {
  static readonly entityName = 'outbound_purchase_order';

  get entityName(): typeof OutboundPurchaseOrder.entityName {
    return OutboundPurchaseOrder.entityName;
  }

  constructor(data: Partial<OutboundPurchaseOrder> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  @Type(() => User)
  author: User;

  @Type(() => PurchaseOrder)
  purchase_order: PurchaseOrder;

  name: string;

  outbound_order_request_count: number;

  amount_total: number;

  handling_amount: number;

  discount_amount: number;

  currency: Currency;

  tax_rate: number;

  amount_sub_total: number;

  @Type(() => OutboundOrderRequest)
  order_requests: OutboundOrderRequest[];

  get printSubTotal(): string {
    return CurrencyService.print(
      this.amount_sub_total,
      this.currency,
    );
  }

  get printHandling(): string {
    return CurrencyService.print(this.handling_amount, this.currency);
  }

  get printDiscount(): string {
    return `-${CurrencyService.print(
      this.discount_amount,
      this.currency,
    )}`;
  }

  get printTotal(): string {
    return CurrencyService.print(this.amount_total, this.currency);
  }

  get totalWithVat(): number {
    return this.amount_total * (1 + (this.tax_rate || 0) / 100);
  }

  get printVat(): string {
    if (this.tax_rate === null) {
      return '';
    }
    return `${this.tax_rate}%`;
  }

  get printTotalWithVat(): string {
    return CurrencyService.print(this.totalWithVat, this.currency);
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
