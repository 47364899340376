/**
 * Labstep
 *
 * @module components/OutboundPurchaseOrder/Form/ShowEdit/TaxRate
 * @desc ShowEdit form for order request currency and handling
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldOutboundPurchaseOrderTaxRate } from 'labstep-web/components/OutboundPurchaseOrder/Form/fields';
import { IOutboundPurchaseOrderFormShowEditTaxRateProps } from './types';

export const OutboundPurchaseOrderFormShowEditTaxRate: React.FC<
  IOutboundPurchaseOrderFormShowEditTaxRateProps
> = ({ outboundPurchaseOrder }) => (
  <ShowEditText
    entity={outboundPurchaseOrder}
    entityName={outboundPurchaseOrder.entityName}
    field={fieldOutboundPurchaseOrderTaxRate}
  >
    {outboundPurchaseOrder.printVat}
  </ShowEditText>
);

export default OutboundPurchaseOrderFormShowEditTaxRate;
