/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Users
 * @desc Organization plan card for users
 */

import React from 'react';
import Link from 'labstep-web/core/Link';
import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import styles from '../styles.module.scss';
import { OrganizationPlanCardUsersProps } from './types';

export const OrganizationPlanCardUsers: React.FC<
  OrganizationPlanCardUsersProps
> = ({ organization }) => {
  let primaryText: React.ReactNode = organization.seats;
  if (organization.max_seats) {
    if (organization.noSeatsLeft) {
      primaryText = (
        <>
          <span className={styles.red}>{organization.seats}</span> /{' '}
          {organization.max_seats}
        </>
      );
    } else {
      primaryText = `${organization.seats} / ${organization.max_seats}`;
    }
  }
  return (
    <OrganizationPlanCard
      icon="users"
      header="Users"
      primary={<p>{primaryText}</p>}
      secondary={
        <>
          {organization.noSeatsLeft && (
            <p className={styles.red}>
              Contact{' '}
              <a href="mailto:billing@labbstep.com">
                info@labstep.com
              </a>{' '}
              to increase the User Limit
            </p>
          )}
          <Link
            to="organization_show_users"
            params={{ identifier: organization.identifier }}
          >
            View users
          </Link>
        </>
      }
    />
  );
};

export default OrganizationPlanCardUsers;
