/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/NextPaymentDate
 * @desc Organization plan card for next payment date
 */

import React from 'react';
import { dateOnly } from 'labstep-web/services/date.service';
import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import { OrganizationPlanCardNextPaymentDateProps } from './types';

export const OrganizationPlanCardNextPaymentDate: React.FC<
  OrganizationPlanCardNextPaymentDateProps
> = ({ organizationPlan }) => (
  <OrganizationPlanCard
    icon="clock outline"
    header="Next Payment Date"
    primary={
      <p>{dateOnly(organizationPlan.upcoming_invoice_date)}</p>
    }
  />
);

export default OrganizationPlanCardNextPaymentDate;
