/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/PaymentMethodType
 * @desc Select price
 */

import PaymentMethod from 'labstep-web/components/OrganizationPlan/PaymentMethod';
import MailTag from 'labstep-web/core/MailTag';
import {
  PAYMENT_TYPE_BACS,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_SEPA,
} from 'labstep-web/models/organization.model';
import React from 'react';
import { OrganizationPlanManagerPaymentMethodTypeProps } from './types';

export const OrganizationPlanManagerPaymentMethodType: React.FC<
  OrganizationPlanManagerPaymentMethodTypeProps
> = ({ currency, setPaymentMethodType }) => {
  return (
    <div>
      <div>
        <PaymentMethod
          label="Credit / Debit Card"
          description=""
          icon="/img/payment/card.png"
          onClick={() => setPaymentMethodType(PAYMENT_TYPE_CARD)}
        />
        {(currency === 'gbp' || !currency) && (
          <PaymentMethod
            label="BACS Direct Debit"
            description="UK Only"
            icon="/img/payment/bacs.png"
            onClick={() => setPaymentMethodType(PAYMENT_TYPE_BACS)}
          />
        )}
        {(currency === 'eur' || !currency) && (
          <PaymentMethod
            label="SEPA Direct Debit"
            description="EUR Only"
            icon="/img/payment/sepa.png"
            onClick={() => setPaymentMethodType(PAYMENT_TYPE_SEPA)}
          />
        )}
      </div>
      <div>
        Contact <MailTag department="info" /> for alternative options.
      </div>
    </div>
  );
};

export default OrganizationPlanManagerPaymentMethodType;
