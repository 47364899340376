/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/**
 * Labstep
 *
 * @module models/organization
 * @desc Typescript export class for Organization
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { OrganizationSAML } from 'labstep-web/models/organization-saml.model';
import { User } from 'labstep-web/models/user.model';
import { CurrencyISO } from 'labstep-web/services/currency.service';
import { daysDifference } from 'labstep-web/services/date.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PermissionRole } from './permission-role.model';
import { UserOrganization } from './user-organization.model';

export const PAYMENT_TYPE_BACS = 'bacs_debit';

export const PAYMENT_TYPE_CARD = 'card';

export const PAYMENT_TYPE_SEPA = 'sepa_debit';

export interface StripeAddress {
  line1: string;
  line2: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
}

export type PremiumFeature =
  | 'ip_whitelist'
  | '2fa'
  | 'security_log'
  | 'custom_permissions'
  | 'time_machine'
  | 'timeline'
  | 'jupyter'
  | 'chemistry'
  | 'auto_sharing'
  | 'sso'
  | 'multiplexing'
  | 'marvinjs'
  | 'entity_import'
  | 'lock_settings'
  | 'signature_workflow'
  | 'export'
  | 'spreadsheet_designer'
  | 'custom_identifier';

export class Organization extends Entity {
  static readonly entityName = 'organization';

  public get entityName(): string {
    return Organization.entityName;
  }

  public constructor(data: Partial<Organization>) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string;

  public whitelist_ip!: string[];

  public logo_url!: string;

  public has_metabase_id!: boolean;

  public two_factor_authentication!: boolean;

  public identifier!: string;

  public is_allow_member_create_group!: boolean;

  public is_stripe_subscription_set!: boolean;

  public is_labstep_managed_outbound!: boolean;

  public group_count!: number;

  public free_seats!: number;

  public tier!:
    | 'academic_free'
    | 'academic_plus'
    | 'academic_institute'
    | 'startup'
    | 'pro'
    | 'enterprise'
    | 'labstep';

  public stripe_customer_id!: string;

  public stripe_price_id!: string;

  public currency!: CurrencyISO | null;

  public stripe_payment_method_type!:
    | 'bacs_debit'
    | 'card'
    | 'sepa_debit';

  public trial_ends_at!: string;

  public premium_features?: PremiumFeature[];

  public is_saml_enabled!: boolean;

  public is_trialling!: boolean;

  public is_payment_overdue!: boolean;

  public seats!: number;

  public max_seats!: number;

  public admin_email!: string;

  @Type(() => OrganizationSAML)
  public organization_saml!: OrganizationSAML | null;

  @Type(() => UserOrganization)
  public logged_user_user_organization!: UserOrganization;

  @Type(() => User)
  public author!: User;

  @Type(() => Group)
  public groups!: Group[];

  @Type(() => PermissionRole)
  public permission_roles!: PermissionRole[];

  public get printDaysRemaining(): string {
    const difference = daysDifference(
      this.trial_ends_at,
      new Date().toDateString(),
    );
    return `${difference} days remaining`;
  }

  public get noSeatsLeft(): boolean {
    if (!this.max_seats) {
      return false;
    }
    return this.seats >= this.max_seats;
  }

  public hasFeatureEnabled(feature: PremiumFeature): boolean {
    return this.premium_features
      ? this.premium_features.indexOf(feature) > -1
      : false;
  }

  public get hasTrialEnded(): boolean {
    if (!this.trial_ends_at || !this.is_trialling) {
      return false;
    }
    const expiryDate = new Date(this.trial_ends_at);
    return new Date().getTime() >= expiryDate.getTime();
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
