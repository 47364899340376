/**
 * Labstep
 *
 * @module components/ShareLink/Form/fields
 * @desc Fields for ShareLink
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { string, mixed } from 'yup';

export const shareLinkFieldEmails: IFieldWithoutActionProps = {
  name: 'emails',
  placeholder: 'Enter email addresses',
  fieldType: FieldType.SelectMulti,
  validation: mixed().required(),
  elementProps: {
    pattern: 'email',
  },
};

export const shareLinkFieldMessage: IFieldWithoutActionProps = {
  name: 'message',
  placeholder: 'Enter your message',
  fieldType: FieldType.TextArea,
  validation: string(),
};
