/**
 * Labstep
 *
 * @module containers/Organization/Metabase
 * @desc Get metabase url for organization
 */

import { organizationGetMetabaseAction } from 'labstep-web/state/actions/organization';
import {
  activeMetabaseUrlSlice,
  selectActiveMetabaseUrl,
} from 'labstep-web/state/slices/activeMetabaseUrl';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { OrganizationMetabaseContainerProps } from './types';

export type OrganizationMetabaseContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Container = ({
  children,
  ...rest
}: OrganizationMetabaseContainerChildrenProps & {
  children: React.FC<
    Omit<OrganizationMetabaseContainerChildrenProps, 'children'>
  >;
}) => children(rest);

const mapStateToProps = (state: LabstepReduxState) => ({
  activeMetabaseUrl: selectActiveMetabaseUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMetabaseUrl() {
    dispatch(organizationGetMetabaseAction());
  },
  setActiveMetabaseUrl(url: string) {
    dispatch(
      activeMetabaseUrlSlice.actions.setActiveMetabaseUrl(url),
    );
  },
});

export const OrganizationMetabaseContainer: React.FC<OrganizationMetabaseContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Container as any);
