/**
 * Labstep
 *
 * @module components/GroupAutoSharing/List
 */

import GroupAutoSharingActionCreate from 'labstep-web/components/GroupAutoSharing/Action/Create';
import GroupAutoSharingTable from 'labstep-web/components/GroupAutoSharing/Table';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Flex from 'labstep-web/core/Flex';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { GroupAutoSharing } from 'labstep-web/models/group-auto-sharing.model';
import React from 'react';
import { IGroupListProps } from './types';

const GroupAutoSharingList: React.FC<IGroupListProps> = ({
  group,
}) => (
  <Flex grow column>
    <ReadOnMountHOC
      type="cursor"
      loading={{
        loader: 'placeholder',
      }}
      entityName={GroupAutoSharing.entityName}
      params={{
        source_group_guid: group.guid,
      }}
      children={({ entities }) =>
        entities.length ? (
          <>
            {group.logged_user_user_group.type === 'owner' && (
              <GroupAutoSharingActionCreate sourceGroup={group} />
            )}
            <GroupAutoSharingTable
              title="Content created in this workspace is auto-shared with..."
              groupAutoSharings={entities}
              group={group}
            />
          </>
        ) : (
          <EmptyState
            title="No auto-sharing rules"
            explanation="Add auto-sharing rules to automatically share content created in this workspace with other workspaces."
            src="/img/empty-state/experiments.svg"
            action={
              group.logged_user_user_group.type === 'owner' && (
                <GroupAutoSharingActionCreate
                  sourceGroup={group}
                  actionComponentProps={{
                    type: 'button',
                    elementProps: { primary: true },
                  }}
                />
              )
            }
          />
        )
      }
    />
    <ReadOnMountHOC
      type="cursor"
      loading={{
        loader: 'placeholder',
      }}
      entityName={GroupAutoSharing.entityName}
      params={{
        target_group_guid: group.guid,
      }}
      children={({ entities }) =>
        entities.length ? (
          <GroupAutoSharingTable
            title="Content is being auto-shared into this workspace from..."
            groupAutoSharings={entities}
            group={group}
          />
        ) : null
      }
    />
  </Flex>
);

export default GroupAutoSharingList;
