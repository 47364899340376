/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/NextPayment
 * @desc Organization plan card for next payment
 */

import React from 'react';
import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import { OrganizationPlanCardNextPaymentProps } from './types';

export const OrganizationPlanCardNextPayment: React.FC<
  OrganizationPlanCardNextPaymentProps
> = ({ organizationPlan }) => (
  <OrganizationPlanCard
    icon="file alternate outline"
    header="Next Payment"
    primary={
      <p>
        {organizationPlan.currencyDisplay}
        {organizationPlan.upcomingTotalDisplay}
      </p>
    }
  />
);

export default OrganizationPlanCardNextPayment;
