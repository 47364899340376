/**
 * Labstep
 *
 * @module components/Group/Action/AddUser
 * @desc Action to add user to group (from a list of the organization's users)
 */

import { CanGroup } from 'labstep-web/components/Entity/Can';
import { DEFAULT_PERMISSION_ROLES } from 'labstep-web/constants/roles';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import DropdownPill from 'labstep-web/core/Dropdown/Pill';
import Flex from 'labstep-web/core/Flex';
import Link from 'labstep-web/core/Link';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import { IGroupActionAddUserProps } from './types';
import { getCreateUserGroupParams } from './utils';

export const GroupActionAddUser: React.FC<
  IGroupActionAddUserProps
> = ({ group, authenticatedUserEntity }) => {
  const permissionRoles = [
    ...DEFAULT_PERMISSION_ROLES,
    ...group.organization.permission_roles,
  ];
  const [activePermissionRole, setActivePermissionRole] =
    React.useState(DEFAULT_PERMISSION_ROLES[1]);
  return (
    <CanGroup action="owner" groupId={group.id}>
      <ModalDefault
        header="Add Members"
        content={({ toggleModal }) => (
          <div>
            <Flex vAlignContent="center">
              <div style={{ flexGrow: 1, paddingRight: 10 }}>
                <EntityCreateContainer
                  entityName={UserGroup.entityName}
                  parentName={group.entityName}
                  parentId={group.id}
                >
                  {({ create }) => (
                    <SearchSelect
                      entityName={User.entityName}
                      placeholder="Search users in your organization"
                      params={{
                        organization_id: group.organization.id,
                        group_not_id: group.id,
                      }}
                      onChange={(option) => {
                        create({
                          user_id: option.id,
                          ...getCreateUserGroupParams(
                            activePermissionRole,
                          ),
                        });
                        toggleModal();
                      }}
                    />
                  )}
                </EntityCreateContainer>
              </div>
              <DropdownPill
                activeLabel={activePermissionRole.name}
                items={permissionRoles.map((permissionRole, i) => ({
                  label: permissionRole.name,
                  onClick: () =>
                    setActivePermissionRole(permissionRole),
                }))}
              />
            </Flex>
            <br />
            {authenticatedUserEntity.userOrganization?.type ===
              'admin' && (
              <Link
                unstyled
                to="organization_show_users"
                params={{ identifier: group.organization.identifier }}
              >
                <ActionComponent
                  type="text"
                  text="Click here to add new users to the organization"
                />
              </Link>
            )}
          </div>
        )}
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            onClick={toggleModal}
            type="button"
            text="Add Members"
          />
        )}
      />
    </CanGroup>
  );
};

export default withAuthenticatedUserEntity(GroupActionAddUser);
