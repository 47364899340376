/**
 * Labstep
 *
 * @module screens/Authentication/SignupEnterprise
 * @desc Signup Screen for Enterprise
 */

import SignupForm from 'labstep-web/components/User/Form/Signup';
import UserSigninSocialMedia from 'labstep-web/components/User/Signin/SocialMedia';
import { withParams } from 'labstep-web/containers/Params';
import { UserRegisterInternalContainer } from 'labstep-web/containers/User/Register/Internal';
import Divider from 'labstep-web/core/Divider';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Icon from 'labstep-web/core/Icon';
import Image from 'labstep-web/core/Image';
import Columns from 'labstep-web/screens/Authentication/Columns';
import {
  Benefits,
  SharedBody,
  SharedFooter,
  TermsAndConditions,
} from 'labstep-web/screens/Authentication/Shared';
import qs from 'query-string';
import React from 'react';
import styles from './styles.module.scss';

export const SignupLeft = ({ bottomQuote }) => (
  <>
    <div className={styles.info}>
      <div className={styles.infoMessage}>
        A collaborative research environment with integrated ELN,
        inventory and order management modules. Built to simplify and
        accelerate scientific discovery.
      </div>
      <div>
        <Image src="/img/landing/robot_main.svg" />
      </div>
    </div>
    <div className={styles.container}>
      <Benefits header="Benefits of Labstep" />
      <Divider horizontal>
        <Icon color="teal" name="quote right" size="huge" />
      </Divider>
      <div className={styles.quote}>
        <p>{bottomQuote.quote}</p>
        <div>
          <b>{bottomQuote.name}</b>
          <p>{bottomQuote.affiliation}</p>
        </div>
      </div>
    </div>
  </>
);

class Register extends React.Component<any> {
  componentDidMount() {
    const { setParams, location } = this.props;
    if (location.search.startsWith('?')) {
      const params = qs.parse(location.search);
      if (params.referralCode) {
        setParams({
          referralCode: params.referralCode,
          refSource: params.refSource,
        });
      }
    }

    this.props.clearLoginError();
  }

  componentDidUpdate(prevProps) {
    const { match, clearLoginError } = this.props;
    if (prevProps.match.url !== match.url) {
      clearLoginError();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const body = { is_signup_enterprise: true } as {
      is_signup_enterprise: true;
    };

    return (
      <div>
        <SharedBody>
          <UserSigninSocialMedia body={body} mode="signup" />

          <Divider horizontal>Or</Divider>
          <SignupForm body={body} />
        </SharedBody>

        <SharedFooter>
          <TermsAndConditions />
          <p>
            The trial agreement and documents governing the terms of
            your free trial are included in the terms and conditions.
          </p>
        </SharedFooter>
      </div>
    );
  }
}

export const RegisterColumns = (props) => (
  <>
    <DocumentHead title="Signup" />
    <Columns
      inverted
      logo={
        <Image className={styles.logo} src="/img/logo/logo.svg" />
      }
      header="Sign up for a 7-day Free Trial"
      explainer="Keep your research organised, accurate & accessible"
      left={
        <SignupLeft
          bottomQuote={{
            quote:
              'The onboarding process was quick, efficient, and streamlined. It was easy to migrate our whole company onto the platform.',
            name: 'Carl Brown',
            affiliation: 'Scientist, Sherlock Bioscience, US',
          }}
        />
      }
      right={<Register {...props} />}
      centerAlign={false}
    />
  </>
);

const ScreensAuthenticationSignup = (props) => (
  <UserRegisterInternalContainer>
    {(registerProps) => (
      <RegisterColumns {...registerProps} {...props} />
    )}
  </UserRegisterInternalContainer>
);

export default withParams(ScreensAuthenticationSignup);
