/**
 * Labstep
 */

import MetadataActionUpdateIsRequired from 'labstep-web/components/Metadata/Action/UpdateIsRequired';
import MetadataFormShowEditInputOutput from 'labstep-web/components/Metadata/Form/ShowEdit/InputOutput';
import MetadataFormShowEditNumeric from 'labstep-web/components/Metadata/Form/ShowEdit/Numeric';
import MetadataFormShowEditOptions from 'labstep-web/components/Metadata/Form/ShowEdit/Options';
import MetadataFormShowEditProtocolDevice from 'labstep-web/components/Metadata/Form/ShowEdit/ProtocolDevice';
import MetadataFormShowEditType from 'labstep-web/components/Metadata/Form/ShowEdit/Type';
import ProtocolConditionActionToggleIsVariable from 'labstep-web/components/ProtocolCondition/Action/ToggleIsVariable';
import { ICONS } from 'labstep-web/constants/icons';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Group } from 'labstep-web/models/group.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { RowsType } from '../../types';

export const getRows = (
  entity: Protocol | Experiment,
  metadataEntity: Metadata,
  groupId?: Group['id'],
): RowsType => {
  const rows: RowsType = [
    {
      header: (
        <TextWithHelp text="Input / Output" icon="exchange" header />
      ),
      content: (metadata): React.ReactElement => (
        <MetadataFormShowEditInputOutput metadata={metadata} />
      ),
      dataTestId: 'metadata-input-output',
    },
    {
      header: (
        <TextWithHelp
          text="Data Type"
          icon={ICONS.metadata.primary}
          header
        />
      ),
      content: (metadata): React.ReactElement => (
        <MetadataFormShowEditType metadata={metadata} />
      ),
      dataTestId: 'metadata-type',
    },
    {
      header: (
        <TextWithHelp text="Required" icon="exclamation" header />
      ),
      content: (metadata): React.ReactElement => (
        <MetadataActionUpdateIsRequired metadata={metadata} />
      ),
      dataTestId: 'metadata-required',
    },
  ];
  rows.splice(2, 0, {
    header: (
      <TextWithHelp
        text="Variable"
        icon={ICONS.protocol_condition.info.variable}
        helperText="Enable to assign this field different values across different conditions."
        header
      />
    ),
    content: (metadata): React.ReactElement => (
      <ProtocolConditionActionToggleIsVariable
        entity={metadata}
        protocol={entity}
      />
    ),
    dataTestId: 'metadata-is-variable',
  });
  if (metadataEntity.type === MetadataType.options) {
    rows.splice(2, 0, {
      header: <TextWithHelp text="Options" icon="list" header />,
      content: (metadata): React.ReactElement => (
        <MetadataFormShowEditOptions metadata={metadata} />
      ),
      dataTestId: 'metadata-options',
    });
  }
  if (metadataEntity.canBeLinkedToDevice) {
    rows.push({
      header: (
        <TextWithHelp
          text="Device"
          icon={ICONS.device.primary}
          header
        />
      ),
      content: (metadata): React.ReactElement => (
        <MetadataFormShowEditProtocolDevice
          metadata={metadata}
          parent={entity}
          params={{ group_id: groupId }}
        />
      ),
      dataTestId: 'metadata-device',
    });
  }
  if (
    metadataEntity.type === MetadataType.numeric &&
    metadataEntity.is_variable
  ) {
    rows.push({
      header: (
        <TextWithHelp
          text="Unit"
          icon={ICONS.protocol_value.info.unit}
          header
        />
      ),
      content: (metadata): React.ReactElement => (
        <MetadataFormShowEditNumeric
          metadata={metadata}
          type="unit"
        />
      ),
      dataTestId: 'metadata-unit',
    });
  }
  return rows;
};
