/**
 * Labstep
 *
 * @module containers/ShareLinkEmail
 * @desc Email ShareLink container
 */

import {
  emailShareLinkAction,
  emailShareLinkActionType,
} from 'labstep-web/state/actions/email-sharelink';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

export type EmailShareLinkContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Container = ({
  children,
  ...rest
}: EmailShareLinkContainerChildrenProps & {
  children: (
    childrenProps: Omit<
      EmailShareLinkContainerChildrenProps,
      'children'
    >,
  ) => JSX.Element;
}) => children(rest);

const mapStateToProps = (state: LabstepReduxState) => ({
  status: selectApiStatus(state, emailShareLinkActionType),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  emailShareLink(...args: Parameters<typeof emailShareLinkAction>) {
    dispatch(emailShareLinkAction(...args));
  },
});

export const EmailShareLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
