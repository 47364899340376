/**
 * Labstep
 *
 * @module components/Organization/UserManager
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import SharelinkInvitationActionCreateOrganization from 'labstep-web/components/ShareLinkInvitation/Action/Create/Organization';
import ShareLinkInvitationModal from 'labstep-web/components/ShareLinkInvitation/Modal';
import UserOrganizationTable from 'labstep-web/components/UserOrganization/Table';
import { UserOrganization } from 'labstep-web/models/user-organization.model';
import React from 'react';
import { IUserOrganizationManagerProps } from './types';

export const UserOrganizationManager: React.FC<
  IUserOrganizationManagerProps
> = ({ organization }) => {
  return (
    <EntitySearch
      searchKey="search_query"
      entityName={UserOrganization.entityName}
      params={{
        organization_id: organization.id,
      }}
      bulkActions={[
        BulkAction.add_to_workspace,
        BulkAction.update_organization_role,
        BulkAction.disable_users,
      ]}
      filters={[Filter.is_user_deleted]}
      action={() => (
        <SharelinkInvitationActionCreateOrganization
          organization={organization}
        />
      )}
    >
      {({ entities }) => (
        <div>
          {!organization.is_saml_enabled &&
            organization.logged_user_user_organization.type ===
              'admin' && (
              <ShareLinkInvitationModal entity={organization} />
            )}
          <UserOrganizationTable userOrganizations={entities} />
        </div>
      )}
    </EntitySearch>
  );
};

export default UserOrganizationManager;
