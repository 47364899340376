/**
 * Labstep
 *
 * @module models/outbound-order-request
 * @desc Typescript export class for OutboundOrderRequest
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { User } from 'labstep-web/models/user.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';

export class OutboundOrderRequest extends Entity {
  static readonly entityName = 'outbound_order_request';

  get entityName(): string {
    return OutboundOrderRequest.entityName;
  }

  constructor(data: Partial<OutboundOrderRequest> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  @Type(() => User)
  author: User;

  @Type(() => OrderRequest)
  order_request: OrderRequest;

  name: string;

  amount: number;

  commission_amount: number;

  currency: Currency;

  get printAmount(): string {
    return CurrencyService.print(this.amount, this.currency);
  }

  get printCommission(): string {
    return CurrencyService.print(
      this.commission_amount,
      this.currency,
    );
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
