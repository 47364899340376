/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';

export const emailShareLinkActionType = 'email_sharelink';

export const emailShareLinkAction = (
  body: Record<string, unknown>,
  options: any = {},
): any =>
  sfApi.post({
    type: 'EMAIL_SHARELINK',
    route: {
      custom: 'app_sharelink_emailsharelink',
    },
    meta: { action_type: emailShareLinkActionType, customReducer: 1 },
    body,
    ...options,
  });
