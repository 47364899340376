/**
 * Labstep
 */

import {
  Organization,
  StripeAddress,
} from 'labstep-web/models/organization.model';
import * as sfApi from 'labstep-web/services/sf-api.service';
import {
  TypeConfirmIntentSepaActionCreator,
  TypeCreateCustomerActionCreator,
  TypeOrganizationSubscriptionPortalActionCreator,
  TypeSetupIntentSepaActionCreator,
} from './organization.types';

export const organizationSubscriptionPortalActionType =
  'organization_subscription_portal';

export const getOrganizationSubscriptionPortalAction = (
  body: any,
  options = {},
): TypeOrganizationSubscriptionPortalActionCreator =>
  sfApi.post({
    type: 'ORGANIZATION_SUBSCRIPTION_PORTAL',
    route: {
      custom: 'app_organizationplan_postsubscriptionportal',
    },
    body,
    meta: {
      action_type: organizationSubscriptionPortalActionType,
      customReducer: 1,
    },
    ...options,
  });

export const organizationSetupIntentSepaDebitActionType =
  'organization_setup_intent_sepa_debit';

export const setupIntentSepaDebitAction = (
  id,
  body: any,
  options = {},
): TypeSetupIntentSepaActionCreator =>
  sfApi.post({
    type: 'ORGANIZATION_SETUP_INTENT_SEPA_DEBIT',
    route: {
      custom: 'app_organizationplan_setupintentsepadebit',
    },
    params: { id },
    body,
    meta: {
      action_type: organizationSetupIntentSepaDebitActionType,
      customReducer: 1,
    },
    ...options,
  });

export const confirmIntentSepaDebitActionType =
  'confirm_intent_sepa_debit';

export const confirmIntentSepaDebitAction = (
  id,
  options = {},
): TypeConfirmIntentSepaActionCreator =>
  sfApi.post({
    type: 'CONFIRM_INTENT_SEPA_DEBIT',
    route: {
      custom: 'app_organizationplan_subscriptionsepadebit',
    },
    params: { id },
    meta: {
      action_type: confirmIntentSepaDebitActionType,
      customReducer: 1,
    },
    ...options,
  });

export const organizationCreateCustomerActionType =
  'organization_create_customer';

export const organizationCreateCustomerAction = (
  organization: Organization,
  address: StripeAddress,
): TypeCreateCustomerActionCreator =>
  sfApi.post({
    type: 'ORGANIZATION_CREATE_CUSTOMER',
    route: {
      custom: 'app_organizationplan_createcustomer',
    },
    meta: {
      action_type: organizationCreateCustomerActionType,
      customReducer: 1,
      organization,
    },
    body: {
      address,
    },
  });

export const organizationGetMetabaseActionType =
  'organization_get_metabase';

export const organizationGetMetabaseAction = () =>
  sfApi.get({
    type: 'ORGANIZATION_GET_METABASE',
    route: {
      custom: 'app_organizationmetabase_getorganizationmetabase',
    },
    meta: {
      action_type: organizationGetMetabaseActionType,
      customReducer: 1,
    },
  });
