/**
 * Labstep
 *
 * @module components/Resource/Filter/Pane
 * @desc Filter pane for resource
 */

import { FilterList } from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import { Resource } from 'labstep-web/models/resource.model';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';

export const ResourceExtraFilters: React.FC = () => (
  <FilterList
    filters={[
      {
        icon: ICONS.resource.info.available,
        text: STRINGS.resource.info.available,
        params: { available_resource_item_count_min: 1 },
      },
      {
        icon: ICONS.resource.info.unavailable,
        text: STRINGS.resource.info.unavailable,
        params: { available_resource_item_count_max: 0 },
      },
    ]}
  />
);

const ResourceFilterPane: React.FC = () => (
  <FilterPane
    entityName={Resource.entityName}
    extraFilters={<ResourceExtraFilters />}
    icon={ICONS.resource.primary}
    text={STRINGS.resource.plural.capitalized}
  />
);

export default ResourceFilterPane;
