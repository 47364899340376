/**
 * Labstep
 *
 * @module services/ag-grid
 * @desc AGGrid Service
 */

import type { AgGridReact } from 'ag-grid-react';
import { RowNode, ColDef, ColumnState } from 'ag-grid-community';
import {
  EntityImportColDef,
  EntityImportDataRowData,
} from 'labstep-web/models/entity-import.model';

export class AGGridService {
  protected _agGrid: AgGridReact;

  public constructor(agGrid: AgGridReact) {
    this._agGrid = agGrid;
  }

  public get agGrid(): AgGridReact {
    return this._agGrid;
  }

  public set agGrid(agGrid: AgGridReact) {
    this._agGrid = agGrid;
  }

  /**
   * Trigger validation for all cells.
   */
  public refreshData(): void {
    const columnDefs = this.getColumnDefs();

    columnDefs.forEach((colDef: EntityImportColDef) => {
      const { colId } = colDef;
      if (colId) {
        this.refreshColumnData(colId);
      }
    });
  }

  /**
   * Trigger validation for all cells in a column.
   *
   * @param colId - The column id.
   */
  public refreshColumnData(colId: ColDef['colId']): void {
    const { api } = this.agGrid;

    if (colId) {
      api.forEachNode((rowNode: RowNode) => {
        if (rowNode.data[colId]) {
          rowNode.setDataValue(colId, rowNode.data[colId].value);
        }
      });
    }
  }

  /**
   * Change the column definition
   * @param colId Old column id.
   * @param newColId New column id.
   */
  public changeColDef(
    colId: ColDef['colId'],
    newColDef: ColDef,
  ): void {
    const newColId = newColDef.colId;
    if (!colId || !newColId) {
      return;
    }

    // replace data
    if (colId !== newColId) {
      this.agGrid.api.forEachNode((rowNode: RowNode) => {
        // eslint-disable-next-line no-param-reassign
        rowNode.data[newColId] = rowNode.data[colId];
      });
    }

    // replace columns and state
    const { colDefs, state } =
      this.getColDefsAndStateWithReplacedColumn(colId, newColDef);
    this.setColumnDefs(colDefs);
    this.agGrid.columnApi.applyColumnState({
      state,
      applyOrder: true,
    });
  }

  /**
   * Get column definitions and state with a column replaced.
   * @param colId Column id to replace.
   * @param newColDef New column definition.
   * @returns Column definitions and state.
   */
  public getColDefsAndStateWithReplacedColumn(
    colId: ColDef['colId'],
    newColDef: ColDef,
  ): {
    state: ColumnState[];
    colDefs: ColDef[];
  } {
    const newColId = newColDef.colId;
    const state = this.agGrid.columnApi.getColumnState();
    const columns = this.agGrid.columnApi.getAllColumns();
    const colDefs = columns?.map((c) => c.getColDef()) || [];
    const oldColDefIdx = colDefs.findIndex((c) => c.colId === colId);

    if (!colId || !newColId || oldColDefIdx === -1) {
      return { state, colDefs };
    }

    // Create a new array of column definitions with the new column
    const newColDefs = [
      ...colDefs.slice(0, oldColDefIdx),
      newColDef,
      ...colDefs.slice(oldColDefIdx + 1),
    ];

    // Replace the old column with the new column in the grid state
    if (colId === newColId) {
      return { state, colDefs: newColDefs };
    }
    const newState = state.map((colState) =>
      colState.colId === colId
        ? { ...colState, colId: newColId }
        : colState,
    );

    return { state: newState, colDefs: newColDefs };
  }

  public addRowData(rowData: EntityImportDataRowData): void {
    const { api } = this.agGrid;

    api.applyTransaction({
      add: rowData,
    });
  }

  public addNewRow(data = {}): void {
    const { api } = this.agGrid;

    api.applyTransaction({
      add: [data],
    });
  }

  public getColumnDefs(): EntityImportColDef[] {
    const { columnApi } = this.agGrid;

    const columns = columnApi.getAllColumns();
    if (!columns) {
      return [];
    }

    return columns
      .filter((column) => column.getColDef())
      .map((column) => column.getColDef() as EntityImportColDef);
  }

  public setColumnDefs(colDefs: EntityImportColDef[]): void {
    const { api } = this.agGrid;

    api.setColumnDefs(colDefs);

    this.refreshColumnDefs();
  }

  public refreshColumnDefs(): void {
    const { api, columnApi } = this.agGrid;

    const state = columnApi.getColumnState();

    const columns = columnApi.getAllColumns();
    if (columns) {
      const colDefs = columns.map((column) => column.getColDef());

      api.setColumnDefs(colDefs);

      if (state) {
        columnApi.applyColumnState({
          state,
          applyOrder: true,
        });
      }
    }
  }

  /**
   * Remove a column from the grid.
   * @param colId - The column id.
   */
  public removeColumn(colId: ColDef['colId']): void {
    if (!colId) {
      return;
    }
    const { api, columnApi } = this.agGrid;

    // remove data from rows
    api.forEachNode((rowNode: RowNode) => {
      if (rowNode.data[colId]) {
        const { [colId]: _, ...rest } = rowNode.data;
        rowNode.setData(rest);
      }
    });

    const columns = columnApi.getAllColumns();
    if (columns) {
      const colDefs = columns
        .filter((column) => column.getColId() !== colId)
        .map((column) => column.getColDef());

      api.setColumnDefs(colDefs);
    }
  }

  /**
   * Get all unique values in a column.
   * @param colId - The column id.
   * @returns An array of unique values.
   */
  public getUniqueValues(colId: ColDef['colId']): string[] {
    if (!colId) {
      return [];
    }
    const { api } = this.agGrid;

    const values: string[] = [];

    api.forEachNode((rowNode: RowNode) => {
      if (rowNode.data[colId]) {
        values.push(rowNode.data[colId].value);
      }
    });

    const uniqueValues = Array.from(new Set(values));

    return uniqueValues;
  }
}
