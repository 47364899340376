/**
 * Labstep
 *
 * @module components/UserOrganization/Action/ManagePermissions
 * @desc Update workspace permissions per user
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { UserGroup } from 'labstep-web/models/user-group.model';
import EntitySearch from 'labstep-web/components/Entity/Search';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { GroupEmptyState } from 'labstep-web/components/Group/Card/EmptyState';
import FloatingSearchSelect from 'labstep-web/core/Select/FloatingSearch';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { User } from 'labstep-web/models/user.model';
import { Group } from 'labstep-web/models/group.model';
import UserGroupTable from 'labstep-web/components/User/Table';
import UserGroupActionMenu from 'labstep-web/components/UserGroup/Action/Menu';
import { Action } from 'labstep-web/components/UserGroup/Action/Menu/types';
import Button from 'labstep-web/core/Button';
import Popup from 'labstep-web/core/Popup';
import { IGroupActionManageMembersProps } from './types';

export const GroupActionManageMembers: React.FC<
  IGroupActionManageMembersProps
> = ({ group, organizationId, iconOnly }) => {
  const confirmMessage = `Are you sure you want to remove these members from ${group.name}?`;

  return (
    <ModalDefault
      header={`Manage Members: ${group.name}`}
      size="large"
      content={() => (
        <EntitySearch
          entityName={UserGroup.entityName}
          params={{
            group_id: group.id,
          }}
          bulkActions={[
            {
              action: BulkAction.delete,
              props: {
                confirmMessage,
                actionComponentProps: {
                  type: 'button',
                  icon: 'ban',
                  text: 'Remove from Workspace',
                },
              },
            },
          ]}
          action={
            <EntityCreateContainer
              entityName={UserGroup.entityName}
              parentName={Group.entityName}
              parentId={group.id}
            >
              {({ create, status }) => (
                <FloatingSearchSelect
                  entityName={User.entityName}
                  params={{
                    count: 5,
                    organization_id: organizationId,
                    group_not_id: group.id,
                  }}
                  placeholder="Search organization users..."
                  onChange={(option) => {
                    create({
                      user_id: option.id,
                      group_id: group.id,
                    });
                  }}
                  isClearable={false}
                  isDisabled={status && status.isFetching}
                  actionComponentProps={{
                    type: 'button',
                    text: 'Add Members',
                  }}
                  alignRight
                  keepMenuOpen
                  removeSelectedOption
                />
              )}
            </EntityCreateContainer>
          }
          noResultsMessage={
            <GroupEmptyState isActionAllowed={false} />
          }
          searchBarProps={{
            placeholder: `Search ${group.name}'s members ...`,
          }}
        >
          {({ entities }) => (
            <UserGroupTable
              userGroups={entities}
              actionMenu={({ entity }: { entity: UserGroup }) => (
                <UserGroupActionMenu
                  userGroup={entity}
                  actions={[Action.delete_user_group]}
                />
              )}
            />
          )}
        </EntitySearch>
      )}
      viewComponent={({ toggleModal }) => {
        return iconOnly ? (
          <Popup
            trigger={
              <Button icon="cog" circular onClick={toggleModal} />
            }
            content="Manage"
            size="mini"
          />
        ) : (
          <ActionComponent
            type="option"
            icon="users"
            text="Manage Members"
            onClick={toggleModal}
          />
        );
      }}
    />
  );
};
