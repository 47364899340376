/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Timeline
 * @desc ExperimentWorkflow Timeline
 */

import FullCalendar from '@fullcalendar/react';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import Event from 'labstep-web/components/ExperimentWorkflow/Timeline/Event';
import Resource from 'labstep-web/components/ExperimentWorkflow/Timeline/Resource';
import { EntityUpdateAnyContainer } from 'labstep-web/containers/Entity/Update/Any';
import Card from 'labstep-web/core/Card/Card';
import Placeholder from 'labstep-web/core/Placeholder';
import Timeline from 'labstep-web/core/Timeline';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { format } from 'labstep-web/services/date.service';
import { isEqual } from 'lodash';
import React from 'react';
import ExperimentWorkflowTimelineModal from './Modal';
import styles from './styles.module.scss';
import {
  IExperimentWorkflowTimelineContainerProps,
  IExperimentWorkflowTimelineContainerWithAccessProps,
  IExperimentWorkflowTimelineProps,
  IExperimentWorkflowTimelineState,
} from './types';
import { createBody, createEventsAndResources } from './utils';

/** Set the searchParams on mount (should match sortFn) */
export const defaultSort = 'started_at,start_planned_at';

export class ExperimentWorkflowTimeline extends React.Component<
  IExperimentWorkflowTimelineProps,
  IExperimentWorkflowTimelineState
> {
  constructor(props: IExperimentWorkflowTimelineProps) {
    super(props);
    this.state = {
      datesSet: { start: null, end: null },
      modal: null,
    };
  }

  componentDidMount() {
    const { searchParams, setParams } = this.props;
    if (searchParams.sort !== defaultSort) {
      setParams({ sort: defaultSort });
    }
  }

  shouldComponentUpdate(
    nextProps: IExperimentWorkflowTimelineProps,
    nextState: IExperimentWorkflowTimelineState,
  ) {
    return (
      !isEqual(
        this.props.experimentWorkflows,
        nextProps.experimentWorkflows,
      ) ||
      !isEqual(this.props.status, nextProps.status) ||
      !isEqual(this.props.updateStatuses, nextProps.updateStatuses) ||
      !isEqual(this.state, nextState)
    );
  }

  handleDatesSet = (datesSet) => {
    this.setState({
      datesSet,
    });
  };

  resetModal = () =>
    this.setState({
      modal: null,
    });

  render() {
    const {
      experimentWorkflows,
      actions,
      primaryColumnHeader,
      emptyState,
      status,
      showMoreAction,
      searchParams,
      updateStatuses,
      update,
      experimentWorkflowsCanEdit,
    } = this.props;

    const calendarRef = React.createRef<FullCalendar>();

    const isReading = status && status.isFetching;
    const isUpdating = updateStatuses.some(
      (updateStatus) => updateStatus.isFetching,
    );
    const isLoading = isReading || isUpdating;

    const isCached =
      status &&
      status.cached &&
      Math.floor((status.cached - Date.now()) / 1000 / 60) < 5;

    const showFullTableSpinner =
      isReading && (!isCached || experimentWorkflows.length === 0);

    if (showFullTableSpinner) {
      return <Placeholder />;
    }

    const handleEventChange = ({
      event: { start, end, id, extendedProps },
    }) => {
      const { experimentWorkflow } = extendedProps;
      // if start and end are equal, end is null on eventChange
      const formattedEnd = end === null ? start : end;
      const body = createBody(
        experimentWorkflow,
        start,
        formattedEnd,
      );
      update(id, body, { optimistic: true });
    };

    const handleSelect = (dateSelectArgs) => {
      const { start, end, resource } = dateSelectArgs;
      const calendarApi = calendarRef.current.getApi();
      calendarApi.unselect();

      if (resource.id === 'showMoreAction') {
        return;
      }

      if (
        resource.id === 'createActionTop' ||
        resource.id === 'createActionBottom'
      ) {
        this.setState({
          modal: {
            defaultValues: {
              planned_at: [format(start), format(end)],
            },
          },
        });
        return;
      }

      const { experimentWorkflow } = resource.extendedProps;
      if (!experimentWorkflowsCanEdit[experimentWorkflow.id]) {
        return;
      }
      const body = createBody(experimentWorkflow, start, end);
      update(resource.id, body, { optimistic: true });
    };

    const handleResourceClick = ({ activeStart }) => {
      const eventStart = new Date(activeStart);
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(eventStart);
      calendarApi.scrollToTime({
        days: eventStart.getDate() - 1,
      });
    };

    const eventContent = ({ event }) => {
      const { experimentWorkflow } = event.extendedProps;
      return (
        <Event
          experimentWorkflow={experimentWorkflow}
          disabled={isLoading}
        />
      );
    };

    const resourceLabelContent = ({ resource }) => {
      if (
        resource.id === 'createActionTop' ||
        resource.id === 'createActionBottom'
      ) {
        return (
          <div
            className={styles.createAction}
            onClick={() =>
              this.setState({
                modal: {},
              })
            }
          >
            <span>
              Click or select dates to insert new experiment
            </span>
          </div>
        );
      }

      if (resource.id === 'showMoreAction') {
        return showMoreAction;
      }

      let arrowAction: 'left' | 'right' | undefined;
      const { experimentWorkflow } = resource.extendedProps;
      if (experimentWorkflow.activeStart) {
        const viewStart = new Date(this.state.datesSet.start);
        const viewEnd = new Date(this.state.datesSet.end);
        const startDate = new Date(experimentWorkflow.activeStart);
        if (startDate < viewStart) {
          arrowAction = 'left';
        } else if (startDate >= viewEnd) {
          arrowAction = 'right';
        }
      }

      return (
        <Resource
          experimentWorkflow={experimentWorkflow}
          actions={actions}
          onClick={handleResourceClick}
          arrowAction={arrowAction}
        />
      );
    };

    const [events, resources] = createEventsAndResources(
      this.props.experimentWorkflows,
      this.props.experimentWorkflowsCanEdit,
      isLoading,
    );

    return experimentWorkflows.length === 0 && !isReading ? (
      <>{emptyState}</>
    ) : (
      <div>
        {this.state.modal && (
          <ExperimentWorkflowTimelineModal
            onClose={this.resetModal}
            options={{
              onSuccess: this.resetModal,
            }}
            defaultValues={this.state.modal.defaultValues}
            body={
              searchParams.folder_id && {
                folder_id: Number(searchParams.folder_id),
              }
            }
          />
        )}
        <Card className={styles.card}>
          <Timeline
            ref={calendarRef}
            events={events}
            resources={resources}
            eventChange={handleEventChange}
            select={handleSelect}
            eventContent={eventContent}
            resourceLabelContent={resourceLabelContent}
            resourceAreaHeaderContent={primaryColumnHeader}
            resourceOrder="sortOrder"
            datesSet={this.handleDatesSet}
            editable={!isLoading}
            selectable={!isLoading}
          />
        </Card>
      </div>
    );
  }
}

export const ExperimentWorkflowTimelineContainer: React.FC<
  IExperimentWorkflowTimelineContainerProps
> = (props) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ setParams, searchParams }) => (
        <EntityUpdateAnyContainer
          entityName={ExperimentWorkflow.entityName}
        >
          {({ statuses, update }) => (
            <ExperimentWorkflowTimeline
              key={JSON.stringify(searchParams)}
              setParams={setParams}
              searchParams={searchParams}
              updateStatuses={statuses}
              update={update}
              {...props}
            />
          )}
        </EntityUpdateAnyContainer>
      )}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export const ExperimentWorkflowTimelineContainerWithAccess: React.FC<
  IExperimentWorkflowTimelineContainerWithAccessProps
> = (props) => {
  const experimentWorkflowsCanEdit = useHasAccessMultiple(
    ExperimentWorkflow.entityName,
    props.experimentWorkflows.map((e) => e.id),
    Action.edit,
  );

  return (
    <ExperimentWorkflowTimelineContainer
      experimentWorkflowsCanEdit={experimentWorkflowsCanEdit}
      {...props}
    />
  );
};

export default ExperimentWorkflowTimelineContainerWithAccess;
