/**
 * Labstep
 *
 * @module core/Timeline/Right
 * @desc ExperimentWorkflow Timeline Right
 */

import classnames from 'classnames';
import EntityLink from 'labstep-web/components/Entity/Link';
import EntityUserActionManageList from 'labstep-web/components/EntityUser/Action/Manage/List';
import { ICONS } from 'labstep-web/constants/icons';
import Card from 'labstep-web/core/Card/Card';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { IIconProps } from 'labstep-web/core/Icon/types';
import React from 'react';
import styles from './styles.module.scss';
import { IExperimentWorkflowTimelineEventProps } from './types';

export const ExperimentWorkflowTimelineEvent: React.FC<
  IExperimentWorkflowTimelineEventProps
> = ({ experimentWorkflow, disabled }) => {
  let color: IIconProps['color'];
  if (experimentWorkflow.statusType === 'completed') {
    color = 'green';
  }
  if (experimentWorkflow.statusType === 'started') {
    color = 'yellow';
  }
  return (
    <Card
      className={classnames(styles.eventCard, {
        [styles.disabled]: disabled,
      })}
    >
      <Flex vAlignContent="center">
        <div>
          <Icon
            className={styles.statusIcon}
            color={color}
            name={
              ICONS.experiment_workflow.info[
                experimentWorkflow.statusType
              ]
            }
          />
          <EntityLink entity={experimentWorkflow} />
          {experimentWorkflow.locked_at && (
            <Icon
              className={styles.lockIcon}
              name={ICONS.experiment_workflow.actions.lock}
            />
          )}
        </div>
        <div>
          <EntityUserActionManageList
            entity={experimentWorkflow}
            hidePopup
          />
        </div>
      </Flex>
    </Card>
  );
};

export default ExperimentWorkflowTimelineEvent;
