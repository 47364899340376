/**
 * Labstep
 *
 * @module components/PurchaseOrder/Details
 * @desc Shows actions + order request table
 */

import BulkActions from 'labstep-web/components/Entity/Search//BulkActions';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import GuardAdminLabstep from 'labstep-web/components/Guard/LabstepAdmin';
import Container from 'labstep-web/components/Layout/Container';
import { OrderRequestEmptyState } from 'labstep-web/components/OrderRequest/Card/EmptyState';
import OrderRequestTable from 'labstep-web/components/OrderRequest/Table';
import OutboundPurchaseOrderActionCreate from 'labstep-web/components/OutboundPurchaseOrder/Action/Create';
import OutboundPurchaseOrderFormShowEditDiscount from 'labstep-web/components/OutboundPurchaseOrder/Form/ShowEdit/Discount';
import OutboundPurchaseOrderFormShowEditHandling from 'labstep-web/components/OutboundPurchaseOrder/Form/ShowEdit/Handling';
import OutboundPurchaseOrderFormShowEditTaxRate from 'labstep-web/components/OutboundPurchaseOrder/Form/ShowEdit/TaxRate';
import PurchaseOrderActionAddOrderRequest from 'labstep-web/components/PurchaseOrder/Action/AddOrderRequest';
import PurchaseOrderActionUpdateIsLabstepFulfilled from 'labstep-web/components/PurchaseOrder/Action/UpdateIsLabstepFulfilled';
import PurchaseOrderActionUpdateStatus from 'labstep-web/components/PurchaseOrder/Action/UpdateStatus';
import PurchaseOrderFormShowEditDiscount from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/Discount';
import PurchaseOrderFormShowEditHandling from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/Handling';
import PurchaseOrderFormShowEditTaxRate from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/TaxRate';
import { BulkSelectContainer } from 'labstep-web/containers/BulkSelect';
import Flex from 'labstep-web/core/Flex';
import Table from 'labstep-web/core/Table';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import styles from './styles.module.scss';
import { IPurchaseOrderDetailsProps } from './types';

export const PurchaseOrderDetails: React.FC<
  IPurchaseOrderDetailsProps
> = ({ purchaseOrder }) => {
  const orderRequests = purchaseOrder.nonAttachedOrderRequests;

  return (
    <GuardAdminLabstep>
      {({ isLabstepAdmin }) => {
        const isAdminPage = Boolean(
          isLabstepAdmin && purchaseOrder.outbound_purchase_order,
        );
        return (
          <>
            {orderRequests.length === 0 ? (
              <OrderRequestEmptyState
                explanation="Add new or existing order requests to this order."
                action={
                  <PurchaseOrderActionAddOrderRequest
                    purchaseOrder={purchaseOrder}
                  />
                }
              />
            ) : (
              <>
                <Container>
                  <Flex>
                    <PurchaseOrderActionUpdateStatus
                      purchaseOrder={purchaseOrder}
                    />
                    <PurchaseOrderActionAddOrderRequest
                      purchaseOrder={purchaseOrder}
                    />
                    <OutboundPurchaseOrderActionCreate
                      purchaseOrder={purchaseOrder}
                    />
                  </Flex>
                </Container>

                <Container>
                  <PurchaseOrderActionUpdateIsLabstepFulfilled
                    purchaseOrder={purchaseOrder}
                  />
                </Container>

                <BulkSelectContainer
                  total={orderRequests.length}
                  visibleEntities={orderRequests}
                >
                  <>
                    <BulkActions
                      entityName={OrderRequest.entityName}
                      bulkActions={
                        purchaseOrder.status === 'open'
                          ? [
                              BulkAction.order_request_remove_purchase_order,
                              BulkAction.order_request_update_status,
                            ]
                          : [BulkAction.order_request_update_status]
                      }
                    />
                    <OrderRequestTable
                      isAdminPage={isAdminPage}
                      withAdditionalInfo={false}
                      totalPages={1}
                      emptyState={null}
                      actions={[]}
                      orderRequests={orderRequests}
                      withPrice
                      withCheckbox={
                        purchaseOrder.status !== 'completed'
                      }
                      footer={
                        <Table.Row>
                          <Table.Cell
                            className={styles.footerCell}
                            textAlign="right"
                            style={{
                              paddingRight:
                                purchaseOrder.outbound_purchase_order
                                  ? 20
                                  : 40,
                            }}
                          >
                            <div>
                              <div>
                                <b>Sub Total (Excl. VAT): </b>
                                <span style={{ padding: '5px' }}>
                                  {purchaseOrder.printSubTotal}
                                </span>
                              </div>
                              <br />
                              <div>
                                <div>Handling: </div>
                                <PurchaseOrderFormShowEditHandling
                                  purchaseOrder={purchaseOrder}
                                />
                              </div>

                              <br />

                              <div>
                                <div>Discount: </div>
                                <PurchaseOrderFormShowEditDiscount
                                  purchaseOrder={purchaseOrder}
                                />
                              </div>

                              <br />

                              <div>
                                <b>Total (Excl. VAT): </b>
                                <span style={{ padding: '5px' }}>
                                  {purchaseOrder.printTotal}
                                </span>
                              </div>

                              <br />

                              <div>
                                <div>VAT: </div>
                                <PurchaseOrderFormShowEditTaxRate
                                  purchaseOrder={purchaseOrder}
                                />
                              </div>

                              <br />

                              <div>
                                <b>Total (Incl. VAT): </b>
                                <span style={{ padding: '5px' }}>
                                  {purchaseOrder.printTotalWithVat}
                                </span>
                              </div>
                            </div>
                          </Table.Cell>
                          {purchaseOrder.outbound_purchase_order && (
                            <Table.Cell
                              style={{
                                width: 280,
                                paddingRight: 30,
                                borderLeft: '1px solid black',
                              }}
                              textAlign="right"
                              className={styles.outboundFooterOutCell}
                            >
                              <div>
                                <div>
                                  <b>Sub Total (Excl. VAT): </b>
                                  <span style={{ padding: '5px' }}>
                                    {
                                      purchaseOrder
                                        .outbound_purchase_order
                                        .printSubTotal
                                    }
                                  </span>
                                </div>
                                <br />
                                <div>
                                  <div>Handling: </div>
                                  <OutboundPurchaseOrderFormShowEditHandling
                                    outboundPurchaseOrder={
                                      purchaseOrder.outbound_purchase_order
                                    }
                                  />
                                </div>

                                <br />
                                <div>
                                  <div>Discount: </div>
                                  <OutboundPurchaseOrderFormShowEditDiscount
                                    outboundPurchaseOrder={
                                      purchaseOrder.outbound_purchase_order
                                    }
                                  />
                                </div>

                                <br />

                                <div>
                                  <b>Total (Excl. VAT): </b>
                                  <span style={{ padding: '5px' }}>
                                    {
                                      purchaseOrder
                                        .outbound_purchase_order
                                        .printTotal
                                    }
                                  </span>
                                </div>

                                <br />

                                <div>
                                  <div>VAT: </div>
                                  <OutboundPurchaseOrderFormShowEditTaxRate
                                    outboundPurchaseOrder={
                                      purchaseOrder.outbound_purchase_order
                                    }
                                  />
                                </div>

                                <br />

                                <div>
                                  <b>Total (Incl. VAT): </b>
                                  <span style={{ padding: '5px' }}>
                                    {
                                      purchaseOrder
                                        .outbound_purchase_order
                                        .printTotalWithVat
                                    }
                                  </span>
                                </div>

                                <br />

                                <br />

                                <div>
                                  <b>Commission: </b>
                                  <span style={{ padding: '5px' }}>
                                    {purchaseOrder.printCommission}
                                  </span>
                                </div>
                              </div>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      }
                    />
                  </>
                </BulkSelectContainer>
              </>
            )}
          </>
        );
      }}
    </GuardAdminLabstep>
  );
};

export default PurchaseOrderDetails;
