/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import CurrencyService from 'labstep-web/services/currency.service';
import rules from 'labstep-web/services/validation/rules';
import React from 'react';

export const colDefAmount: ColDef<OrderRequest> = {
  colId: 'amount',
  headerName: 'Price',
  headerComponentParams: {
    icon: ICONS.order_request.info.price,
  },
  valueGetter: (params) => params.data.printAmount,
  cellRenderer: (params) =>
    params.data.amount ? (
      params.data.printAmount
    ) : (
      <DataGridPlaceholder
        params={params}
        editable={getEditable()}
        placeholder="No price"
      />
    ),
  editable: getEditable(),
  valueSetter: (params) => {
    const createOrUpdateProps = {
      entityName: params.data.entityName,
      id: params.data.idAttr,
    };
    let amount: unknown;
    let currency: unknown;
    if (params.newValue === null) {
      amount = null;
      currency = null;
    } else {
      amount = CurrencyService.getAmountFromAmountCurrency(
        params.newValue,
      );
      currency = CurrencyService.getCurrencyFromAmountCurrency(
        params.newValue,
      );
    }
    let amountUpdated = false;
    let currencyUpdated = false;
    if (amount || amount === null) {
      amountUpdated = setValue(
        'amount',
        amount,
        createOrUpdateProps,
        rules.order_request.amount,
      );
    }
    if (currency || currency === null) {
      currencyUpdated = setValue(
        'currency',
        currency,
        createOrUpdateProps,
        rules.order_request.currency,
      );
    }
    return amountUpdated || currencyUpdated;
  },
};
