/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Price
 * @desc Select price
 */

import OrganizationPlanItem from 'labstep-web/components/OrganizationPlan/Item';
import { supportedIntervals } from 'labstep-web/constants/organization-plan-price';
import Flex from 'labstep-web/core/Flex';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { OrganizationPlanPrice } from 'labstep-web/models/organization-plan-price.model';
import React, { useEffect, useState } from 'react';
import SelectCurrency from './Select/Currency';
import SelectInterval from './Select/Interval';
import styles from './styles.module.scss';
import {
  IChildrenProps,
  OrganizationPlanManagerPriceContainerProps,
  OrganizationPlanManagerPriceProps,
} from './types';

const ORGANIZATION_PLAN_PRICE_ACADEMIC_FREE: OrganizationPlanPrice =
  new OrganizationPlanPrice({
    id: 'academic_free',
    tier: 'academic_free',
  });
const ORGANIZATION_PLAN_PRICE_ACADEMIC_INSTITUTE: OrganizationPlanPrice =
  new OrganizationPlanPrice({
    id: 'academic_institute',
    tier: 'academic_institute',
  });

export const OrganizationPlanManagerPrice: React.FC<
  OrganizationPlanManagerPriceProps
> = ({
  organizationPlanPrices,
  priceCurrency,
  setPriceCurrency,
  activePlanPrice,
  setActivePlanPrice,
  academic,
}) => {
  const [activeInterval, setActiveInterval] =
    useState<(typeof supportedIntervals)[number]>('year');
  useEffect(() => {
    setActivePlanPrice(
      organizationPlanPrices.find(
        (organizationPlanPrice) =>
          (organizationPlanPrice.tier === 'academic_plus' ||
            organizationPlanPrice.tier === 'pro') &&
          organizationPlanPrice.interval === activeInterval,
      ) || null,
    );
  }, []);
  const organizationPlanPricesByInterval =
    organizationPlanPrices.filter(
      (organizationPlanPrice) =>
        organizationPlanPrice.interval === activeInterval,
    );

  return (
    <Flex hAlignContent="center">
      <div className={styles.container}>
        <Flex
          className={styles.selectContainer}
          vAlignContent="center"
          hAlignContent="right"
        >
          <SelectInterval
            activeInterval={activeInterval}
            setActiveInterval={(interval) => {
              setActiveInterval(interval);
              if (activePlanPrice) {
                setActivePlanPrice(
                  organizationPlanPrices.find(
                    (organizationPlanPrice) =>
                      organizationPlanPrice.tier ===
                        activePlanPrice.tier &&
                      organizationPlanPrice.interval === interval,
                  ) || null,
                );
              }
            }}
          />
          <SelectCurrency
            activeCurrency={priceCurrency}
            setActiveCurrency={setPriceCurrency}
          />
        </Flex>
        <div className={styles.plansContainer}>
          {academic && (
            <OrganizationPlanItem
              organizationPlanPrice={
                ORGANIZATION_PLAN_PRICE_ACADEMIC_FREE
              }
              priceCurrency={priceCurrency}
              onClick={() => {
                // Do nothing
              }}
              isSelected={false}
            />
          )}
          {organizationPlanPricesByInterval.map(
            (organizationPlanPrice) => (
              <OrganizationPlanItem
                key={organizationPlanPrice.id}
                organizationPlanPrice={organizationPlanPrice}
                isSelected={
                  !!activePlanPrice &&
                  activePlanPrice.id === organizationPlanPrice.id
                }
                priceCurrency={priceCurrency}
                onClick={() =>
                  setActivePlanPrice(organizationPlanPrice)
                }
              />
            ),
          )}
          {academic && (
            <OrganizationPlanItem
              organizationPlanPrice={
                ORGANIZATION_PLAN_PRICE_ACADEMIC_INSTITUTE
              }
              priceCurrency={priceCurrency}
              onClick={() => {
                // Do nothing
              }}
              isSelected={false}
            />
          )}
        </div>
      </div>
    </Flex>
  );
};

export const OrganizationPlanManagerPriceContainer: React.FC<
  OrganizationPlanManagerPriceContainerProps
> = ({ ...rest }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={OrganizationPlanPrice.entityName}
    params={{}}
    loading={{ loader: 'placeholder' }}
  >
    {({ entities: organizationPlanPrices }: IChildrenProps) => (
      <OrganizationPlanManagerPrice
        organizationPlanPrices={organizationPlanPrices}
        {...rest}
      />
    )}
  </ReadOnMountHOC>
);

export default OrganizationPlanManagerPriceContainer;
