/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Type
 * @desc Metadata field type metadata type
 */

import React from 'react';
import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import { METADATA_TYPES_DATA } from 'labstep-web/models/metadata/constants';
import { IMetadataShowEditTypeProps } from './types';

export const MetadataShowEditType: React.FC<
  IMetadataShowEditTypeProps
> = ({ metadata }) => (
  <EntityActionUpdateDropdownPill
    entity={metadata}
    items={METADATA_TYPES_DATA.map((type) => ({
      label: type.label,
      body: {
        type: type.value,
      },
    }))}
    currentValue={
      METADATA_TYPES_DATA.find(
        (type) => type.value === metadata.type,
      )!.label
    }
  />
);

export default MetadataShowEditType;
