/**
 * Labstep
 */

import { IEntityDataGridContextMenuFilterProps } from 'labstep-web/components/Entity/DataGrid/ContextMenu/types';
import ResourceItemFilterResourceLocation from 'labstep-web/components/ResourceItem/Filter/ResourceLocation';
import { ICONS } from 'labstep-web/constants/icons';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import { getResourceLocationModal } from './utils';

/**
 * Column definition for resource location
 * @returns Column definition
 */
const colDefResourceLocation = {
  colId: 'resource_location',
  headerName: ResourceLocation.getHumanReadableEntityName(
    false,
    true,
  ),
  headerComponentParams: {
    icon: ICONS.resource_location.primary,
    filter: (props: IEntityDataGridContextMenuFilterProps) => (
      <ResourceItemFilterResourceLocation {...props} />
    ),
  },
  cellRenderer: (params): React.ReactElement =>
    params.data.resource_location ? (
      getResourceLocationModal(params, false)
    ) : (
      <DataGridPlaceholder
        params={params}
        children={`Specify ${ResourceLocation.getHumanReadableEntityName()}`}
        editable={getEditable()}
        placeholder={`No ${ResourceLocation.getHumanReadableEntityName()}`}
      />
    ),
  cellStyle: { display: 'flex' },
  cellEditor: (params) => getResourceLocationModal(params, true),
  cellEditorPopup: true,
  editable: getEditable(),
  valueGetter: (params): number | null =>
    params.data.resource_location?.idAttr || null,
  valueSetter: (params): boolean =>
    setValue('resource_location_guid', params.newValue, {
      entityName: params.data.entityName,
      id: params.data.idAttr,
    }),
};

export default colDefResourceLocation;
