/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card
 * @desc Card to display organization plan details
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Card from 'labstep-web/core/Card/Card';
import SubHeader from 'labstep-web/core/SubHeader';
import Header from 'labstep-web/core/Header';
import { IOrganizationPlanCardProps } from './types';
import styles from './styles.module.scss';

const OrganizationPlanCard: React.FC<IOrganizationPlanCardProps> = ({
  icon,
  header,
  primary,
  secondary,
}) => (
  <Card>
    <div className={styles.container}>
      <div>
        <Icon name={icon} />
      </div>
      <div className={styles.info}>
        <SubHeader>{header}</SubHeader>
        {primary && <Header>{primary}</Header>}
        {secondary && <div>{secondary}</div>}
      </div>
    </div>
  </Card>
);

export default OrganizationPlanCard;
