/**
 * Labstep
 *
 * @module models/entity-import
 * @desc Typescript export class for EntityImport
 */

import type { CellClassParams, ColDef } from 'ag-grid-community';
import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Device } from 'labstep-web/models/device.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Resource } from 'labstep-web/models/resource.model';
import { User } from 'labstep-web/models/user.model';
import { ResourceItem } from './resource-item.model';

export const ENTITY_IMPORT_DATE_FORMATS = [
  'yyyy-MM-dd',
  'yyyy/MM/dd',
  'yyyy.MM.dd',
  'dd-MM-yyyy',
  'dd/MM/yyyy',
  'dd.MM.yyyy',
  'ddMMyyyy',
  'ddMMyy',
  'yyyyMMdd',
  'yyMMdd',
];

export const ENTITY_IMPORT_DATETIME_FORMATS = [
  'yyyy-MM-dd HH:mm',
  'yyyy-MM-dd HH:mm:ss',
  'yyyy/MM/dd HH:mm',
  'yyyy/MM/dd HH:mm:ss',
];

export const ENTITY_IMPORT_DEFAULT_DATE_FORMAT = {
  date: ENTITY_IMPORT_DATE_FORMATS[0],
  datetime: ENTITY_IMPORT_DATETIME_FORMATS[0],
};

export type ParamsValidation = Pick<CellClassParams, 'value'> & {
  colDef: EntityImportColDef;
};

export interface EntityImportColDef extends ColDef {
  entity_import?: {
    metadata?: Metadata;
    date_format?: string;
    default_value?: string | null;
  };
  isRequired?: boolean;
  isCustom?: boolean;
  /** Child entity name column definition belongs to, if provided */
  entityName?: EntityImportEntity['entityName'];
  validator?: (params: ParamsValidation) => boolean;
}

export type EntityImportDataRowDataRow = Record<
  string,
  string | null
>;
export type EntityImportDataRowData = EntityImportDataRowDataRow[];

export interface EntityImportCellObject {
  value: string;
  isValid: boolean;
  datetime?: string;
}

export type EntityImportDataItems = Record<
  string,
  EntityImportDataItem[]
>;

export interface EntityImportDataItem {
  fields?: Record<string, unknown>;
  metadatas?: EntityImportDataItemMetadata[];
  relations?: EntityImportDataItemRelations;
}

export interface EntityImportDataItemMetadata {
  template_guid: string;
  fields: Record<string, unknown>;
}

export interface EntityImportDataItemRelation {
  name?: string;
  entity_import_row_index?: number | null;
  path?: string;
}
export type EntityImportDataItemRelationWithMetadatas = Record<
  string,
  EntityImportDataItemRelation & {
    metadatas?: EntityImportDataItemMetadata[];
  }
>;

export type EntityImportDataItemRelations = Record<
  string,
  EntityImportDataItemRelation
>;

export interface EntityImportData {
  rowData: EntityImportDataRowData;
  items: EntityImportDataItems;
}

export interface Step {
  mode: EntityImportStepMode;
  entity_name: string;
  total_count: number;
  success_count: number;
}

export interface StepData {
  step: number;
  steps: Record<string, Step>;
}

// eslint-disable-next-line no-shadow
export enum EntityImportCreateMode {
  create_always = 'create_always',
  create_skip_existing = 'create_skip_existing',
  create_update_existing = 'create_update_existing',
}

// eslint-disable-next-line no-shadow
export enum EntityImportStatus {
  preparing = 'preparing',
  started = 'started',
  completed = 'completed',
  failed = 'failed',
  revert_started = 'revert_started',
  revert_completed = 'revert_completed',
  revert_failed = 'revert_failed',
}

// eslint-disable-next-line no-shadow
export enum EntityImportStepMode {
  create = 'create',
  link = 'link',
  revert = 'revert',
}

export const STATUS_LABELS: Record<string, string> = {
  [EntityImportStatus.preparing]: 'Preparing data',
  [EntityImportStatus.started]: 'Running',
  [EntityImportStatus.completed]: 'Completed',
  [EntityImportStatus.revert_started]: 'Reverting',
  [EntityImportStatus.revert_completed]: 'Reverted',
};

export type EntityImportEntity = Resource | Device | ResourceItem;

export type EntityImportTargetEntityClass =
  EntityImportEntity['entityName'];

export class EntityImport extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityClass = 'entity_import' as const;

  // @deprecated
  public static readonly entityName = 'entity_import' as const;

  public get entityClass(): typeof EntityImport.entityClass {
    return EntityImport.entityClass;
  }

  // @deprecated
  public get entityName(): typeof EntityImport.entityName {
    return EntityImport.entityClass;
  }

  public constructor(data: Partial<EntityImport> = {}) {
    super();
    Object.assign(this, data);
  }

  @Type(() => User)
  public author!: User;

  public guid!: string;

  public created_at!: string;

  public name!: string;

  public data!: EntityImportData | null;

  public step_data!: StepData | null;

  public target_entity_name!: EntityImportTargetEntityClass;

  public status!: string;

  public success_count!: number;

  public total_count!: number;

  @Type(() => Device)
  public device_template!: Device;

  @Type(() => Resource)
  public resource_template!: Resource;

  public get template(): Device | Resource | null {
    if (Device.entityName === this.target_entity_name) {
      return this.device_template;
    }
    if (Resource.entityName === this.target_entity_name) {
      return this.resource_template;
    }
    if (ResourceItem.entityName === this.target_entity_name) {
      return this.resource_template;
    }
    return null;
  }

  public get statusLabel(): string {
    return STATUS_LABELS[this.status] || '';
  }

  public get canBeReverted(): boolean {
    return (
      this.status === EntityImportStatus.completed ||
      this.status === EntityImportStatus.failed
    );
  }
}
