/**
 * Labstep
 *
 * @module components/OrganizationPlan/Action/Upgrade
 * @desc Action to open stripe billing portal
 */

import React, { useState } from 'react';
import { CurrencyISO } from 'labstep-web/services/currency.service';
import { defaultCurrency } from 'labstep-web/constants/organization-plan-price';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { OrganizationPlanPrice } from 'labstep-web/models/organization-plan-price.model';
import OrganizationPlanManagerPrice from '../../Manager/Price';
import OrganizationPlanManagerPaymentMethodType from '../../Manager/PaymentMethodType';
import OrganizationPlanManagerSubmit from '../../Manager/Submit';
import OrganizationPlanManagerCustomer from '../../Manager/Customer';
import styles from './styles.module.scss';
import { IOrganizationPlanActionUpgradeProps } from './types';

export const ORGANIZATION_PLAN_ACTION_UPGRADE_ROUTE_ID = 'upgrade';

export const OrganizationPlanActionUpgrade: React.FC<
  IOrganizationPlanActionUpgradeProps<'mini' | 'fullscreen'>
> = ({ organization, ...rest }) => {
  const [activePlanPrice, setActivePlanPrice] =
    useState<OrganizationPlanPrice | null>(null);
  const [priceCurrency, setPriceCurrency] = useState<CurrencyISO>(
    organization.currency || defaultCurrency,
  );
  const formRef = React.useRef<{ submit: VoidFunction }>(null);
  const [paymentMethodType, setPaymentMethodType] = useState<
    string | null
  >(null);

  return (
    <ModalWizard
      mode="mini"
      className={styles.modal}
      routeId={ORGANIZATION_PLAN_ACTION_UPGRADE_ROUTE_ID}
      title="Upgrade Now"
      trigger={({ toggleModal }) => (
        <ActionComponent
          type="button"
          text="Upgrade Now"
          onClick={() => toggleModal()}
        />
      )}
      startIndex={organization.is_stripe_subscription_set ? 2 : 0}
      steps={[
        'Select a plan',
        'Enter your details',
        'Select a payment method',
      ]}
      {...rest}
    >
      <ModalWizard.Step index={0}>
        <OrganizationPlanManagerPrice
          organization={organization}
          priceCurrency={priceCurrency}
          setPriceCurrency={setPriceCurrency}
          activePlanPrice={activePlanPrice}
          setActivePlanPrice={setActivePlanPrice}
        />
      </ModalWizard.Step>
      <ModalWizard.Step
        index={1}
        onContinue={() => {
          if (!formRef.current) {
            return;
          }
          formRef.current.submit();
        }}
      >
        <OrganizationPlanManagerCustomer
          organization={organization}
          formRef={formRef}
        />
      </ModalWizard.Step>
      <ModalWizard.Step index={2}>
        {paymentMethodType && activePlanPrice ? (
          <OrganizationPlanManagerSubmit
            organization={organization}
            priceId={activePlanPrice.id}
            currency={priceCurrency}
            tier={activePlanPrice.tier}
            paymentMethodType={paymentMethodType}
          />
        ) : (
          <OrganizationPlanManagerPaymentMethodType
            currency={priceCurrency}
            setPaymentMethodType={setPaymentMethodType}
          />
        )}
      </ModalWizard.Step>
    </ModalWizard>
  );
};

export default OrganizationPlanActionUpgrade;
