/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import Icon from 'labstep-web/core/Icon';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import rules from 'labstep-web/services/validation/rules';
import React from 'react';
import styles from './styles.module.scss';

export const colDefIsUrgent: ColDef<OrderRequest> = {
  colId: 'is_urgent',
  headerName: 'Urgent',
  headerComponentParams: {
    icon: ICONS.order_request.info.urgent,
  },
  valueGetter: (params) => params.data.is_urgent,
  // only editing through copy/paste and cellRenderer
  cellEditor: () => null,
  cellEditorPopup: true,
  editable: getEditable(),
  cellRenderer: (params) => (
    <Icon
      name={ICONS.order_request.info.urgent}
      className={styles[`${params.data.is_urgent}`]}
      onClick={() =>
        setValue(
          'is_urgent',
          !params.data.is_urgent,
          {
            entityName: params.data.entityName,
            id: params.data.id,
          },
          rules.order_request.is_urgent,
        )
      }
    />
  ),
  valueSetter: (params) =>
    setValue(
      'is_urgent',
      params.newValue,
      {
        entityName: params.data.entityName,
        id: params.data.id,
      },
      rules.order_request.is_urgent,
    ),
};
