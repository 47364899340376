/**
 * Labstep
 *
 * @module components/User/List/Multi
 * @desc Group Users List
 */

import React from 'react';
import UserCard from 'labstep-web/components/User/Card/Preview';
import MultiList from 'labstep-web/core/List/Multi';
import { IUserGroupListMultiProps } from './types';

export const UserGroupListMulti: React.FC<
  IUserGroupListMultiProps
> = ({ id, actionMenu, userGroups }) => (
  <MultiList id={id} half>
    {userGroups.map((userGroup) => (
      <div key={userGroup.user.id}>
        <UserCard userGroup={userGroup} actionMenu={actionMenu} />
      </div>
    ))}
  </MultiList>
);

export default UserGroupListMulti;
