/**
 * Labstep
 *
 * @desc Action to create PermissionRole
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import PermissionRoleFormCreate from 'labstep-web/components/PermissionRole/Form/Create';
import { IPermissionRoleActionCreateProps } from './types';

const PermissionRoleActionCreate: React.FC<
  IPermissionRoleActionCreateProps
> = ({ organization }) => (
  <CanCreate
    parentName={organization.entityName}
    parentId={organization.id}
    entityName="permission_role"
  >
    <ModalDefault
      header="Add Role"
      content={({ toggleModal }) => (
        <PermissionRoleFormCreate
          organization={organization}
          options={{ onSuccess: toggleModal }}
          onCancel={toggleModal}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          showIcon
          type="button"
          text="Add Role"
          elementProps={{
            primary: true,
            basic: true,
          }}
          icon="user plus"
          onClick={toggleModal}
        />
      )}
    />
  </CanCreate>
);

export default PermissionRoleActionCreate;
