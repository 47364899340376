/**
 * Labstep
 *
 * @module components/Group/Settings/Devices
 */

import React from 'react';
import { TagType } from 'labstep-web/models/tag.model';
import TagIndex from 'labstep-web/screens/Tag/Index';
import DeviceIndexTemplate from 'labstep-web/screens/Device/Index/Template';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import { IGroupSettingsDevicesProps } from './types';

const GroupSettingsDevices: React.FC<IGroupSettingsDevicesProps> = ({
  group,
}) => {
  return (
    <div id="group-settings-devices" style={{ flex: 1 }}>
      <LayoutLinks
        inverted
        links={[
          {
            children: 'Categories',
            route: {
              to: 'group_settings_device_categories',
              params: { id: group.id },
            },
            render: () => <DeviceIndexTemplate group={group} />,
          },
          {
            children: 'Tags',
            route: {
              to: 'group_settings_device_tags',
              params: { id: group.id },
            },
            render: () => (
              <TagIndex group={group} type={TagType.device} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default GroupSettingsDevices;
