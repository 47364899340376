/**
 * Labstep
 *
 * @module components/Metadata/Action/Create/Modal/Dropdown
 * @desc Modal component for metadata arising from dropdown
 */

import MetadataActionCreateDropdown from 'labstep-web/components/Metadata/Action/Create/Dropdown';
import MetadataActionCreateModal from 'labstep-web/components/Metadata/Action/Create/Modal';
import ActionComponent from 'labstep-web/core/Action/Component';
import Loadable from 'labstep-web/core/Loadable';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { Resource } from 'labstep-web/models/resource.model';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React, { useState } from 'react';
import { IMetadataActionCreateContainerProps } from '../types';
import { IMetadataActionCreateModalDropdownProps } from './types';

export const MetadataFormCreateBulk = React.lazy(
  () => import('labstep-web/components/Metadata/Form/Create/Bulk'),
);

export const MetadataActionCreateModalDropdown: React.FC<
  IMetadataActionCreateModalDropdownProps
> = ({ entity, isTemplate, options }) => {
  const [selectedMetadataType, setSelectedMetadataType] =
    useState<MetadataType>(MetadataType.default);
  const [isBulk, setIsBulk] = useState<boolean>(false);
  const modalProps: NonNullable<
    IMetadataActionCreateContainerProps['modalProps']
  > = {
    viewComponent: ({ toggleModal }) => (
      <MetadataActionCreateDropdown
        trigger={
          <ActionComponent
            dataTestId="metadata-action-create-dropdown"
            icon="plus"
            type="text"
            text={`Add ${Metadata.getHumanReadableEntityName(
              false,
              true,
              entity,
            )}`}
          />
        }
        showChemistry={entity instanceof Resource}
        handleOnClickMetadataOption={(metadataType) => {
          toggleModal();
          setSelectedMetadataType(metadataType.value);
        }}
        handleOnClickBulkOption={() => {
          toggleModal();
          setIsBulk(true);
        }}
      />
    ),
    onClose: ({ toggleModal }) => {
      toggleModal();
      setIsBulk(false);
    },
    size: isBulk ? 'small' : 'tiny',
  };
  if (isBulk) {
    modalProps.content = ({ toggleModal }) => (
      <Loadable>
        <MetadataFormCreateBulk
          entity={entity}
          isTemplate={isTemplate}
          options={enhanceOptions({
            options,
            toast: true,
            onSuccess: () => {
              toggleModal();
              setIsBulk(false);
            },
          })}
        />
      </Loadable>
    );
  }
  return (
    <MetadataActionCreateModal
      isTemplate={isTemplate}
      entity={entity}
      entityName={entity.entityName}
      options={options}
      actionComponentProps={{ type: 'text', icon: 'plus' }}
      modalProps={modalProps}
      metadataType={selectedMetadataType}
      isHeaderPlural={isBulk}
    />
  );
};

export default MetadataActionCreateModalDropdown;
