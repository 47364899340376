/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Submit
 * @desc Submit the changes
 */

import { Elements } from '@stripe/react-stripe-js';
import { OrganizationBillingPortalContainer } from 'labstep-web/containers/Organization/BillingPortal';
import PaymentSetupForm from 'labstep-web/core/Stripe/PaymentSetupForm';
import { StripeService } from 'labstep-web/services/stripe.service';
import React, { useEffect, useState } from 'react';
import { OrganizationPlanManagerSubmitProps } from './types';

export const Sepa = ({
  clientSecret,
  confirmIntentSepaDebitAction,
  organization,
}) => (
  <Elements stripe={StripeService.loadStripeClient()}>
    <PaymentSetupForm
      clientSecret={clientSecret}
      onSuccess={() => confirmIntentSepaDebitAction(organization.id)}
    />
  </Elements>
);

export const Submit = ({
  organization,
  priceId,
  currency,
  tier,
  paymentMethodType,
  confirmIntentSepaDebitAction,
  getOrganizationSubscriptionPortal,
  setupIntentSepaDebitAction,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      StripeService.getStripePortal(
        organization,
        priceId,
        currency,
        tier,
        paymentMethodType,
        setClientSecret,
        getOrganizationSubscriptionPortal,
        setupIntentSepaDebitAction,
      );
    }
  }, [
    organization,
    priceId,
    currency,
    paymentMethodType,
    setClientSecret,
    getOrganizationSubscriptionPortal,
    setupIntentSepaDebitAction,
    isLoaded,
    setIsLoaded,
  ]);

  if (clientSecret) {
    return (
      <Sepa
        clientSecret={clientSecret}
        confirmIntentSepaDebitAction={confirmIntentSepaDebitAction}
        organization={organization}
      />
    );
  }

  return <div>Redirecting to Stripe...</div>;
};

export const OrganizationPlanManagerSubmit: React.FC<
  OrganizationPlanManagerSubmitProps
> = ({
  organization,
  priceId,
  tier,
  currency,
  paymentMethodType,
}) => {
  return (
    <OrganizationBillingPortalContainer>
      {({
        getOrganizationSubscriptionPortal,
        setupIntentSepaDebitAction,
        confirmIntentSepaDebitAction,
      }) => (
        <Submit
          organization={organization}
          priceId={priceId}
          currency={currency}
          tier={tier}
          paymentMethodType={paymentMethodType}
          getOrganizationSubscriptionPortal={
            getOrganizationSubscriptionPortal
          }
          setupIntentSepaDebitAction={setupIntentSepaDebitAction}
          confirmIntentSepaDebitAction={confirmIntentSepaDebitAction}
        />
      )}
    </OrganizationBillingPortalContainer>
  );
};

export default OrganizationPlanManagerSubmit;
