/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Timeline/Resource
 * @desc ExperimentWorkflow Timeline Resource component
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import EntityLink from 'labstep-web/components/Entity/Link';
import EntityUserActionManageList from 'labstep-web/components/EntityUser/Action/Manage/List';
import ExperimentWorkflowActionMenu from 'labstep-web/components/ExperimentWorkflow/Action/Menu';
import ActionComponent from 'labstep-web/core/Action/Component';
import { IIconProps } from 'labstep-web/core/Icon/types';
import styles from './styles.module.scss';
import { IExperimentWorkflowTimelineEventProps } from './types';

export const ExperimentWorkflowTimelineResource: React.FC<
  IExperimentWorkflowTimelineEventProps
> = ({ experimentWorkflow, actions, onClick, arrowAction }) => (
  <div className={styles.eventCard}>
    <Flex vAlignContent="center">
      <div className={styles.title}>
        <EntityLink entity={experimentWorkflow} editableName />
      </div>
      {arrowAction && (
        <div className={styles.arrowAction}>
          <ActionComponent
            type="icon"
            icon={`arrow ${arrowAction}` as IIconProps['name']}
            onClick={() => onClick(experimentWorkflow)}
            elementProps={{ popup: { content: 'View on Timeline' } }}
          />
        </div>
      )}
      <div className={styles.right}>
        <EntityUserActionManageList
          entity={experimentWorkflow}
          hidePopup
        />
        <ExperimentWorkflowActionMenu
          experimentWorkflow={experimentWorkflow}
          actions={actions}
        />
      </div>
    </Flex>
  </div>
);

export default ExperimentWorkflowTimelineResource;
