/**
 * Labstep
 *
 * @module services/ag-grid-validation
 * @desc AGGrid Validation Service
 */

import { RowNode } from 'ag-grid-community';
import { formatISO, isMatch, parse, set } from 'date-fns';
import {
  ENTITY_IMPORT_DEFAULT_DATE_FORMAT,
  EntityImportColDef,
  EntityImportDataRowDataRow,
  ParamsValidation,
} from 'labstep-web/models/entity-import.model';
import {
  IMetadataOptions,
  MetadataType,
} from 'labstep-web/models/metadata/types';
import {
  convertAmountUnitToAmount,
  convertAmountUnitToUnit,
} from '../amount-unit.service';

export class AGGridEntityImportValidationService {
  public static isNodeInvalid(
    node: RowNode,
    columnDefs?: EntityImportColDef[],
  ): boolean {
    return !AGGridEntityImportValidationService.isRowValid(
      node.data as EntityImportDataRowDataRow,
      columnDefs,
    );
  }

  public static isRowValid(
    row: EntityImportDataRowDataRow,
    columnDefs?: EntityImportColDef[],
  ): boolean {
    const rowIsEmpty =
      AGGridEntityImportValidationService.isRowEmpty(row);
    if (rowIsEmpty) {
      return true;
    }

    const rowIsInvalid = columnDefs?.some(
      (columnDef) =>
        !this.isCellValid({
          colDef: columnDef,
          value: row[columnDef.colId!],
        }),
    );

    return !rowIsInvalid;
  }

  public static isRowEmpty(row: EntityImportDataRowDataRow): boolean {
    return (
      Object.keys(row).filter(
        (key: string) => this.parseValue(row[key]) === '',
      ).length === Object.keys(row).length
    );
  }

  public static isCellValid(params: ParamsValidation): boolean {
    const paramsParsed = {
      ...params,
      value: this.parseValue(params.value),
    };
    const { colDef } = paramsParsed;
    if (colDef.isRequired && !this.validateIsRequired(paramsParsed)) {
      return false;
    }
    if (this.isValueEmpty(paramsParsed.value)) {
      return true;
    }
    if (colDef.validator) {
      return colDef.validator(paramsParsed);
    }
    return true;
  }

  public static validateIsRequired = (params: ParamsValidation) => {
    const { value } = params;
    return !AGGridEntityImportValidationService.isValueEmpty(value);
  };

  public static validatorMetadata = (
    params: ParamsValidation,
    metadataType: MetadataType,
  ) => {
    if (metadataType === MetadataType.date) {
      return this.validateDate(params);
    }

    if (metadataType === MetadataType.datetime) {
      return this.validateDatetime(params);
    }

    if (metadataType === MetadataType.numeric) {
      return this.validateNumeric(params);
    }

    if (metadataType === MetadataType.options) {
      return this.validateOptions(params);
    }

    return true;
  };

  public static parseValue = (value: string | null) => {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  };

  public static isValueEmpty = (value: string) =>
    value === '' || !value;

  public static validateNumeric = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    const number = convertAmountUnitToAmount(value);
    const unit = convertAmountUnitToUnit(value);
    return !!(number || unit);
  };

  public static validateNumber = (params: ParamsValidation) => {
    const { value } = params;
    return !Number.isNaN(Number(value));
  };

  public static validateDate = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    return isMatch(
      value,
      AGGridEntityImportValidationService.getDateFormat(
        params.colDef,
        'date',
      ),
    );
  };

  public static validateDatetime = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    return isMatch(
      value,
      AGGridEntityImportValidationService.getDateFormat(
        params.colDef,
        'datetime',
      ),
    );
  };

  public static validateOptions = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    const metadataOptions =
      AGGridEntityImportValidationService.getMetadataOptions(params);
    if (!metadataOptions) {
      return false;
    }
    if (metadataOptions.is_allow_add) {
      return true;
    }

    const supportedValues = Object.keys(metadataOptions.values);
    const selectedValues: string[] = value
      .split(',')
      .map((subValue: string) => subValue.trim());

    if (
      !metadataOptions.is_allow_multiple &&
      selectedValues.length > 1
    ) {
      return false;
    }

    return selectedValues.every((subValue) =>
      supportedValues.includes(subValue),
    );
  };

  public static getMetadataOptions(
    params: ParamsValidation,
  ): IMetadataOptions | null {
    const colDef = params.colDef as EntityImportColDef;
    if (
      colDef.entity_import?.metadata &&
      colDef.entity_import?.metadata.type === MetadataType.options
    ) {
      return colDef.entity_import?.metadata?.options || null;
    }
    return null;
  }

  public static getDateFormat(
    colDef: EntityImportColDef,
    type: 'date' | 'datetime',
  ): string {
    const defaultDateFormat = ENTITY_IMPORT_DEFAULT_DATE_FORMAT[type];
    return colDef?.entity_import?.date_format
      ? colDef.entity_import.date_format
      : defaultDateFormat;
  }

  public static formatDateISO(
    value: string,
    colDef: EntityImportColDef,
    type: 'date' | 'datetime',
  ): string {
    let date = parse(
      value,
      AGGridEntityImportValidationService.getDateFormat(colDef, type),
      new Date(),
    );
    if (type === 'date') {
      date = set(date, {
        hours: 12,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
    }
    return formatISO(date);
  }
}
