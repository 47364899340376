/**
 * Labstep
 *
 * @module components/OutboundPurchaseOrder/Action/Create
 * @desc Create Action for outboundPurchaseOrder request workflow
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldOutboundPurchaseOrderHandlingAmount: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'handling_amount',
    fieldLabel: 'Handling Amount',
    validation: rules.purchase_order.handling_amount,
    placeholder: 'Enter handling amount',
  };

export const fieldOutboundPurchaseOrderDiscountAmount: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'discount_amount',
    validation: rules.purchase_order.discount_amount,
    placeholder: 'Enter discount amount',
  };

export const fieldOutboundPurchaseOrderTaxRate: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'tax_rate',
    fieldLabel: 'Vat',
    validation: rules.purchase_order.tax_rate,
    placeholder: 'Enter Vat',
  };
