/**
 * Labstep
 */

import React from 'react';
import { IStatus } from 'labstep-web/typings';

export const defaultModalWizardContext = {
  activeStepIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveStepIndex: () => {
    // Do nothing
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goBack: () => {
    // Do nothing
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goForward: () => {
    // Do nothing
  },
  startIndex: 0,
  isLastStep: false,
  status: undefined,
  errorMessages: [],
  steps: [],
};

export type onContinueType = (props: {
  goForward: VoidFunction;
  goBack: VoidFunction;
  setErrorMessages: (errorMessages: string[]) => void;
}) => void;

export interface IModalWizardContextProps {
  activeStepIndex: number;
  setActiveStepIndex: (index: number) => void;
  goBack: () => void;
  goForward: (onContinue?: onContinueType) => void;
  startIndex: number;
  isLastStep: boolean;
  status?: IStatus;
  errorMessages: string[];
  steps: string[];
}

export const ModalWizardContext =
  React.createContext<IModalWizardContextProps>(
    defaultModalWizardContext,
  );

export const useModalWizardContext = () => {
  const context = React.useContext(ModalWizardContext);
  return context;
};
