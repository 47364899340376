/**
 * Labstep
 *
 * @module components/OrganizationPlan/PaymentMethod
 * @desc Component to show a payment method
 */

import React from 'react';
import styles from './styles.module.scss';
import { OrganizationPaymentMethodProps } from './types';

const OrganizationPaymentMethod: React.FC<
  OrganizationPaymentMethodProps
> = ({ icon, label, description, onClick }) => (
  <div className={styles.container} onClick={onClick}>
    <img src={icon} width="32" height="32" alt={label} />
    <div className={styles.label}>{label}</div>
    <div className={styles.description}>{description}</div>
  </div>
);

export default OrganizationPaymentMethod;
