/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Text/Benefit
 * @desc Benefit text for organization plan card
 */

import React from 'react';
import Header from 'labstep-web/core/Header';
import styles from './styles.module.scss';
import { OrganizationPlanCardTextBenefitProps } from './types';

export const OrganizationPlanCardTextBenefit: React.FC<
  OrganizationPlanCardTextBenefitProps
> = ({ text }) => (
  <Header size="mini" className={styles.green}>{`+ ${text}`}</Header>
);

export default OrganizationPlanCardTextBenefit;
