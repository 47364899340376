/**
 * Labstep
 *
 * @module components/Table/Form/ShowEdit/Name
 * @desc ShowEdit for name for resource item
 */

import React from 'react';
import ShowEdit from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldTableName } from 'labstep-web/components/Table/Form/fields';
import Button from 'labstep-web/core/Button';
import { ITableFormShowEditNameProps } from './types';
import styles from './styles.module.scss';

const TableFormShowEditName: React.FC<
  ITableFormShowEditNameProps
> = ({ table, onDone, status }) => (
  <div className={styles.header}>
    <ShowEdit
      entityName={table.entityName}
      entity={table}
      field={fieldTableName}
    >
      <span>{table.name}</span>
    </ShowEdit>
    <div>
      <Button onClick={onDone} status={status} primary>
        Done
      </Button>
    </div>
  </div>
);

export default TableFormShowEditName;
