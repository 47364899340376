/**
 * Labstep
 *
 * @module components/UserOrganization/Action/Update
 * @desc Update UserOrganization
 */

import React from 'react';
import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import { capitalize } from 'labstep-web/services/i18n.service';
import { UserOrganizationType } from 'labstep-web/models/user-organization.model';
import { IUserOrganizationActionUpdateProps } from './types';

export const UserOrganizationActionUpdate: React.FC<
  IUserOrganizationActionUpdateProps
> = ({ userOrganization }) => (
  <EntityActionUpdateDropdownPill
    entity={userOrganization}
    items={Object.keys(UserOrganizationType).map((type) => ({
      label: capitalize(type),
      body: { type },
    }))}
    currentValue={capitalize(userOrganization.type)}
  />
);

export default UserOrganizationActionUpdate;
