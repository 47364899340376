/**
 * Labstep
 *
 * @module components/Experiment/Card
 * @desc Experiment Card
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import ExperimentActionMenu from 'labstep-web/components/Experiment/Action/Menu';
import ExperimentActionStartEnd from 'labstep-web/components/Experiment/Action/StartEnd';
import { ICONS } from 'labstep-web/constants/icons';
import ElementCard from 'labstep-web/core/Card/Element';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import React from 'react';
import styles from './styles.module.scss';
import { IExperimentCardProps } from './types';

const ExperimentCard: React.FC<IExperimentCardProps> = ({
  experiment,
  dragHandle,
}) => (
  <ElementCard
    icon={ICONS.experiment.primary}
    name={
      experiment.useLatestProtocol ? (
        <span className={styles.primary}>
          <Link
            to="protocol_collection_show"
            params={{ id: experiment.protocol_collection_id }}
          >
            {experiment.name}
          </Link>
        </span>
      ) : (
        <EntityLink entity={experiment} />
      )
    }
    right={
      experiment.locked_at ? (
        <Icon name={ICONS.experiment.locked} />
      ) : (
        <ExperimentActionStartEnd
          experiment={experiment}
          actionType="icon"
        />
      )
    }
    actions={
      <ExperimentActionMenu
        experiment={experiment}
        actions={['lock', 'delete', 'is_use_latest_protocol']}
      />
    }
    dragHandle={dragHandle}
  />
);

export default ExperimentCard;
