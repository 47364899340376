/**
 * Labstep
 *
 * @module components/Resource/Form/fields
 * @desc Resource fields
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Group } from 'labstep-web/models/group.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldExperimentWorkflowName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  validation: rules.experiment_workflow.name,
  placeholder: 'Give your experiment a name',
  header: true,
};

export const fieldExperimentWorkflowNameWithLabel: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'name',
    fieldLabel: 'Name',
    validation: rules.experiment_workflow.name,
    placeholder: 'Give your experiment a name',
    header: true,
  };

export const fieldExperimentWorkflowPlannedAt: IFieldWithoutActionProps =
  {
    name: 'planned_at',
    fieldLabel: 'Planned Dates',
    validation: rules.experiment_workflow.planned_at,
    fieldType: FieldType.DateTimePicker,
    elementProps: {
      dateTimePickerOptions: {
        mode: 'range',
        rangeNames: ['start_planned_at', 'end_planned_at'],
      },
    },
  };

export const getFieldExperimentWorkflowTemplate = (
  activeGroupId: Group['id'],
): IFieldWithoutActionProps => {
  return {
    name: 'source',
    fieldLabel: 'Template',
    validation: rules.experiment_workflow.template,
    fieldType: FieldType.SearchSelect,
    elementProps: {
      entityName: ExperimentWorkflow.entityName,
      placeholder: 'Search templates...',
      params: { is_template: true, group_id: activeGroupId },
    },
  };
};
