/**
 * Labstep
 */

import { IParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { IStatus } from 'labstep-web/typings';

type ICustomFilter = React.FC<{ setParams: any; searchParams: any }>;

// eslint-disable-next-line no-shadow
export enum Filter {
  created_by = 'created_by',
  assigned_to = 'assigned_to',
  deleted = 'deleted',
  signed = 'signed',
  date_range = 'date_range',
  user_search_select = 'user_search_select',
  tag = 'tag',
  resource_item_status = 'resource_item_status',
  available_resource_item_count = 'available_resource_item_count',
  order_request_status = 'order_request_status',
  purchase_order_status = 'purchase_order_status',
  extension = 'extension',
  protocol = 'protocol',
  resource = 'resource',
  is_user_deleted = 'is_user_deleted',
  experiment_workflow_status = 'experiment_workflow_status',
  experiment_workflow_status_type = 'experiment_workflow_status_type',
  resource_location = 'resource_location',
  requested_by = 'requested_by',
  molecule = 'molecule',
  metadata = 'metadata',
  resource_metadata = 'resource_metadata',
  is_group_deleted = 'is_group_deleted',
}
type IFilter = Filter | ICustomFilter;

export interface IFilterMenuContentProps {
  setParams?: any;
  clearAll?: any;
  filters: IFilter[];
  searchParams?: any;
  entityName?: string;
  toggleMenu?: any;
  toggleSideMenu?: any;
  addPostFilter: IParamsHOCChildrenProps['addPostFilter'];
}

export interface IFilterMenuProps
  extends Omit<
    IFilterMenuContentProps,
    'searchParams' | 'setParams' | 'addPostFilter'
  > {
  trigger: React.ReactNode;
  usePostFilter?: boolean;
}
