/**
 * Labstep
 *
 * @module models/resource
 * @desc Typescript export class for Resource Location
 */

import { Entity } from 'labstep-web/models/entity.model';
import { PermissionActions } from 'labstep-web/typings';
import { Type } from 'class-transformer';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Thread } from 'labstep-web/models/thread.model';
import { File as FileModel } from 'labstep-web/models/file.model';
import { Group } from 'labstep-web/models/group.model';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Layout } from 'react-grid-layout';
import { User } from './user.model';
import { PermaLink } from './perma-link.model';

export interface IMapDataItemProps {
  name: string;
  resource_name?: string;
  item: Layout;
}

export class ResourceLocation extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'resource_location' as const;

  readonly defaultImageSrc = '/img/resource-category/default.svg';

  get entityName(): typeof ResourceLocation.entityName {
    return ResourceLocation.entityName;
  }

  constructor(data: Partial<ResourceLocation> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  name!: string;

  resource_item_count!: number;

  inner_location_count!: number;

  entity_users_count!: number;

  inner_location_ids!: ResourceLocation['id'][];

  map_data!: {
    rowCount: number;
    columnCount: number;
    data: { [key: string]: IMapDataItemProps };
  };

  stock_checked_at!: string;

  location_path!: string;

  public allowed_actions!: PermissionActions[];

  @Type(() => Group)
  owner!: Group;

  @Type(() => ResourceLocation)
  outer_location!: ResourceLocation;

  @Type(() => User)
  author!: User;

  @Type(() => Thread)
  thread!: Thread;

  @Type(() => MetadataThread)
  metadata_thread!: MetadataThread;

  @Type(() => FileModel)
  image?: FileModel;

  @Type(() => EntityUser)
  entity_users_preview!: EntityUser[];

  @Type(() => PermaLink)
  perma_link!: PermaLink;

  get initialLayout() {
    return this.map_data.data;
  }

  get path(): string {
    return this.location_path;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
