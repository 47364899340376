/**
 * Labstep
 *
 * @desc Manager for resourceTemplates
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import FilterItem from 'labstep-web/components/Filter/Item';
import { isActive as isFilterActive } from 'labstep-web/components/Filter/List/utils';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ActionComponent from 'labstep-web/core/Action/Component';
import ActionMenu from 'labstep-web/core/Action/Menu';
import List from 'labstep-web/core/List';
import { sortByName } from 'labstep-web/core/Tree/utils';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import React from 'react';
import {
  ICreateActionProps,
  IEntityTemplatePaneManagerContainerProps,
  IEntityTemplatePaneManagerProps,
} from './types';

export const params = {
  template_id: undefined,
  has_template: 'false',
};

export const CreateAction: React.FC<ICreateActionProps> = ({
  setActiveEditModal,
  entityName,
  postFilter,
}) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ setParams, addPostFilter }): React.ReactElement => (
        <EntityActionCreate
          entityName={entityName}
          actionComponentProps={{
            type: 'text',
            icon: ICONS.entity.actions.create,
          }}
          body={{ is_template: true }}
          options={{
            onSuccess: ({ response }): void => {
              const id = response.result;
              if (postFilter) {
                const { guid } =
                  response.entities.resource_template[id];
                addPostFilter(postFilter.getNode([guid]), true, 1);
              } else {
                setParams({ template_id: id });
              }
              setActiveEditModal({
                entityName,
                id,
              });
            },
          }}
        />
      )}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export const CreateActionContainer =
  withActiveEditModal(CreateAction);

export const EntityTemplatePaneManager: React.FC<
  IEntityTemplatePaneManagerProps
> = ({
  templates,
  showUncategorised,
  showAction,
  entityName,
  entityPreview,
  postFilter,
}) => (
  <List>
    <ParamsHOC historyAction="replace">
      <ParamsContext.Consumer>
        {({
          setParams,
          searchParams,
          addPostFilter,
        }): React.ReactElement => (
          <>
            {showUncategorised && (
              <FilterItem
                icon={ICONS.tag.info.untagged}
                text="Uncategorised"
                active={isFilterActive(searchParams, params)}
                onClick={(): void => {
                  setParams(params);
                }}
              />
            )}
            {templates
              .filter((f) => !f.deleted_at)
              .map((template) => {
                const isActive = postFilter
                  ? !!postFilter.getNodeInParams(searchParams, [
                      template.guid,
                    ])
                  : Number(searchParams.template_id) === template.id;
                return (
                  <FilterItem
                    key={template.id}
                    icon={ICONS.resource_template.primary}
                    text={template.name}
                    active={isActive}
                    onClick={(): void =>
                      postFilter
                        ? addPostFilter(
                            postFilter.getNode([template.guid]),
                            true,
                            1,
                          )
                        : setParams({
                            template_id: template.id,
                            has_template: undefined,
                          })
                    }
                    actionMenu={
                      <ActionMenu>
                        {entityPreview({
                          entity: template,
                          modalProps: {
                            viewComponent: ({
                              toggleModal,
                            }): React.ReactElement => (
                              <ActionComponent
                                type="option"
                                icon="cog"
                                text="Settings"
                                onClick={toggleModal}
                              />
                            ),
                          },
                        })}

                        <EntityActionDelete
                          entity={template}
                          options={{
                            onSuccess: (): void => {
                              if (isActive) {
                                if (postFilter) {
                                  addPostFilter(
                                    postFilter.getNode([undefined]),
                                    true,
                                    1,
                                  );
                                } else {
                                  setParams({
                                    template_id: undefined,
                                  });
                                }
                              }
                            },
                          }}
                        />
                      </ActionMenu>
                    }
                  />
                );
              })}
            {showAction && (
              <CreateActionContainer
                entityName={entityName}
                postFilter={postFilter}
              />
            )}
          </>
        )}
      </ParamsContext.Consumer>
    </ParamsHOC>
  </List>
);

export const EntityTemplatePaneManagerContainer: React.FC<
  IEntityTemplatePaneManagerContainerProps
> = ({
  activeGroup,
  showUncategorised = true,
  searchBarProps,
  entityName,
  entityPreview,
  postFilter,
}) => (
  <EntitySearchCursor
    entityName={entityName}
    params={{
      group_id: activeGroup?.id,
      is_template: 1,
      sort: 'name',
    }}
    emptyStateAction={
      <CreateActionContainer entityName={entityName} />
    }
    showEmptyStateOnSearchOnly
    searchBarProps={{
      placeholder: 'Search categories',
      ...searchBarProps,
    }}
  >
    {({ entities, searchParams }): React.ReactElement => {
      const searchActive = !!searchParams.search_query;
      return (
        <EntityTemplatePaneManager
          entityPreview={entityPreview}
          templates={searchActive ? entities : sortByName(entities)}
          showUncategorised={!searchActive && showUncategorised}
          showAction={!searchActive}
          entityName={entityName}
          postFilter={postFilter}
        />
      );
    }}
  </EntitySearchCursor>
);

export default withActiveGroup(EntityTemplatePaneManagerContainer);
