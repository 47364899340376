import React from 'react';

const iconUsers = 'user plus' as const;
const iconWorkspaces = 'sitemap' as const;
const iconUpload = 'cloud upload' as const;
const iconCheck = 'check' as const;

export const plans = {
  startup: {
    name: 'Startup',
    logoSrc: '/img/logo/tier/logo-startup.svg',
    description:
      'Great for small teams. Set your company up for success from day one.',
    users: '1-10',
    features: [
      {
        id: '1',
        text: (
          <>
            Up to <b>10 Users</b>
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            Up to <b>3 Workspaces</b>
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Notebook, Inventory and Order Management',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Live Chat Support & Help Centre',
        icon: iconCheck,
      },
    ],
    priceColor: 'blue',
  },
  pro: {
    name: 'Pro',
    logoSrc: '/img/logo/tier/logo-pro.svg',
    description:
      'Ideal for fast-growing companies. Adds scale and efficiency across teams',
    users: '11-50',
    features: [
      {
        id: '1',
        text: (
          <>
            <b>Unlimited</b> Users
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Startup Plan',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Roles & Permissions',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Two-Factor Authentication',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'Full Document Revision History',
        icon: iconCheck,
      },
      {
        id: '8',
        text: 'Tailored Onboarding and Setup',
        icon: iconCheck,
      },
    ],
    priceColor: 'blue',
  },
  enterprise: {
    name: 'Enterprise',
    logoSrc: '/img/logo/tier/logo-enterprise.svg',
    description:
      'A mature and robust solution for your entire organization. Customized to your needs.',
    users: '50+',
    features: [
      {
        id: '1',
        text: (
          <>
            <b>Unlimited</b> Users
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Pro Plan',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Reporting Dashboard',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Security Access Log',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'IP Whitelisting',
        icon: iconCheck,
      },
      {
        id: '8',
        text: 'SAML Single Sign On (SSO)',
        icon: iconCheck,
      },
      {
        id: '9',
        text: 'Dedicated Account Manager',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },

  // Academic

  academic_free: {
    name: 'Academic Free',
    logoSrc: '/img/logo/tier/logo-academic-free.svg',
    description:
      'For small academic labs and individuals looking to document their work.',
    users: '10',
    features: [
      {
        id: '1',
        text: (
          <>
            Up to <b>10 users</b>
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            Up to <b>3 Workspaces</b>
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Notebook, Inventory and Order Management Modules',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Live Chat Support & Help Centre',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },
  academic_plus: {
    name: 'Academic Plus',
    logoSrc: '/img/logo/tier/logo-academic-plus.svg',
    description:
      'For larger academic labs looking for more advanced features.',
    users: '10',
    features: [
      {
        id: '1',
        text: (
          <>
            Up to <b>50 users</b>
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Academic Free',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Roles & Permissions',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Full Document Revision History',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'Two Factor Authentication',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },
  academic_institute: {
    name: 'Academic Institute',
    logoSrc: '/img/logo/tier/logo-academic-institute.svg',
    description:
      'For Universities and Research Institutes looking for a site wide deployment.',
    users: '10',
    features: [
      {
        id: '1',
        text: (
          <>
            <b>Unlimited</b> Users
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Academic Plus',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Reporting Dashboard',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Security Access Log',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'IP Whitelisting',
        icon: iconCheck,
      },
      {
        id: '8',
        text: 'SAML Single Sign On (SSO)',
        icon: iconCheck,
      },
      {
        id: '9',
        text: 'Dedicated Success Manager',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },
};
