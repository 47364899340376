/**
 * Labstep
 *
 * @module components/Group/Settings/Ordering
 * @desc Ordering Group Card
 */

import OrderRequestTemplate from 'labstep-web/components/Group/Template/OrderRequest';
import PurchaseOrderTemplate from 'labstep-web/components/Group/Template/PurchaseOrder';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import TagIndex from 'labstep-web/screens/Tag/Index';
import { TagType } from 'labstep-web/models/tag.model';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import { IGroupSettingsOrderingProps } from './types';

const GroupSettingsOrdering: React.FC<
  IGroupSettingsOrderingProps
> = ({ group }) => (
  <Flex column grow style={{ maxWidth: '800px' }}>
    <LayoutLinks
      inverted
      links={[
        {
          children: 'Request Template',
          route: {
            to: 'group_settings_order_request_template',
            params: { id: group.id },
          },
          render: () => <OrderRequestTemplate group={group} />,
        },
        {
          children: 'Order Template',
          route: {
            to: 'group_settings_purchase_order_template',
            params: { id: group.id },
          },
          render: () => <PurchaseOrderTemplate group={group} />,
        },
        {
          children: 'Tags',
          route: {
            to: 'group_settings_order_request_tags',
            params: { id: group.id },
          },
          render: () => (
            <TagIndex group={group} type={TagType.order_request} />
          ),
        },
      ]}
    />
  </Flex>
);

export default GroupSettingsOrdering;
