import { Device } from '../device.model';
import { Experiment } from '../experiment.model';
import { Molecule } from '../molecule.model';
import { OrderRequest } from '../order-request.model';
import { Protocol } from '../protocol.model';
import { PurchaseOrder } from '../purchase-order.model';
import { ResourceItem } from '../resource-item.model';
import { ResourceLocation } from '../resource-location.model';
import { Resource } from '../resource.model';
import { User } from '../user.model';

export type MetadataEntityNameType = 'metadata';

// eslint-disable-next-line no-shadow
export enum MetadataType {
  default = 'default',
  date = 'date',
  datetime = 'datetime',
  numeric = 'numeric',
  options = 'options',
  molecule = 'molecule',
  sequence = 'sequence',
  file = 'file',
}

// eslint-disable-next-line no-shadow
export enum MetadataTypeValues {
  default = 'Text',
  date = 'Date',
  datetime = 'Date and time',
  numeric = 'Numeric',
  options = 'Options',
  molecule = 'Chemistry',
  sequence = 'Sequence',
  file = 'File / Image',
}

export type IMETADATA_TYPES = {
  label: MetadataTypeValues;
  value: MetadataType;
};

export type IMETADATA_FIELDS = {
  [key in MetadataType]: {
    fields: string[];
    fieldsToReset?: string[];
    fieldsToDelete?: string[];
  };
};

export type IMetadataOptionsValues = {
  [key: string]: boolean;
};

export type IMetadataOptions = {
  // keys: string[];
  values: IMetadataOptionsValues;
  is_allow_multiple: boolean;
  is_allow_add: boolean;
};

export interface IMetadataCreateProps {
  parentName: string;
  parentId: number;
  createBody: {
    template_id: number;
    molecule?: typeof Molecule.createBodyDefault;
    type?: MetadataType;
  };
  type: MetadataType;
  sequence?: string;
  molecule?: string;
}

export type IEntityWithMetadata =
  | Experiment
  | OrderRequest
  | PurchaseOrder
  | Device
  | Protocol
  | Resource
  | ResourceItem
  | ResourceLocation
  | User;
