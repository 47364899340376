/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';

export const sharelinkInvitationOrganizationActionType =
  'sharelink_invitation_organization';

export const sharelinkInvitationOrganizationAction = (
  body: Record<string, unknown>,
  options: any = {},
): any =>
  sfApi.post({
    type: 'SHARELINK_INVITATION_ORGANIZATION',
    route: {
      custom: 'app_sharelinkinvitation_organization',
    },
    meta: {
      action_type: sharelinkInvitationOrganizationActionType,
      customReducer: 1,
    },
    body,
    ...options,
  });
