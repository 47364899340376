/**
 * Labstep
 */

export const jupyterOAuthAuthorize = (
  responseType: string,
  redirectURI: string,
  clientId: string,
  state: string,
): any => ({
  type: 'JUPYTER_OAUTH_AUTHORIZE_REQUEST',
  meta: {
    params: {
      response_type: responseType,
      redirect_uri: redirectURI,
      client_id: clientId,
      state,
    },
  },
});

export const jupyterGetLink = (guid: string): any => ({
  type: 'JUPYTER_GET_LINK_REQUEST',
  meta: {
    guid,
  },
});

export const jupyterRun = (guid: string): any => ({
  type: 'JUPYTER_RUN_REQUEST',
  meta: {
    guid,
  },
});
