import React from 'react';
import {
  ProcessDataFromClipboardParams,
  CellEditingStartedEvent,
  EditableCallbackParams,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  CellClassParams,
  ColDef,
} from 'ag-grid-community';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { validateEmail } from 'labstep-web/state/selectors/helpers';
import { User } from './types';

export const isInvalidGrid = (
  params: CellValueChangedEvent,
): boolean => {
  const rowCount = params.api.getDisplayedRowCount();
  for (let i = 0; i < rowCount; i += 1) {
    const rowNode = params.api.getDisplayedRowAtIndex(i);
    const data = rowNode!.data as User;

    if (
      (data.email &&
        (!data.firstName ||
          !data.lastName ||
          !validateEmail(data.email))) ||
      (data.firstName &&
        data.firstName !== 'Add row' &&
        (!data.email || !data.lastName)) ||
      (data.lastName && (!data.email || !data.firstName))
    ) {
      return true;
    }
  }
  return false;
};

export const getCellStyle = (params: CellClassParams) => {
  const { email, firstName, lastName } = params.data as User;

  if (params.colDef.field === 'firstName') {
    if (!params.value && (lastName || email)) {
      return { backgroundColor: '#F5B1B1' };
    }
  } else if (params.colDef.field === 'lastName') {
    if (
      !params.value &&
      ((firstName && firstName !== 'Add row') || email)
    ) {
      return { backgroundColor: '#F5B1B1' };
    }
  } else if (params.colDef.field === 'email') {
    if (!params.value) {
      if ((firstName && firstName !== 'Add row') || lastName) {
        return { backgroundColor: '#F5B1B1' };
      }
    } else if (!validateEmail(params.value)) {
      return { backgroundColor: '#F5B1B1' };
    }
  }

  return null;
};

export const getEditable = (params: EditableCallbackParams) => {
  if (
    params.data.firstName === 'Add row' &&
    params.column.getColId() !== 'firstName'
  ) {
    return false;
  }
  return true;
};

export const cellRenderer = (params: any) => {
  const data = params.data as User;
  let children = 'Enter';
  let editable = true;

  if (data.firstName === 'Add row') {
    if (params.column.colId === 'firstName') {
      children = 'Add row';
    } else {
      children = ' ';
      editable = false;
    }
  }
  if (params.value === '' || params.value === 'Add row') {
    return (
      <DataGridPlaceholder
        params={params}
        editable={editable}
        children={children}
        placeholder={' '}
      />
    );
  }
  return params.value;
};

export const columnDefs: ColDef[] = [
  {
    headerName: 'First Name',
    field: 'firstName',
    cellRenderer,
    editable: getEditable,
    cellStyle: getCellStyle,
    flex: 1,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    editable: getEditable,
    cellRenderer,
    cellStyle: getCellStyle,
  },
  {
    headerName: 'Email Address',
    field: 'email',
    cellRenderer,
    editable: getEditable,
    cellStyle: getCellStyle,
  },
];

export const emptyUserData = [
  { firstName: '', lastName: '', email: '' },
  { firstName: '', lastName: '', email: '' },
  { firstName: '', lastName: '', email: '' },
  { firstName: 'Add row', lastName: '', email: '' },
];

export const onCellEditingStarted = (
  params: CellEditingStartedEvent,
) => {
  if (
    params.column.getColId() === 'firstName' &&
    params.data.firstName === 'Add row'
  ) {
    const user = params.data as User;
    params.node.setData({
      ...user,
      firstName: '',
    });
    params.api.applyTransaction({
      add: [{ firstName: 'Add row', lastName: '', email: '' }],
      addIndex: params.node.rowIndex ? params.node.rowIndex + 1 : 1,
    });
  }
};

export const onCellEditingStopped = (
  params: CellEditingStoppedEvent,
) => {
  const user = params.data as User;
  if (user.firstName === 'Add row') {
    params.node.setData({
      ...user,
      firstName: '',
    });
  }
};

export const processPastedData = (
  params: ProcessDataFromClipboardParams,
) => {
  const focusedCell = params.api!.getFocusedCell();
  const focusedRowIndex = focusedCell!.rowIndex;
  const focusedRow =
    params.api!.getDisplayedRowAtIndex(focusedRowIndex);

  const editableRows = params.api!.getDisplayedRowCount() - 1;

  const rowsToAdd =
    params.data.length - (editableRows - focusedRowIndex);

  const addIndex =
    focusedRow?.data.firstName === 'Add row'
      ? editableRows
      : editableRows - 1;

  if (rowsToAdd > 0) {
    const newRows = Array.from({ length: rowsToAdd }, () => ({
      firstName: '',
      lastName: '',
      email: '',
    }));
    params.api!.applyTransaction({
      add: newRows,
      addIndex,
    });

    params.api!.redrawRows();
  }
};
