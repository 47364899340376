/**
 * Labstep
 *
 * @module core/Dropdown/Pill
 * @desc Dropdown Pill component
 */

import Dropdown from 'labstep-web/core/Dropdown';
import React from 'react';
import DropdownPillTrigger from './Trigger';
import { IDropdownPillProps } from './types';

const DropdownPill: React.FC<IDropdownPillProps> = ({
  items,
  disabled,
  ...rest
}) => {
  const trigger = (
    <DropdownPillTrigger {...rest} disabled={disabled} />
  );
  return disabled ? (
    trigger
  ) : (
    <Dropdown.Dropdown
      trigger={trigger}
      icon={null}
      data-testid="dropdown-pill"
    >
      <Dropdown.Menu>
        {items.map((item) => (
          <Dropdown.Item
            key={item.label}
            onClick={item.onClick}
            data-testid={item.label}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Dropdown>
  );
};

export default DropdownPill;
