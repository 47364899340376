/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Referencing/Menu
 * @desc Menu component for referencing menu
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Menu from 'labstep-web/core/Menu';
import Ref, { RefWrapper } from 'labstep-web/core/Ref';
import Icon from 'labstep-web/core/Icon';
import MenuItem from 'labstep-web/prosemirror/components/Menu/Commands/Menu/Item';
import {
  getIcon,
  getLabel,
  getSecondaryLabel,
} from 'labstep-web/prosemirror/components/NodeView/Reference/Content';
import { getState as getReferencingPluginState } from 'labstep-web/prosemirror/extensions/referencing/commands/selectors';
import {
  handleSelectCategory,
  handleSelectItem,
} from 'labstep-web/prosemirror/extensions/referencing/commands';
import MenuCursor from 'labstep-web/prosemirror/components/Menu/Cursor';
import MenuReferencingMenuCreatableList from './Creatable/List';
import MenuReferencingMenuCreatableAction from './Creatable/Action';
import { IMenuReferencingMenuProps } from './types';
import styles from './styles.module.scss';
import { showAction } from './utils';

export const MenuReferencingMenu: React.FC<
  IMenuReferencingMenuProps
> = ({
  state,
  dispatch,
  entityName,
  cursorPosition,
  loading,
  debouncing,
}) => {
  const {
    isOpen,
    index,
    selectedCategory,
    availableCategories,
    availableItems,
    creatableItems,
    token,
  } = getReferencingPluginState(state);

  const actionIndex = availableItems.length + creatableItems.length;

  return (
    <MenuCursor cursorPosition={cursorPosition} isOpen={isOpen}>
      {({ menuRef, activeItemRef }) => {
        let items: React.ReactElement | React.ReactElement[];

        if (selectedCategory) {
          if (availableItems.length === 0) {
            items = !loading && !debouncing && (
              <Menu.Item onClick={null}>No item found</Menu.Item>
            );
          } else {
            items = availableItems.map((item, i: number) => {
              const isActive = index === i;
              return (
                <RefWrapper
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  innerRef={isActive && activeItemRef}
                >
                  <Menu.Item
                    active={isActive}
                    onClick={() =>
                      handleSelectItem(state, dispatch, i)
                    }
                  >
                    <div className={styles.item}>
                      <div>
                        <Icon
                          name={getIcon(selectedCategory.value)}
                        />
                        {getLabel(
                          item,
                          state.doc.content
                            .toJSON()
                            .filter((b) => b.type.endsWith('step'))
                            .map((b) => b.attrs.guid),
                        )}
                      </div>
                      {getSecondaryLabel(item, true)}
                    </div>
                  </Menu.Item>
                </RefWrapper>
              );
            });
          }
        } else {
          items = availableCategories.map((category, i) => {
            const isActive = index === i;
            return (
              <RefWrapper
                key={category.value}
                innerRef={isActive && activeItemRef}
              >
                <MenuItem
                  onClick={() =>
                    handleSelectCategory(state, dispatch, i)
                  }
                  active={isActive}
                  header={category.label}
                  icon={getIcon(category.value)}
                />
              </RefWrapper>
            );
          });
        }

        return (
          <Ref innerRef={menuRef}>
            <Menu vertical borderless fluid size="mini">
              <Menu.Item>
                <b>
                  {selectedCategory
                    ? `In this ${getHumanReadableEntityName(
                        entityName,
                      )} `
                    : 'Insert reference to'}
                  ...
                </b>
              </Menu.Item>
              {items}
              {selectedCategory &&
                (loading ||
                  (debouncing && availableItems.length === 0)) && (
                  <Menu.Item>Loading . . .</Menu.Item>
                )}
              {selectedCategory && (
                <>
                  {!!creatableItems.length &&
                    selectedCategory.creatableEntityName && (
                      <MenuReferencingMenuCreatableList
                        state={state}
                        dispatch={dispatch}
                        items={creatableItems}
                        selectedCategory={selectedCategory}
                        index={index}
                        startIndex={availableItems.length}
                        activeItemRef={activeItemRef}
                      />
                    )}
                  {showAction(selectedCategory) && (
                    <RefWrapper
                      innerRef={
                        actionIndex === index && activeItemRef
                      }
                    >
                      <MenuReferencingMenuCreatableAction
                        state={state}
                        dispatch={dispatch}
                        active={actionIndex === index}
                        index={actionIndex}
                        token={token}
                        selectedCategory={selectedCategory}
                      />
                    </RefWrapper>
                  )}
                </>
              )}
            </Menu>
          </Ref>
        );
      }}
    </MenuCursor>
  );
};

export default MenuReferencingMenu;
