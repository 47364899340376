/**
 * Labstep
 *
 * @module components/ShareLink/Accept/Entity
 * @desc Components to accept sharelink for entities
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Header from 'labstep-web/core/Header';
import OrganizationHeader from 'labstep-web/components/Organization/Header';
import OrganizationEmptyStateNoSeatsLeft from 'labstep-web/components/Organization/EmptyState/NoSeatsLeft';
import { IShareLinkGroupOrOrganizationProps } from './types';
import ShareLinkGroupOrOrganizationContent from './Content';

export const ShareLinkGroupOrOrganization: React.FC<
  IShareLinkGroupOrOrganizationProps
> = ({ shareLink, onClick, status }) => (
  <div
    style={{
      textAlign: 'center',
      margin: 'auto',
      marginTop: '10%',
    }}
  >
    {shareLink.organization?.noSeatsLeft ? (
      <OrganizationEmptyStateNoSeatsLeft
        organization={shareLink.organization}
      />
    ) : (
      <>
        {shareLink.organization && (
          <OrganizationHeader organization={shareLink.organization} />
        )}
        <Header size="small">
          {`You have been invited to join the ${
            shareLink.entity.displayName
          }
    ${getHumanReadableEntityName(
      shareLink.entity.entityName,
    )} on Labstep.`}
        </Header>
        <ShareLinkGroupOrOrganizationContent
          shareLink={shareLink}
          onClick={onClick}
          status={status}
        />
      </>
    )}
  </div>
);

export default ShareLinkGroupOrOrganization;
