/**
 * Labstep
 *
 * @author     Onoufrios Malikkides <onoufrios@labstep.com>
 *
 * @desc Empty State to show in for premium features
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Button from 'labstep-web/core/Button';
import { IPremiumFeatureEmptyStateProps } from './types';
import { premiumFeaturesInfo } from './utils';

export const PremiumFeatureEmptyState: React.FC<
  IPremiumFeatureEmptyStateProps
> = ({ premiumFeature }) => {
  const info = premiumFeaturesInfo[premiumFeature];
  return (
    <EmptyState
      src="/img/error/locked.svg"
      title="Premium Feature"
      explanation={`${
        info.name
      } is a premium feature. If you are interested in activating this feature click below to contact sales${
        info.link ? ' or learn more' : ''
      }.`}
      action={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // href not type in Semantic so need the comments
        <Button primary as="a" href="mailto:info@labstep.com">
          Contact Sales
        </Button>
      }
      secondaryAction={
        info.link && (
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            href={info.link}
            basic
            primary
            as="a"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </Button>
        )
      }
    />
  );
};

export default PremiumFeatureEmptyState;
