/**
 * Labstep
 *
 * @module components/UserOrganization/Action/AddBulkUsers
 * @desc Action to add multiple users to organization
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import CreateUsers from './CreateUsers';

export const UserOrganizationActionAddBulkUsers: React.FC = () => {
  return (
    <ModalDefault
      size="small"
      header="Add Users"
      content={({ toggleModal }) => (
        <EntityCreateContainer entityName={User.entityName} batch>
          {({ create, status }) => (
            <CreateUsers
              toggleModal={toggleModal}
              create={create}
              status={status}
            />
          )}
        </EntityCreateContainer>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          type="button"
          text="Add Users"
        />
      )}
    />
  );
};

export default UserOrganizationActionAddBulkUsers;
