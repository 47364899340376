/**
 * Labstep
 *
 * @module models/user-organization
 * @desc Typescript export class for UserOrganization
 */

import { Type } from 'class-transformer';
import { Organization } from 'labstep-web/models/organization.model';
import { User } from 'labstep-web/models/user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { capitalize } from 'labstep-web/services/i18n.service';

// eslint-disable-next-line no-shadow
export enum UserOrganizationType {
  admin = 'admin',
  member = 'member',
  guest = 'guest',
}

export class UserOrganization extends Entity {
  public static readonly entityName = 'user_organization';

  public get entityName(): string {
    return UserOrganization.entityName;
  }

  public constructor(data: Partial<UserOrganization> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public type!: UserOrganizationType;

  public get printRole(): string {
    if (this.user.deleted_at) {
      return 'Disabled';
    }
    return capitalize(this.type);
  }

  @Type(() => User)
  public user!: User;

  @Type(() => Organization)
  public organization!: Organization;
}
