/**
 * Labstep
 *
 * @module components/Metadata/Form/utils
 * @desc Utils for metadata forms
 */

import { Metadata } from 'labstep-web/models/metadata';
import {
  METADATA_FIELDS,
  METADATA_TYPES,
} from 'labstep-web/models/metadata/constants';
import {
  IMETADATA_TYPES,
  MetadataType,
} from 'labstep-web/models/metadata/types';
import { cloneDeep, uniq } from 'lodash';

/**
 * Adds molecule to create body if of type molecule
 */
export const addMoleculeToBody = (
  body: { [key: string]: any },
  type: MetadataType,
  metadata?: Metadata,
) => {
  if (
    type === MetadataType.molecule &&
    !(metadata && metadata.molecule)
  ) {
    return {
      ...body,
      molecule: {
        name: 'Untitled',
        data: '',
      },
    };
  }
  return body;
};

/**
 * Return option by type
 */
export const getOptionByType = (type: string): IMETADATA_TYPES => {
  const metadataTypeMatch = METADATA_TYPES.find(
    (metadataType) => metadataType.value === type,
  );
  if (metadataTypeMatch) {
    return {
      label: metadataTypeMatch.label,
      value: metadataTypeMatch.value,
    };
  }

  const metadataTypeDefault = {
    label: METADATA_TYPES[0].label,
    value: METADATA_TYPES[0].value,
  };

  return metadataTypeDefault;
};

/**
 * Sets default values of form based on existing values
 * Formats metadata options and type
 */
export const loadDefaultValues = (
  values: any,
  entity: Metadata,
): any => {
  const newValues = cloneDeep(values);
  if (Object.keys(values).includes('options_values')) {
    newValues.options_values = entity.options
      ? Object.keys(entity.options.values)
      : [];
    newValues.options_selected_values = entity.optionsSelectedValues;
  }
  if (Object.keys(values).includes('options_is_allow_multiple')) {
    newValues.options_is_allow_multiple = entity.options
      ? entity.options.is_allow_multiple
      : false;
  }
  if (Object.keys(values).includes('options_is_allow_add')) {
    newValues.options_is_allow_add = entity.options
      ? entity.options.is_allow_add
      : false;
  }

  if (Object.keys(values).includes('type')) {
    newValues.type = entity.type
      ? getOptionByType(entity.type)
      : false;
  }

  return newValues;
};

/**
 * Formats option values for body
 */
export const mapArrayOfStringToOptionValues = (
  values: string[],
  keys: string[],
) =>
  keys.reduce(
    (result, key) => ({
      ...result,
      [key]: values.includes(key),
    }),
    {},
  );

/**
 * Get fields by type for reset or delete.
 * The tricky bit is that 'date' field is used by both 'date' and 'datetime'.
 * That's why we have the filter.
 */
export const getFieldsByType = (
  type: string,
  key: 'fields' | 'fieldsToReset' | 'fieldsToDelete',
) => {
  let fields: string[] = [];
  METADATA_TYPES.forEach((metadataType) => {
    const metadataTypeFields =
      METADATA_FIELDS[metadataType.value][key];
    if (metadataType.value !== type && metadataTypeFields) {
      fields = fields.concat([...metadataTypeFields]);
    }
  });
  METADATA_TYPES.forEach((metadataType) => {
    const metadataTypeFields =
      METADATA_FIELDS[metadataType.value][key];
    if (metadataType.value === type && metadataTypeFields) {
      fields = fields.filter(
        (field) => !!metadataTypeFields.indexOf(field),
      );
    }
  });

  return uniq(fields);
};
