/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Create
 * @desc Create Action for resource item
 */

import { getFieldsResourceItemCreateForm } from 'labstep-web/components/ResourceItem/Form/fields';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import FormCreate from 'labstep-web/core/Form/Create';
import { IFormCreateContainerProps } from 'labstep-web/core/Form/Create/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { IResourceItemFormCreateProps } from './types';

export const ResourceItemFormCreate: React.FC<
  IResourceItemFormCreateProps
> = ({
  resource,
  options,
  activeGroup,
  resourceTemplateId,
  protocolValueOrigin,
}) => {
  const defaultValues: IFormCreateContainerProps['defaultValues'] =
    {};
  if (resource) {
    defaultValues.resource = resource;
  }
  const body: IFormCreateContainerProps['body'] = {};
  if (protocolValueOrigin) {
    body.protocol_value_origin_guid = protocolValueOrigin.guid;
    body.amount = protocolValueOrigin.amount;
    body.unit = protocolValueOrigin.unit;
  }
  return (
    <FormCreate
      entityName={ResourceItem.entityName}
      autoFocus={!resource}
      defaultValues={defaultValues}
      options={options}
      fields={getFieldsResourceItemCreateForm({
        groupId: activeGroup?.id,
        resourceTemplateId,
      })}
      body={body}
    />
  );
};

export default withActiveGroup(ResourceItemFormCreate);
