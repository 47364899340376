/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/Preview
 * @desc EntityImport Create Step 4: Preview
 */

import React, { useState } from 'react';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import EntityImportDataGrid from 'labstep-web/components/EntityImport/DataGrid';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import {
  DataGridContextProvider,
  useAgGridEntityImportServiceFromContext,
} from 'labstep-web/core/DataGrid/context';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import { useModalWizardContext } from 'labstep-web/core/Modal/Wizard/context';
import styles from './styles.module.scss';
import { IEntityImportActionCreateStepsPreviewProps } from './types';

const getErrorMessage = (count: number, type: 'row' | 'column') =>
  `${count} invalid ${type}${count > 1 ? 's' : ''}`;

export const EntityImportActionCreateStepsPreview: React.FC<
  IEntityImportActionCreateStepsPreviewProps
> = ({
  entityName,
  index,
  template,
  columnDefs,
  rowData,
  onContinue,
  create,
}) => {
  const [showOnlyInvalidRows, setShowOnlyInvalidRows] =
    useState(false);

  const [showModalConfirmation, setShowModalConfirmation] =
    useState(false);

  const { goBack } = useModalWizardContext();

  const agGridEntityImportService =
    useAgGridEntityImportServiceFromContext();

  const { activeGroup } = useActiveGroup();

  return (
    <>
      <ModalConfirmation
        open={!!showModalConfirmation}
        header="Warning"
        message="Are you sure you want to go back? All changes will be lost."
        onConfirm={() => {
          setShowModalConfirmation(false);
          goBack();
        }}
        onCancel={() => setShowModalConfirmation(false)}
        onClose={() => setShowModalConfirmation(false)}
      />
      <ModalWizard.Step
        index={index}
        description="Here you can add and modify entries based on the field structure you have defined."
        onContinue={({ setErrorMessages, goForward }) => {
          if (!agGridEntityImportService || !activeGroup) {
            return;
          }
          const invalidRowCount =
            agGridEntityImportService.getInvalidRowCount();
          if (invalidRowCount > 0) {
            setErrorMessages([
              getErrorMessage(invalidRowCount, 'row'),
            ]);
          } else {
            agGridEntityImportService.saveData(
              activeGroup.id,
              entityName,
              create,
              template,
              {
                onSuccess: () => {
                  goForward();
                  onContinue();
                },
                onFail: () => {
                  setErrorMessages([
                    'Something went wrong, please try again later',
                  ]);
                },
              },
            );
          }
        }}
        onBack={() => setShowModalConfirmation(true)}
        actions={
          <ActionComponent
            type="checkbox"
            elementProps={{
              label: 'Show only invalid rows',
              checked: showOnlyInvalidRows,
            }}
            onClick={(): void => {
              setShowOnlyInvalidRows(!showOnlyInvalidRows);
            }}
          />
        }
      >
        <div className={styles.container}>
          <EntityImportDataGrid
            template={template}
            targetEntityName={entityName}
            rowData={rowData}
            columnDefs={columnDefs}
            showOnlyInvalidRows={showOnlyInvalidRows}
          />
        </div>
      </ModalWizard.Step>
    </>
  );
};

export const EntityImportActionCreateStepsPreviewContainer: React.FC<
  IEntityImportActionCreateStepsPreviewProps
> = (props) => (
  <DataGridContextProvider>
    <EntityImportActionCreateStepsPreview {...props} />
  </DataGridContextProvider>
);

export default EntityImportActionCreateStepsPreviewContainer;
