/**
 * Labstep
 *
 * @module models/jupyter-instance
 * @desc Typescript export class for JupyterInstance
 */

import { Entity } from 'labstep-web/models/entity.model';
import { configService } from 'labstep-web/services/config.service';

// eslint-disable-next-line no-shadow
export enum JupyterInstanceType {
  edit = 'edit',
  run = 'run',
}

// eslint-disable-next-line no-shadow
export enum JupyterInstanceStatus {
  success = 'success',
  error = 'error',
}

export class JupyterInstance extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'jupyter_instance';

  get entityName(): string {
    return JupyterInstance.entityName;
  }

  type: JupyterInstanceType;

  status: JupyterInstanceStatus;

  started_at: string;

  ended_at: string;

  constructor(data: Partial<JupyterInstance> = {}) {
    super();
    Object.assign(this, data);
  }

  getLink(userGuid: string): string {
    return `${configService.jupyterUrl}/user/${userGuid}/${this.guid}/lab/workspaces/auto-r/tree/notebook.ipynb`;
  }
}
