/**
 * Labstep
 *
 * @module state/reducers/jupyter
 * @desc Reducers for jupyter
 */

import { Action } from 'labstep-web/models/action.model';
import { getEntityReducers } from './entity';

/**
 * Status when calling jupyter oauth authorize endpoint
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const authorize = (state: any = {}, action: Action) => {
  switch (action.type) {
    case 'JUPYTER_OAUTH_AUTHORIZE_REQUEST':
      return {
        isFetching: true,
        error: null,
      };
    case 'JUPYTER_OAUTH_AUTHORIZE_SUCCESS':
      return {
        isFetching: false,
        error: null,
      };
    case 'JUPYTER_OAUTH_AUTHORIZE_FAIL':
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

/**
 * Status when calling jupyter get link endpoint
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const getLink = (state: any = {}, action: Action) => {
  switch (action.type) {
    case 'JUPYTER_GET_LINK_REQUEST':
      return {
        isFetching: true,
        error: null,
      };
    case 'JUPYTER_GET_LINK_SUCCESS':
      return {
        isFetching: false,
        error: null,
      };
    case 'JUPYTER_GET_LINK_FAIL':
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default getEntityReducers('jupyter', {
  authorize,
  get_link: getLink,
});
