/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/File
 * @desc Renders a File node
 */

import FileCollapsibleCard from 'labstep-web/components/File/CollapsibleCard';
import FileImage from 'labstep-web/components/File/Image';
import { File as FileModel } from 'labstep-web/models/file.model';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import { expandedPluginKey } from 'labstep-web/prosemirror/extensions/expanded/plugin';
import React from 'react';
import styles from './styles.module.scss';
import { IFileNodeProps } from './types';

export class FileNode extends React.Component<IFileNodeProps> {
  shouldComponentUpdate() {
    return Boolean(!this);
  }

  render() {
    const { id, timestamp, fullWidth, initialExpandedState } =
      this.props;

    return (
      <Read
        entityName={FileModel.entityName}
        timestamp={timestamp}
        id={id}
      >
        {({ entity: file }) => {
          if (
            file &&
            file.mime_type.startsWith('image') &&
            !file.isLinked
          ) {
            return <FileImage file={file} expandable={false} />;
          }
          return fullWidth ? (
            <FileCollapsibleCard
              file={file}
              initialExpandedState={initialExpandedState}
            />
          ) : (
            <ViewInline
              entity={file}
              icon="file"
              className={styles.container}
            >
              {file.name}
            </ViewInline>
          );
        }}
      </Read>
    );
  }
}

const FileNodeView = ({ timestamp }) => {
  const context = useReactNodeView();
  const { id, fullWidth } = context.node.attrs;
  const { state } = context.view;
  const initialExpandedState =
    state.plugins
      .find((p) => p.spec.key === expandedPluginKey)
      .getState(state).file === id;

  return (
    <FileNode
      id={id}
      timestamp={timestamp}
      fullWidth={fullWidth}
      initialExpandedState={initialExpandedState}
    />
  );
};

export default FileNodeView;
