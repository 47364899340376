/**
 * Labstep
 */

import MetadataFormShowEditValueWithClear from 'labstep-web/components/Metadata/Form/ShowEdit/Value/WithClear';
import ProtocolConditionActionSetIsVariable from 'labstep-web/components/ProtocolCondition/Action/SetIsVariable';
import { ICONS } from 'labstep-web/constants/icons';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { Experiment } from 'labstep-web/models/experiment.model';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { RowsType } from '../../types';

export const getRows = (entity: Protocol | Experiment): RowsType => [
  {
    header: (
      <TextWithHelp text="Value" icon={ICONS.entity.info.id} header />
    ),
    content: (metadata): React.ReactElement => (
      <MetadataFormShowEditValueWithClear
        entity={entity}
        metadata={metadata}
        componentProps={{
          [MetadataType.numeric]: { type: 'both' },
        }}
      />
    ),
    action: (metadata): React.ReactElement =>
      entity instanceof Experiment && (
        <ProtocolConditionActionSetIsVariable
          entity={metadata}
          protocol={entity}
        />
      ),
    dataTestId: 'metadata-value',
  },
];
