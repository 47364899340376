/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/FreeTrial
 * @desc Organization plan card for free trial
 */

import React from 'react';
import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import SubHeader from 'labstep-web/core/SubHeader';
import OrganizationPlanActionUpgrade from '../../Action/Upgrade';
import styles from '../styles.module.scss';
import { OrganizationPlanCardFreeTrialProps } from './types';

export const OrganizationPlanCardFreeTrial: React.FC<
  OrganizationPlanCardFreeTrialProps
> = ({ organization }) => (
  <OrganizationPlanCard
    icon="file alternate"
    header="Plan"
    secondary={
      <div>
        <Flex vAlignContent="center">
          <Header noMargin>Free Trial</Header>
          <SubHeader
            className={styles.red}
            style={{ marginLeft: 10 }}
          >
            {organization.printDaysRemaining}
          </SubHeader>
        </Flex>
        <OrganizationPlanActionUpgrade organization={organization} />
      </div>
    }
  />
);

export default OrganizationPlanCardFreeTrial;
