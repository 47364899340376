/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';
import { SET_ALL_NOTIFICATIONS_AS_VIEWED } from 'labstep-web/state/constants/notification';
import { readEntitiesCount } from 'labstep-web/state/actions/entity';

/**
 * Sets all notifications as viewed
 * @param  {object} options - Additional options
 * @return {object}         - Action
 */
export const setAllNotificationAsViewed = () =>
  sfApi.post({
    type: SET_ALL_NOTIFICATIONS_AS_VIEWED,
    route: {
      custom: 'app_notification_postnotificationsetallviewedat',
    },
    meta: { action_type: 'set_all_notifications_as_viewed' },
    body: {},
  });

/**
 * Read Notifications count
 */
export const readNotificationsCount = () =>
  readEntitiesCount('notification', { is_viewed: false });
