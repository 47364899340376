/**
 * Labstep
 *
 * @module components/User/Card/Preview
 * @desc User Preview Card
 */

import React from 'react';
import Label from 'labstep-web/core/Label';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import UserShowProfile from 'labstep-web/components/User/Show/Profile';
import GenericCard from 'labstep-web/core/Card/Generic';
import { IGroupBadegProps, IUserCardPreviewProps } from './types';

export const GroupBadge: React.FC<IGroupBadegProps> = ({
  userGroup,
}) => {
  if (userGroup.type === 'owner') {
    return (
      <Label color="black" ribbon>
        OWNER
      </Label>
    );
  }
  return null;
};

const UserCardPreview: React.FC<IUserCardPreviewProps> = ({
  userGroup,
  actionMenu,
}) => (
  <GenericCard
    headerSuper={<GroupBadge userGroup={userGroup} />}
    headerImage={
      <ProfilePicture centered entity={userGroup.user} linkable />
    }
    headerTitle={
      <UserShowProfile
        user={userGroup.user}
        viewComponent={userGroup.user.name}
      />
    }
    actionMenu={actionMenu}
  />
);

export default UserCardPreview;
