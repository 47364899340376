/**
 * Labstep
 *
 * @module core/DataGrid
 * @desc Wrapper around AG Grid
 */

import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useToggle } from 'labstep-web/hooks/toggle';
import { configService } from 'labstep-web/services/config.service';
import Loader from 'labstep-web/core/Loader';
import DataGridCellRenderer from './CellRenderer';
import DataGridCellEditor from './CellEditor';
import ColumnHeader from './ColumnHeader';
import { useDataGridContext } from './context';
import { useUpdateContextOnColumnChange } from './hooks';
import DataGridOverlayLoading from './Overlay/Loading';
import { IDataGridProps } from './types';
import { setColumnDefsField } from './utils';
import './styles.scss';

export const DataGrid: React.FC<IDataGridProps> & {
  CellRenderer: typeof DataGridCellRenderer;
  CellEditor: typeof DataGridCellEditor;
} = ({
  status,
  loading,
  defaultColDef: customDefaultColDef,
  onGridReady: customOnGridReady,
  components: customComponents,
  columnDefs: customColumnDefs,
  editable = true,
  height,
  context: customContext,
  ...rest
}) => {
  const [isLicenceKeySet, toggleIsLicenseKeySet] = useToggle();
  useEffect(() => {
    LicenseManager.setLicenseKey(configService.agGridLicenseKey);
    toggleIsLicenseKeySet();
  }, []);

  const ref = useRef<AgGridReact>();
  /** Allows accessing ref through context provider */
  const { ref: contextRef } = useDataGridContext();

  /** Add listener props to update context */
  const contextProps = useUpdateContextOnColumnChange();

  const context = useMemo(
    () => ({
      ...customContext,
      editable,
    }),
    [customContext],
  );

  // needs to be updated through the API
  // to prevent redundant rerendering
  const columnDefs = useMemo(
    () =>
      !editable && customColumnDefs
        ? setColumnDefsField(customColumnDefs, 'editable', false)
        : customColumnDefs,
    [editable],
  );

  const components: IDataGridProps['components'] = useMemo(
    (): IDataGridProps['components'] => ({
      agColumnHeader: ColumnHeader,
      agColumnGroupHeader: ColumnHeader,
      ...customComponents,
    }),
    [],
  );

  const defaultColDef: IDataGridProps['defaultColDef'] = useMemo(
    (): IDataGridProps['defaultColDef'] => ({
      resizable: true,
      minWidth: 50,
      ...customDefaultColDef,
    }),
    [],
  );

  // will be removed on rerender due to status prop change
  // the ref api is not available yet on initialising grid
  // so useEffect doesn't work
  const onGridReady = useCallback<
    NonNullable<IDataGridProps['onGridReady']>
  >(
    (params) => {
      if (status?.isFetching) {
        params.api.showLoadingOverlay();
      }
      customOnGridReady?.(params);
    },
    [status],
  );

  return (
    <>
      <div style={{ height }} className="ag-theme-alpine">
        {isLicenceKeySet && (
          <AgGridReact
            ref={(node: AgGridReact): void => {
              ref.current = node;
              if (contextRef) {
                contextRef.current = node;
              }
            }}
            columnDefs={columnDefs}
            components={components}
            defaultColDef={defaultColDef}
            domLayout={height ? 'normal' : 'autoHeight'}
            enableFillHandle
            enableRangeSelection
            enterMovesDownAfterEdit
            onGridReady={onGridReady}
            overlayNoRowsTemplate="<div />"
            loadingOverlayComponent={DataGridOverlayLoading}
            rowSelection="multiple"
            undoRedoCellEditing
            context={context}
            {...rest}
            {...contextProps}
          />
        )}
      </div>
      {ref.current?.api && (status?.isFetching || loading) && (
        <div style={{ marginTop: '10px' }}>
          <Loader size="tiny" active inline /> Loading
        </div>
      )}
    </>
  );
};

DataGrid.CellRenderer = DataGridCellRenderer;
DataGrid.CellEditor = DataGridCellEditor;

export default DataGrid;
