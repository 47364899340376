import {
  PERMISSION_ROLE_EDITOR,
  PERMISSION_ROLE_OWNER,
  PERMISSION_ROLE_VIEWER,
} from 'labstep-web/constants/roles';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import {
  USER_GROUP_TYPE_EDIT,
  USER_GROUP_TYPE_OWNER,
  USER_GROUP_TYPE_VIEW,
} from 'labstep-web/models/user-group.model';

export const getCreateUserGroupParams = (
  activePermissionRole: Partial<PermissionRole>,
) => {
  let type = USER_GROUP_TYPE_EDIT;
  let permission_role_id: string | null =
    activePermissionRole.guid as string;
  if (activePermissionRole.name === PERMISSION_ROLE_OWNER.name) {
    type = USER_GROUP_TYPE_OWNER;
    permission_role_id = null;
  } else if (
    activePermissionRole.name === PERMISSION_ROLE_EDITOR.name
  ) {
    type = USER_GROUP_TYPE_EDIT;
    permission_role_id = null;
  } else if (
    activePermissionRole.name === PERMISSION_ROLE_VIEWER.name
  ) {
    type = USER_GROUP_TYPE_VIEW;
    permission_role_id = null;
  }
  return {
    type,
    permission_role_id,
  };
};
