/**
 * Labstep
 *
 * @module components/OutboundPurchaseOrder/Form/ShowEdit/Handling
 * @desc ShowEdit form for order request currency and handling
 */

import React from 'react';
import ShowEditAmount from 'labstep-web/core/Form/ShowEdit/Amount';
import { fieldOutboundPurchaseOrderHandlingAmount } from 'labstep-web/components/OutboundPurchaseOrder/Form/fields';
import { IOutboundPurchaseOrderFormShowEditHandlingProps } from './types';

export const OutboundPurchaseOrderFormShowEditHandling: React.FC<
  IOutboundPurchaseOrderFormShowEditHandlingProps
> = ({ outboundPurchaseOrder }) => (
  <ShowEditAmount
    entity={outboundPurchaseOrder}
    entityName={outboundPurchaseOrder.entityName}
    field={fieldOutboundPurchaseOrderHandlingAmount}
    currency={outboundPurchaseOrder.currency}
  >
    {outboundPurchaseOrder.printHandling}
  </ShowEditAmount>
);

export default OutboundPurchaseOrderFormShowEditHandling;
