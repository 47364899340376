/**
 * Labstep
 *
 * @module components/ShareLink/Info
 * @desc Copy a ShareLink
 */

import { useHasAccessGroup } from 'labstep-web/components/Entity/Can';
import GroupActionSetDefaultUserGroupType from 'labstep-web/components/Group/Action/SetDefaultUserGroupType';
import SharelinkActionEmail from 'labstep-web/components/ShareLink/Action/Email';
import ShareLinkActionReCreate from 'labstep-web/components/ShareLink/Action/ReCreate';
import ToggleShareLinkPermissions from 'labstep-web/components/ShareLink/Action/TogglePermissions';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ActionCopy from 'labstep-web/core/Action/Copy';
import Input from 'labstep-web/core/Input';
import { Device } from 'labstep-web/models/device.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Folder } from 'labstep-web/models/folder.model';
import { Group } from 'labstep-web/models/group.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { Resource } from 'labstep-web/models/resource.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { navigation } from 'labstep-web/services/navigation';
import { PermissionActions } from 'labstep-web/typings';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { IShareLinkInfoProps } from './types';

export const canEdit = (
  parentEntity:
    | Group
    | Device
    | ExperimentWorkflow
    | Folder
    | OrderRequest
    | ProtocolCollection
    | Resource
    | ResourceLocation,
) => {
  if (parentEntity.entityName === Group.entityName) {
    return useHasAccessGroup('owner', parentEntity.id);
  }

  return (
    parentEntity.allowed_actions.includes('*') ||
    parentEntity.allowed_actions.includes(
      `${parentEntity.entityName}:edit` as PermissionActions,
    )
  );
};

export const ShareLinkInfo: React.FC<IShareLinkInfoProps> = ({
  shareLink,
  parentEntity,
  header,
}) => {
  const [activeShareLink, setActiveShareLink] = useState(shareLink);
  const link =
    activeShareLink &&
    navigation.get(
      'sharelink_show',
      { token: activeShareLink.token },
      true,
    );

  return (
    <>
      <span>{header || 'Share via a link'}</span>
      <div className={styles.container}>
        <div className={styles.input}>
          <Input fluid value={link} />
        </div>
        <div>
          <ShareLinkActionReCreate
            parentEntity={parentEntity}
            options={{
              onSuccess: ({ response }) => {
                setActiveShareLink(
                  response.entities.share_link[response.result],
                );
              },
            }}
          />
        </div>
        <ActionCopy
          text={link}
          actionComponentProps={{
            type: 'button',
            elementProps: { primary: true },
            text: 'Copy',
          }}
        />

        <EntityReadEntityContainer
          entityName={ShareLink.entityName}
          id={activeShareLink.id}
        >
          {({ entity }) => (
            <SharelinkActionEmail shareLink={entity} />
          )}
        </EntityReadEntityContainer>

        {canEdit(parentEntity) && (
          <div>
            {parentEntity.entityName === Group.entityName ? (
              <GroupActionSetDefaultUserGroupType
                group={parentEntity as Group}
              />
            ) : (
              <ToggleShareLinkPermissions
                shareLinkId={activeShareLink.id}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ShareLinkInfo;
