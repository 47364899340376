/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';
import { Chemical } from 'labstep-web/models/chemical.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { File } from 'labstep-web/models/file.model';
import { Group } from 'labstep-web/models/group.model';
import { JupyterInstance } from 'labstep-web/models/jupyter-instance.model';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Organization } from 'labstep-web/models/organization.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { Resource } from 'labstep-web/models/resource.model';
import { UserGroup } from 'labstep-web/models/user-group.model';
import {
  addMetadataOptionToTemplate,
  getStateWithUpdatedLockedAt,
  mergeByIds,
  removeChild,
  removeOneToOne,
} from 'labstep-web/state/reducers/helpers';
import {
  EntityNamesById,
  LabstepReduxState,
} from 'labstep-web/state/types';
import { v4 } from 'uuid';
import {
  createChemical,
  createCustomerWithOrganizationAndStripeCustomerId,
  createFileWithAnnotation,
  createOrUpdateResourceItemWithExperimentValueOrigin,
  createOrderRequestTemplate,
  createProtocolWithExperimentAndAddToLibrary,
  createResourceItemTemplate,
  createTemplatePurchaseOrder,
  deleteExperiment,
  deleteMetadata,
  deleteProtocolValue,
  isOptionsWithTemplateUpdate,
  recalcStoichiometryTable,
  updateExperimentWorkflowAndLock,
  updateOrderRequest,
  updateUserGroupWithHome,
} from '../action-definitions';

export const updateStoichiometryTable = <T extends EntityNamesById>(
  state: any,
  action: Action,
  entityName: T,
): any => {
  const entities = action.payload[entityName];
  const newState = {};
  Object.keys(entities).forEach((id: string) => {
    newState[id] = { ...state[id], ...entities[id] };
  });
  return {
    ...state,
    ...newState,
  };
};

export const updateEntityById = <T extends EntityNamesById>(
  state: any,
  action: Action,
  entityName: T,
): any => {
  switch (entityName) {
    case Experiment.entityName: {
      if (createProtocolWithExperimentAndAddToLibrary(action)) {
        return {
          ...state,
          [action.meta.body.experiment_id]: {
            ...state[action.meta.body.experiment_id],
            protocol: action.payload.result,
          },
        };
      }

      if (updateExperimentWorkflowAndLock(action)) {
        return getStateWithUpdatedLockedAt(state, action);
      }

      if (deleteProtocolValue(action)) {
        return removeChild(
          state,
          action.payload.result,
          'protocol_values',
        );
      }

      break;
    }

    case ExperimentWorkflow.entityName: {
      if (deleteExperiment(action)) {
        return removeChild(
          state,
          action.payload.result,
          'experiments',
        );
      }

      break;
    }

    case ProtocolValue.entityName: {
      if (recalcStoichiometryTable(action)) {
        return updateStoichiometryTable(state, action, entityName);
      }
      if (createChemical(action)) {
        const { protocol_value_guid, items } = action.meta.body;
        const guids = items
          ? items.map((item) => item.protocol_value_guid)
          : [protocol_value_guid];
        const result = {};
        guids.forEach((guid) => {
          result[guid] = {
            ...state[guid],
            ...action.payload.entities.protocol_value[guid],
          };
        });
        return {
          ...state,
          ...result,
        };
      }
      if (
        createOrUpdateResourceItemWithExperimentValueOrigin(action)
      ) {
        const { result, entities } = action.payload;
        if (!action.meta.body.protocol_value_origin_guid) {
          return removeOneToOne(
            state,
            action,
            'resource_item_output',
          );
        }
        const newState = {
          ...state,
          [action.meta.body.protocol_value_origin_guid]: {
            ...state[action.meta.body.protocol_value_origin_guid],
            resource_item_output: result,
          },
        };
        return mergeByIds(newState, entities[entityName], entityName);
      }
      break;
    }

    case File.entityName: {
      if (createFileWithAnnotation(action)) {
        return {
          ...mergeByIds(
            state,
            action.payload.entities[entityName],
            entityName,
          ),
          [action.meta.annotated_file_id]: {
            ...state[action.meta.annotated_file_id],
            image_annotation: action.payload.result[0],
          },
        };
      }
      break;
    }

    case Metadata.entityName: {
      if (isOptionsWithTemplateUpdate(action)) {
        addMetadataOptionToTemplate(state, action);
      }
      break;
    }

    case MetadataThread.entityName: {
      if (deleteMetadata(action)) {
        return removeChild(state, action.payload.result, 'metadatas');
      }
      break;
    }

    case Organization.entityName: {
      if (createCustomerWithOrganizationAndStripeCustomerId(action)) {
        return {
          ...state,
          [action.meta.organization.id]: {
            ...state[action.meta.organization.id],
            stripe_customer_id: action.payload.stripe_customer_id,
          },
        };
      }
      break;
    }

    case PurchaseOrder.entityName: {
      if (updateOrderRequest(action)) {
        const { body } = action.meta;
        if (body.purchase_order_id) {
          const purchaseOrderId = body.purchase_order_id;
          // cannot work out how typescript can infer this
          const orderRequests = (
            state as LabstepReduxState['entities']['purchase_order']['byId']
          )[purchaseOrderId].order_requests;
          if (orderRequests) {
            return {
              ...state,
              [purchaseOrderId]: {
                ...state[purchaseOrderId],
                order_requests: [
                  ...orderRequests,
                  action.payload.result,
                ],
              },
            };
          }
        }
      }
      break;
    }

    case Resource.entityName: {
      if (createResourceItemTemplate(action)) {
        const resourceItemTemplateId = action.payload.result;
        const resourceId = action.meta.body.resource_id;
        return {
          ...state,
          [resourceId]: {
            ...state[resourceId],
            resource_item_template: resourceItemTemplateId,
          },
        };
      }
      if (createOrderRequestTemplate(action)) {
        const orderRequestTemplateId = action.payload.result;
        const resourceId = action.meta.body.template_resource_id;
        return {
          ...state,
          [resourceId]: {
            ...state[resourceId],
            order_request_template: orderRequestTemplateId,
            order_request_template_id: orderRequestTemplateId,
          },
        };
      }
      break;
    }

    case Chemical.entityName: {
      if (recalcStoichiometryTable(action)) {
        return updateStoichiometryTable(state, action, entityName);
      }
      break;
    }

    case UserGroup.entityName: {
      if (updateUserGroupWithHome(action)) {
        const existingUserGroupState =
          state[action.meta.identifier] || {};
        return Object.keys(state).reduce(
          (result, key) => {
            if (Number(key) === Number(action.meta.identifier)) {
              return result;
            }
            return {
              ...result,
              [key]: {
                ...state[key],
                is_home: false,
              },
            };
          },
          {
            [action.meta.identifier]: {
              ...existingUserGroupState,
              ...action.payload.entities.user_group[
                action.meta.identifier
              ],
            },
          },
        );
      }
      break;
    }

    case Group.entityName: {
      if (createTemplatePurchaseOrder(action)) {
        const groupId = action.meta.body.group_id;
        return {
          ...state,
          [groupId]: {
            ...state[groupId],
            purchase_order_template_id: action.payload.result,
          },
        };
      }
      break;
    }

    case JupyterInstance.entityName: {
      if (action.type === 'JUPYTER_GET_LINK_SUCCESS') {
        return {
          ...state,
          [action.payload.guid]: {
            ...action.payload,
          },
        };
      }
      if (action.type === 'JUPYTER_RUN_SUCCESS') {
        return {
          ...state,
          [action.payload.guid]: {
            ...action.payload,
          },
        };
      }
      break;
    }

    case JupyterNotebook.entityName: {
      if (action.type === 'JUPYTER_GET_LINK_SUCCESS') {
        return {
          ...state,
          [action.meta.guid]: {
            ...state[action.meta.guid],
            jupyter_instance_edit: action.payload.guid,
          },
        };
      }
      if (action.type === 'JUPYTER_RUN_REQUEST') {
        const uuid = v4();
        return {
          ...state,
          [action.meta.guid]: {
            ...state[action.meta.guid],
            jupyter_instance_run: {
              guid: uuid,
              type: 'run',
            },
          },
        };
      }
      if (action.type === 'JUPYTER_RUN_SUCCESS') {
        return {
          ...state,
          [action.meta.guid]: {
            ...state[action.meta.guid],
            jupyter_instance_run: action.payload.guid,
          },
        };
      }
      break;
    }
    default:
      break;
  }

  if (action.type === 'SUCCESS_UPDATE_FILE') {
    if (
      entityName === 'metadata' &&
      action.meta.body.metadata_id === null
    ) {
      return Object.keys(state).reduce(
        (result, key) => ({
          ...result,
          [key]: {
            ...state[key],
            // see comment above
            files: (
              state as LabstepReduxState['entities']['metadata']['byId']
            )[key].files.filter(
              (fileId) => fileId !== action.meta.params.id,
            ),
          },
        }),
        {},
      );
    }
  }

  return null;
};

export const deduplicateById = (
  array: { id: number; [key: string]: any }[],
) => {
  const map = new Map();

  // Iterate over the array and add each object to the map using its id as the key.
  // This automatically removes any duplicates as a map can only have unique keys.
  array.forEach((item) => map.set(item.id, item));

  // Convert the map values back to an array and return it.
  return Array.from(map.values());
};

/**
 *
 * @description Adds newly created user_entity to entity_users_preview because
 * the backend can't handle the recursion and doesn't update entity_users_preview
 * correctly
 */
export const previewArrayUpdate = (
  state: any,
  action: Action,
): any => {
  const entityUser =
    action.payload.entities.entity_user[action.payload.result];

  const entity =
    action.payload.entities[action.meta.parentName][
      action.meta.parentId
    ];

  const newEntities = {
    ...action.payload.entities[action.meta.parentName],
    [action.meta.parentId]: {
      ...entity,
      entity_users_preview: deduplicateById([
        ...entity.entity_users_preview,
        entityUser,
      ]),
    },
  };

  return mergeByIds(state, newEntities, action.meta.parentName);
};
