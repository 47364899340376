/**
 * Labstep.
 *
 * @module services/stripe
 * @desc Wrapper around Stripe JS SDK
 * @see https://github.com/stripe/stripe-js/issues/43
 */

import { Stripe, loadStripe } from '@stripe/stripe-js';
import {
  PAYMENT_TYPE_BACS,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_SEPA,
} from 'labstep-web/models/organization.model';
import { configService } from './config.service';
import { navigation } from './navigation';

export class StripeService {
  public static async loadStripeClient(): Promise<Stripe | null> {
    if (configService.stripeApiKey) {
      return loadStripe(configService.stripeApiKey);
    }
    return null;
  }

  public static async redirectToCheckout(sessionId: string) {
    if (configService.stripeApiKey) {
      const stripe = await StripeService.loadStripeClient();
      if (stripe) {
        stripe.redirectToCheckout({
          sessionId,
        });
      }
    }
  }

  public static getStripePortal = (
    organization,
    priceId,
    currency,
    tier,
    paymentMethodType,
    setClientSecret,
    getOrganizationSubscriptionPortal,
    setupIntentSepaDebitAction,
  ) => {
    const url = organization
      ? navigation.get(
          'organization_show_billing',
          { identifier: organization.identifier },
          true,
        )
      : navigation.get('root', {}, true);

    if (
      paymentMethodType === PAYMENT_TYPE_CARD ||
      paymentMethodType === PAYMENT_TYPE_BACS
    ) {
      getOrganizationSubscriptionPortal(
        {
          price_id: priceId,
          currency,
          payment_method_type: paymentMethodType,
          success_url: url,
          cancel_url: url,
          tier,
        },
        {
          toast: true,
        },
      );
    } else if (paymentMethodType === PAYMENT_TYPE_SEPA) {
      setupIntentSepaDebitAction(
        organization.id,
        {
          price_id: priceId,
          currency,
          tier,
        },
        {
          toast: true,
          onSuccess: async (result) => {
            setClientSecret(result.response.client_secret);
          },
        },
      );
    }
  };
}
