/**
 * Labstep
 *
 * @module core/Timeline
 * @desc Wrapper around FullCalendar with Timeline plugin
 */

import React, { forwardRef } from 'react';
import FullCalendar from 'labstep-web/core/FullCalendar';
import { IPluginName } from 'labstep-web/core/FullCalendar/types';
import { ITimelineProps } from './types';

export const Timeline: React.FC<ITimelineProps> = forwardRef(
  (props, ref) => (
    <FullCalendar
      ref={ref}
      pluginName={IPluginName.timeline}
      initialView="resourceTimelineMonth"
      displayEventTime={false}
      dayHeaders={false}
      slotLabelFormat={{
        day: 'numeric',
      }}
      buttonText={{ today: 'Today' }}
      editable
      selectable
      nowIndicator
      eventDragMinDistance={5}
      stickyHeaderDates
      scrollTime={null}
      {...props}
    />
  ),
);

export default Timeline;
