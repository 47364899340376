/**
 * Labstep
 *
 * @module screens/Resource/Index
 * @desc Resource Index
 */

import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import { IEntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import MetadataDataGridColumnOptions from 'labstep-web/components/Metadata/DataGrid/ColumnOptions';
import { ResourceEmptyState } from 'labstep-web/components/Resource/Card/EmptyState';
import ResourceListOrTable from 'labstep-web/components/Resource/ListOrTable';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import { Resource } from 'labstep-web/models/resource.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import columnDefs from 'labstep-web/screens/Resource/Index/colDefs';
import { selectEntity } from 'labstep-web/state/selectors/entity';
import store from 'labstep-web/state/store';
import React, { useCallback, useMemo } from 'react';
import ResourceIndexBreadcrumb from './Breadcrumb';
import ResourceIndexLeftPane from './LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import {
  IResourceIndexListOrTableProps,
  IResourceIndexProps,
} from './types';

export const ResourceIndexListOrTable: React.FC<
  IResourceIndexListOrTableProps
> = ({ entities, ...rest }) => {
  const { searchParams, setParams } = useParamsContext();
  return (
    <ResourceListOrTable
      resources={entities}
      templateId={searchParams.template_id}
      columnDefs={(template) => columnDefs(setParams, template)}
      toolPanelParams={(template) =>
        template
          ? {
              extraColumnOptions: (
                <MetadataDataGridColumnOptions entity={template} />
              ),
            }
          : undefined
      }
      {...rest}
    />
  );
};

export const ResourceIndex: React.FC<IResourceIndexProps> = ({
  group,
  links,
}) => {
  const breadcrumb = useMemo(() => <ResourceIndexBreadcrumb />, []);
  const leftPane = useMemo(
    () => <ResourceIndexLeftPane links={links} />,
    [links],
  );
  const params = useMemo(
    () => ({ group_id: group.id, skip_total: 1, is_template: 0 }),
    [group.id],
  );
  const noResultsMessage = useCallback(
    ({ searchParams }) => (
      <ResourceEmptyState
        resourceTemplateId={searchParams.template_id}
      />
    ),
    [],
  );
  const action = useCallback(
    ({ searchParams }) => (
      <EntityActionCreateMenu
        entityName={Resource.entityName}
        extraOptions={
          <EntityImportModal
            targetEntityName={Resource.entityName}
            templateId={searchParams.template_id}
          />
        }
        templateId={searchParams?.template_id}
      />
    ),
    [],
  );

  const entityView = (searchParams: Record<string, unknown>) => {
    const id = searchParams.template_id as number;
    // TODO: this is a hack to get the guid of the template
    // Will remove once we migrate to guid
    const guid = id
      ? selectEntity(store.getState(), Resource.entityName, id)?.guid
      : undefined;
    return {
      context: 'resource_index',
      entityName: Resource.entityName,
      parameters: guid
        ? {
            template_guid: guid,
            resource_template_guid: guid,
          }
        : undefined,
    };
  };

  return (
    <MasterIndex
      entityName={Resource.entityName}
      breadcrumb={breadcrumb}
      leftPane={leftPane}
      filters={filters}
      params={params}
      noResultsMessage={noResultsMessage}
      action={action}
      tableFormat={false}
      bulkActions={bulkActions}
      sortOptions={sortOptions}
      useAllPages
      entityView={entityView}
    >
      {({
        entities,
        readNextPage,
        status,
      }: IEntitySearchContainerChildrenProps) => (
        <ResourceIndexListOrTable
          entities={entities}
          loadMore={readNextPage}
          status={status}
        />
      )}
    </MasterIndex>
  );
};

export default ResourceIndex;
