/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/ModuleContext
 * @desc ModuleContext
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import DropdownPortalHover from 'labstep-web/core/Dropdown/Portal/Hover';
import { primaryLinks } from 'labstep-web/components/Layout/FullScreen/Links/constants';
import NavLink from 'labstep-web/core/NavLink';
import Link from 'labstep-web/core/Link';
import { IModuleContextProps } from './types';
import styles from './styles.module.scss';

export const ModuleContext: React.FC<IModuleContextProps> = ({
  activeGroup,
  moduleContext,
}) => (
  <DropdownPortalHover
    trigger={
      <div id="module-context" className={styles.context}>
        <Link
          to={moduleContext.route.to}
          params={{ id: activeGroup?.id }}
        >
          <Icon name={moduleContext.icon} />
          {moduleContext.text}
        </Link>
      </div>
    }
    content={
      <div className={styles.dropdown}>
        {primaryLinks.map((link) => (
          <div key={link.route.to} className={styles.dropdownItem}>
            <NavLink
              className={styles.link}
              to={link.route.to}
              params={{ id: activeGroup?.id }}
            >
              <Icon name={link.icon} />
              {link.text}
            </NavLink>
          </div>
        ))}
      </div>
    }
  />
);

export default ModuleContext;
