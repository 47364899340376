/**
 * Labstep
 *
 * @module core/List/Multi
 * @desc Core List for showing multiple cards
 */

import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

const MultiList: React.FC<any> = ({ children, half, ...rest }) => (
  <div
    className={cx(styles.list, { [styles.half]: half })}
    role="list"
    {...rest}
  >
    {children}
  </div>
);

export default MultiList;
