/**
 * Labstep
 *
 * @module components/Group/Settings/Experiment
 * @desc Set a default ExperimentWorkflow template for this group
 */

import EntityStateWorkflowIndex from 'labstep-web/components/EntityStateWorkflow/Index';
import GroupActionSetIsSignatureTwoFactorRequired from 'labstep-web/components/Group/Action/SetIsSignatureTwoFactorRequired';
import GroupActionUpdateIsCompletionChecklistEnabled from 'labstep-web/components/Group/Action/UpdateIsCompletionChecklistEnabled';
import GroupFormShowEditDefaultEntityStateWorkflow from 'labstep-web/components/Group/Form/ShowEdit/DefaultEntityStateWorkflow';
import GroupFormShowEditExperimentWorkflowTemplate from 'labstep-web/components/Group/Form/ShowEdit/DefaultExperimentWorkflowTemplate';
import GroupFormShowEditDefaultSignatureRequestMessage from 'labstep-web/components/Group/Form/ShowEdit/DefaultSignatureRequestMessage';
import GroupFormShowEditDefaultSignatureStatement from 'labstep-web/components/Group/Form/ShowEdit/DefaultSignatureStatement';
import GroupFormShowEditExperimentWorkflowCustomIdentifierSet from 'labstep-web/components/Group/Form/ShowEdit/CustomShowIdentifierSet/ExperimentWorkflow';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import { ILayoutLinksProps } from 'labstep-web/components/Layout/Links/types';
import Flex from 'labstep-web/core/Flex';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { ITableRowType } from 'labstep-web/core/Table/Horizontal/types';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { Group } from 'labstep-web/models/group.model';
import { TagType } from 'labstep-web/models/tag.model';
import ExperimentWorkflowIndexTemplate from 'labstep-web/screens/ExperimentWorkflow/Index/Template';
import TagIndex from 'labstep-web/screens/Tag/Index';
import React from 'react';
import { IGroupSettingsExperimentProps } from './types';

export const getRows = (
  group: Group,
  hasSignatureWorkflowFeatureEnabled: boolean,
) => {
  let rows: ITableRowType<Group>[] = [
    {
      header: 'Custom Identifier',
      content: () => (
        <Flex column spacing="gap">
          <GroupFormShowEditExperimentWorkflowCustomIdentifierSet
            group={group}
          />
          Automatically adds a prefix to new experiments with a number
          that increments each time i.e ELN001, ELN002, ELN003 etc.
        </Flex>
      ),
    },
    {
      header: 'Default Template',
      content: () => (
        <Flex column spacing="gap">
          <GroupFormShowEditExperimentWorkflowTemplate
            group={group}
          />
          If you set a default template for this Workspace, all new
          experiments will use this template by default unless another
          template is specified at creation.
        </Flex>
      ),
    },
  ];

  if (hasSignatureWorkflowFeatureEnabled) {
    rows = [
      ...rows,
      {
        header: `Default ${EntityStateWorkflow.getHumanReadableEntityName(
          false,
          true,
        )}`,
        content: () => (
          <Flex column spacing="gap">
            <GroupFormShowEditDefaultEntityStateWorkflow
              group={group}
            />
            The {EntityStateWorkflow.getHumanReadableEntityName()}{' '}
            determines the{' '}
            {EntityState.getHumanReadableEntityName(true)} each
            experiment must pass through and which roles must sign or
            be assigned at each stage. If the template used does not
            have a workflow, this will be used as the default instead.
          </Flex>
        ),
      },
    ];
  }

  rows = [
    ...rows,
    {
      header: 'Completion Checklist',
      content: () => (
        <Flex column spacing="gap">
          <GroupActionUpdateIsCompletionChecklistEnabled
            group={group}
          />
          When users finish a protocol or experiment, show a checklist
          highlighting missing data fields and incomplete steps.
        </Flex>
      ),
    },
  ];

  if (group.canEnableSignatureTwoFactorRequirement) {
    rows = [
      ...rows,
      {
        header: 'Require 2FA to Sign',
        content: () => (
          <Flex column spacing="gap">
            <GroupActionSetIsSignatureTwoFactorRequired
              group={group}
            />
            Require users to enter a code from a verified device when
            signing experiments.
          </Flex>
        ),
      },
    ];
  }

  if (!hasSignatureWorkflowFeatureEnabled) {
    rows = [
      ...rows,
      {
        header: 'Default Signature Request Message',
        content: () => (
          <GroupFormShowEditDefaultSignatureRequestMessage
            group={group}
          />
        ),
      },
      {
        header: 'Default Signature Statement',
        content: () => (
          <GroupFormShowEditDefaultSignatureStatement group={group} />
        ),
      },
    ];
  }

  return rows;
};

const GroupSettingsExperiment: React.FC<
  IGroupSettingsExperimentProps
> = ({ group }) => {
  const hasSignatureWorkflowFeatureEnabled = useHasFeatureFlagEnabled(
    'signature_workflow',
  );
  let links: ILayoutLinksProps['links'] = [
    {
      children: 'Experiments',
      route: {
        to: 'group_settings_experiment_workflow',
        params: { id: group.id },
      },
      render: () => (
        <HorizontalTable
          padded
          headerProps={{ width: 3 }}
          rows={getRows(group, hasSignatureWorkflowFeatureEnabled)}
          columns={[group]}
        />
      ),
    },
    {
      children: 'Templates',
      route: {
        to: 'group_settings_experiment_workflow_templates',
        params: { id: group.id },
      },
      render: () => <ExperimentWorkflowIndexTemplate group={group} />,
    },
    {
      children: 'Workflows',
      route: {
        to: 'group_settings_experiment_entity_state_workflow',
        params: { id: group.id },
      },
      render: () => <EntityStateWorkflowIndex group={group} />,
    },
    {
      children: 'Tags',
      route: {
        to: 'group_settings_experiment_tags',
        params: { id: group.id },
      },
      render: () => (
        <TagIndex group={group} type={TagType.experiment_workflow} />
      ),
    },
  ];
  if (!hasSignatureWorkflowFeatureEnabled) {
    links = links.filter((l) => l.children !== 'Workflows');
  }
  return (
    <div id="group-settings-experiment" style={{ flex: 1 }}>
      <LayoutLinks inverted links={links} />
    </div>
  );
};

export default GroupSettingsExperiment;
