/**
 * Labstep
 *
 * @module screens/Authentication/Signup/Left
 * @desc Signup Screen Left
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Divider from 'labstep-web/core/Divider';
import Image from 'labstep-web/core/Image';
import { Benefits } from 'labstep-web/screens/Authentication/Shared';
import styles from './styles.module.scss';

export const QUOTE =
  'I can search my past experiments very fast and show others my work efficiently.';
export const NAME = 'Chengwei Yuan';
export const UNIVERSITY = 'University of Cambridge, UK';

export const SignupLeft: React.FC = () => (
  <>
    <div className={styles.info}>
      <div className={styles.infoMessage}>
        A collaborative research environment with integrated ELN,
        inventory and order management modules. Built to simplify and
        accelerate scientific discovery.
      </div>
      <div>
        <Image src="/img/landing/global_collaboration.svg" />
      </div>
    </div>
    <Benefits header="Benefits of Labstep" />
    <div>
      <Divider horizontal>
        <Icon color="teal" name="quote right" />
      </Divider>
      <div className={styles.quote}>
        <p>{`"${QUOTE}"`}</p>
        <div>
          <b>{NAME}</b>
          <p>{UNIVERSITY}</p>
        </div>
      </div>
    </div>
  </>
);

export default SignupLeft;
