/**
 * Labstep
 *
 * @module components/Layout/MainContent/Center
 * @desc Layout center
 */

import React from 'react';
import styles from './styles.module.scss';
import { ILayoutMainContentCenterProps } from './types';

const LayoutMainContentCenter: React.FC<
  ILayoutMainContentCenterProps
> = ({ children }) => (
  <div className={styles.container} id="main-content-center">
    {children}
  </div>
);

export default LayoutMainContentCenter;
