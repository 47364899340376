/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport
 * @desc DataGrid Header for EntityImport
 */

import classNames from 'classnames';
import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import { EntityImportColDef } from 'labstep-web/models/entity-import.model';
import { IDataGridColumnHeaderEntityImportProps } from './types';
import DataGridColumnHeaderEntityImportCustom from './Metadata';
import DataGridColumnHeaderEntityImportDateFormat from './DateFormat';
import styles from './styles.module.scss';

export const DataGridColumnHeaderEntityImport: React.FC<
  IDataGridColumnHeaderEntityImportProps
> = (props) => {
  const colDef = props.column.getColDef() as
    | EntityImportColDef
    | undefined; // This can happen
  if (!colDef) {
    return null;
  }

  return (
    <DataGridColumnHeaderEntityImportCustom
      trigger={
        <span
          className={classNames({
            [styles.clickable]: colDef.isCustom || !colDef.isRequired,
          })}
        >
          {colDef.isCustom && (
            <Popup
              content="This column does not match any fields on the template. Click to match it to a column or create a new one."
              trigger={<Icon name="warning sign" color="yellow" />}
            />
          )}
          {props.icon && (
            <>
              <Icon name={props.icon} cornerIcon={props.cornerIcon} />{' '}
            </>
          )}
          {colDef.headerName}
          {props.info && (
            <>
              {' '}
              <Icon
                name="info circle"
                popup={{ content: props.info }}
              />{' '}
            </>
          )}
          <DataGridColumnHeaderEntityImportDateFormat
            colDef={colDef}
          />
        </span>
      }
      {...props}
    />
  );
};

export default DataGridColumnHeaderEntityImport;
