import {
  PostFilterComparison,
  PostFilterNodeType,
  PostFilterOperator,
  PostFilterType,
} from 'labstep-web/services/query-parameter.service';

export class PostFilter<
  TAttribute extends string,
  TPath extends string,
> {
  private readonly predicates: [TAttribute, PostFilterComparison][];

  private readonly path: TPath | undefined;

  /**
   * Check if two nodes point at the same filter
   * @param nodeA Node to compare
   * @param nodeB Node to compare
   * @returns Are the nodes the same
   */
  public static compareNodes(
    nodeA: PostFilterNodeType,
    nodeB: PostFilterNodeType,
  ): boolean {
    if (nodeA.path !== nodeB.path) {
      return false;
    }
    if (nodeA.predicates.length !== nodeB.predicates.length) {
      return false;
    }
    return nodeA.predicates.every((predicate, i) => {
      const { attribute } = nodeB.predicates[i];
      return predicate.attribute === attribute;
    });
  }

  /**
   * Creates a post filter
   * Predicates are an array of tuples where the first value is the attribute
   * and the second value is the comparison
   * e.g. [['name', PostFilterComparison.eq]]
   * The path is optional and will be added to the node if provided
   *
   * You can use the getNode method to get a node to add to the post filter
   * and pass in values to add to the predicates
   * e.g. postFilter.getNode(['test'])
   *
   * @param predicates Predicates to add to the post filter
   * @param path Path of the post filter
   */
  public constructor(
    predicates: [TAttribute, PostFilterComparison][],
    path?: TPath,
  ) {
    this.predicates = predicates;
    this.path = path;
  }

  /**
   * Checks if a node matches the post filter
   * @param node Node to check
   * @param compareValue Optional value to compare against
   * @returns Is the node active
   */
  public isNodeActive(
    node: PostFilterNodeType,
    compareValues?: string[],
  ): boolean {
    if (this.path && node.path !== this.path) {
      return false;
    }
    if (node.predicates.length !== this.predicates.length) {
      return false;
    }
    return node.predicates.every((predicate, i) => {
      const [attribute] = this.predicates[i];
      return (
        predicate.attribute === attribute &&
        (!compareValues || predicate.value === compareValues[i])
      );
    });
  }

  /**
   * Get the node from the search parameters if active
   * @param params Search params
   * @param compareValues Optional value to compare against
   * @returns Is the node in the post filter
   */
  public getNodeInParams(
    params: Partial<Record<'filter', PostFilterType>>,
    compareValues?: string[],
  ): PostFilterNodeType | undefined {
    if (!params.filter) {
      return undefined;
    }
    const predicatesUser = params.filter[0].predicates;
    const predicatesSystem = params.filter[1].predicates;
    return [...predicatesUser, ...predicatesSystem].find((node) =>
      this.isNodeActive(node, compareValues),
    );
  }

  /**
   * Returns the comparison and value of attributes of a node
   * e.g. { name: { comparison: PostFilterComparison.eq, value: 'test' } }
   * If the node does not match the post filter, an empty object is returned
   * @param node Node to get attribute values from
   * @returns A record of attribute comparisons and values
   */
  public getAttributeValues(
    node: PostFilterNodeType,
  ):
    | Record<
        TAttribute,
        { comparison: PostFilterComparison; value: string }
      >
    | Record<string, never> {
    if (!this.isNodeActive(node)) {
      return {};
    }
    return node.predicates.reduce(
      (acc, predicate) => ({
        ...acc,
        [predicate.attribute]: {
          comparison: predicate.comparison,
          value: predicate.value,
        },
      }),
      {},
    );
  }

  /**
   * Returns the path of the post filter
   * @returns Path
   */
  public getPath(): TPath | undefined {
    return this.path;
  }

  /**
   * Returns a node to add to the post filter
   * Values are optional and will be added to the predicates if provided
   * @param values Values to add to the predicates.
   * If a value is a tuple, the first value is the value and the second value is the comparison
   * e.g. ['test', PostFilterComparison.eq]
   * If a value is a string, the comparison will be the default comparison.
   * e.g. 'test'
   * @returns Node
   */
  public getNode(
    values?: (string | [string, PostFilterComparison] | undefined)[],
  ): PostFilterNodeType {
    return {
      path: this.path,
      type: PostFilterOperator.and,
      predicates: this.predicates.map((predicate, i) => {
        const attribute = predicate[0];
        let comparison = predicate[1];
        let value: string | undefined;
        if (values) {
          const valueAtIndex = values[i];
          if (Array.isArray(valueAtIndex)) {
            [value, comparison] = valueAtIndex;
          } else {
            value = valueAtIndex;
          }
        }
        return {
          attribute,
          comparison,
          value,
        };
      }),
    };
  }
}
