/**
 * Labstep
 *
 * @desc Table for PermissionRole
 */

import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import PermissionRoleActionMenu from 'labstep-web/components/PermissionRole/Action/Menu';
import { Action } from 'labstep-web/components/PermissionRole/Action/Menu/types';
import {
  DEFAULT_PERMISSION_ROLE_SETTINGS,
  PERMISSION_LABELS,
} from 'labstep-web/constants/roles';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import DropdownPillTrigger from 'labstep-web/core/Dropdown/Pill/Trigger';
import Hoverable from 'labstep-web/core/Hoverable';
import Icon from 'labstep-web/core/Icon';
import SimpleTableCollapsible from 'labstep-web/core/Table/Simple/Collapsible';
import { ISimpleTableCollapsibleProps } from 'labstep-web/core/Table/Simple/Collapsible/types';
import { PermissionRoleSetting } from 'labstep-web/models/permission-role-setting.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import React from 'react';
import styles from './styles.module.scss';
import {
  IPermissionRoleTableProps,
  PermissionRoleTableContainerProps,
} from './types';
import {
  LABELS_FOR_PERMISSIONS,
  getExistingPermissionRoleSettingType,
  getItems,
  getSections,
} from './utils';

export type SectionType = {
  name: string;
  entityName: string;
  permission: string;
};

export const PermissionRoleTable: React.FC<
  IPermissionRoleTableProps
> = ({ create, permissionRoles, organization }) => {
  const columns: ISimpleTableCollapsibleProps['columns'] = [
    {
      header: <div style={{ width: '210px' }} />,
      content: ({ name }: SectionType) => (
        <span style={{ marginLeft: '50px' }}>
          {PERMISSION_LABELS[name]}
        </span>
      ),
      cellProps: { width: 5 },
    },
  ];

  DEFAULT_PERMISSION_ROLE_SETTINGS.forEach((permissionRole) => {
    columns.push({
      header: (
        <span>
          {permissionRole.name} <Icon name="lock" />
        </span>
      ),
      content: ({ entityName, permission }: SectionType) => (
        <DropdownPillTrigger
          activeLabel={
            LABELS_FOR_PERMISSIONS[
              permissionRole.settings[entityName][permission]
            ]
          }
          disabled
        />
      ),
      cellProps: { style: { minWidth: 100 } },
    });
  });

  permissionRoles.forEach((permissionRole) => {
    columns.push({
      header: (
        <Hoverable
          component={
            <CanCreate
              parentName={organization.entityName}
              parentId={organization.id}
              entityName={PermissionRole.entityName}
            >
              <PermissionRoleActionMenu
                permissionRole={permissionRole}
                actions={[Action.edit, Action.delete]}
              />
            </CanCreate>
          }
          hoverable={<span>{permissionRole.name}</span>}
        />
      ),
      content: ({ entityName, permission }: SectionType) => (
        <EntityActionUpdateDropdownPill
          entity={permissionRole}
          currentValue={getExistingPermissionRoleSettingType(
            permissionRole,
            entityName,
            permission,
          )}
          items={getItems(
            permissionRole,
            entityName,
            permission,
            create,
          )}
        />
      ),
      cellProps: { style: { minWidth: 100 } },
    });
  });

  const sections = getSections(organization);

  return (
    <div className={styles.container}>
      <SimpleTableCollapsible
        colSpan={1}
        columns={columns}
        sections={sections}
        fixed={false}
      />
    </div>
  );
};

export const PermissionRoleTableContainer: React.FC<
  PermissionRoleTableContainerProps
> = (props) => (
  <EntityCreateContainer
    entityName={PermissionRoleSetting.entityName}
  >
    {(containerProps) => (
      <PermissionRoleTable {...containerProps} {...props} />
    )}
  </EntityCreateContainer>
);

export default PermissionRoleTableContainer;
