/**
 * Labstep
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { getToken } from 'labstep-web/prosemirror/utils/selection';
import { submitElement } from 'labstep-web/prosemirror/extensions/slash/commands/actions';
import commandsPlugin, {
  TRIGGER,
} from 'labstep-web/prosemirror/extensions/slash/plugin';
import { replaceWithNode } from 'labstep-web/prosemirror/nodes/commands';
import NodeConditions from '..';

/**
 * Replace token with conditions node
 * @param state State
 * @param dispatch Dispatch
 */
export const createConditionsNode = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
): void => {
  const { from, to } = getToken(state, TRIGGER);
  replaceWithNode(state, dispatch, from, to, NodeConditions.key);
};

/**
 * Submit conditions element so we can open modal from within Modal/Add
 * @param state State
 * @param dispatch Dispatch
 */
export const createConditionsNodeAndSubmitElement = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
): void => {
  const dispatchWithSubmitElement: IStateDispatchProps['dispatch'] = (
    tr,
  ) => {
    tr.setMeta(commandsPlugin, submitElement('conditions'));
    dispatch?.(tr);
  };
  createConditionsNode(state, dispatchWithSubmitElement);
};
