/**
 * Labstep
 *
 * @module screens/Authentication/Signup
 * @desc Signup Screen
 */

import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Columns from 'labstep-web/screens/Authentication/Columns';
import React from 'react';
import SignupLeft from './Left';
import SignupRight from './Right';
import { ScreensAuthenticationSignupProps } from './types';

export const ScreensAuthenticationSignup: React.FC<
  ScreensAuthenticationSignupProps
> = ({ uiPersistent }) => {
  const activeShareLink = uiPersistent.active_share_link;
  const header = activeShareLink ? '' : 'Sign up for free';
  const explainer = activeShareLink
    ? ''
    : 'Keep your research organised, accurate & accessible';
  return (
    <>
      <DocumentHead title="Signup" />
      <Columns
        inverted
        header={header}
        explainer={explainer}
        left={<SignupLeft />}
        right={<SignupRight activeShareLink={activeShareLink} />}
        centerAlign={false}
      />
    </>
  );
};

export default withUiPersistent(ScreensAuthenticationSignup);
