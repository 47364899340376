/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Action/Create
 * @desc Create Action for Custom Identifier Set
 */

import CustomIdentifierSetFormCreate from 'labstep-web/components/CustomIdentifierSet/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { ICustomIdentifierSetActionCreateProps } from './types';

export const CustomIdentifierSetActionCreate: React.FC<
  ICustomIdentifierSetActionCreateProps
> = ({ groupId, parentClass, onSuccess }) => (
  <Modal
    header="Custom Identifier"
    viewComponent={
      <ActionComponent
        type="text"
        text="Enable Custom Identifier"
        elementProps={{
          placeholder: true,
        }}
      />
    }
    content={
      <CustomIdentifierSetFormCreate
        groupId={groupId}
        parentClass={parentClass}
        options={
          onSuccess && {
            onSuccess: ({ response }) => {
              const id = response.result;
              onSuccess({
                group_id: groupId,
                custom_identifier_set_id: id,
              });
            },
          }
        }
      />
    }
  />
);

export default CustomIdentifierSetActionCreate;
