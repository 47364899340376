/**
 * Labstep
 *
 * @desc Fields for outbound order requests
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldOutboundOrderRequestAmount: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'amount',
    fieldLabel: 'Price',
    placeholder: 'Enter Price',
    validation: rules.outbound_order_request.amount,
  };

export const fieldOutboundOrderRequestCommission: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'commission_amount',
    fieldLabel: 'Commission',
    placeholder: 'Enter Commission',
    validation: rules.outbound_order_request.commission,
  };
