/**
 * Labstep
 */

import { IIconProps } from 'labstep-web/core/Icon/types';
import { Entity } from 'labstep-web/models/entity.model';
import { ColDefParams } from '../types';

export interface IDataGridColumnHeaderProps
  extends ColDefParams<Entity> {
  displayName?: string;
  icon?: IIconProps['name'];
  cornerIcon?: IIconProps['cornerIcon'];
  action?: React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /** Merge with parent/child cell */
  mergeAs?: DataGridColumnHeaderMergeAsTypes | null;
}

export type DataGridColumnHeaderStyleStateType = {
  marginTop: number;
  marginBottom: number;
};

// eslint-disable-next-line no-shadow
export enum DataGridColumnHeaderMergeAsTypes {
  /** Parent of merged column header */
  parent = 'parent',
  /** Child of merged column header */
  child = 'child',
}
