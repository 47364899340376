/**
 * Labstep
 *
 * @module components/Entity/Action/Share
 * @desc Share an entity using a ShareLink
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import CreateShareLinkContainer from 'labstep-web/components/ShareLink/Action/Create';
import ShareLinkInfo from 'labstep-web/components/ShareLink/Info';
import PermissionManager from 'labstep-web/components/Permission/Manager';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IShareEntityActionProps } from './types';

const ShareEntityAction: React.FC<IShareEntityActionProps> = ({
  entity,
  actionComponentProps,
}) => (
  <Can
    entityName={entity.entityName}
    id={entity.idAttr}
    action={Action.share}
  >
    <Modal
      size="small"
      header={`Share ${getHumanReadableEntityName(
        entity.entityName,
        false,
        true,
      )}`}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          onClick={toggleModal}
          text="Share"
          icon="share alternate"
          {...actionComponentProps}
        />
      )}
      content={
        <CreateShareLinkContainer parentEntity={entity as any}>
          {({ shareLink }) => (
            <>
              <ShareLinkInfo
                shareLink={shareLink}
                parentEntity={entity as any}
              />
              {entity.entityName !== 'group' && (
                <PermissionManager parentEntity={entity as any} />
              )}
            </>
          )}
        </CreateShareLinkContainer>
      }
    />
  </Can>
);

export default ShareEntityAction;
