/**
 * Labstep
 *
 * @module components/ShareLink/Action/Email
 * @desc Email shareLink to collaborators
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ShareLinkFormEmail from 'labstep-web/components/ShareLink/Form/Email';
import { IShareLinkActionEmailProps } from './types';

const ShareLinkActionEmail: React.FC<IShareLinkActionEmailProps> = ({
  shareLink,
}) => (
  <ModalDefault
    id="nested-modal"
    header="Share via email"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        text="Email"
        type="button"
        dataTestId="share-link-action-email"
      />
    )}
    content={({ toggleModal }) => (
      <ShareLinkFormEmail
        shareLink={shareLink}
        options={{ onSuccess: toggleModal }}
      />
    )}
  />
);

export default ShareLinkActionEmail;
