/**
 * Labstep
 *
 * @module components/Protocol/SearchSelect
 * @desc Protocol Search Select
 */

import React from 'react';
import SearchSelect from 'labstep-web/core/Select/Search';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { IProtocolSearchSelectProps } from './types';

export const ProtocolSearchSelect: React.FC<
  IProtocolSearchSelectProps
> = ({ params, ...rest }) => (
  <SearchSelect
    labelKey="label"
    setOptions={(protocolCollections: ProtocolCollection[]) =>
      protocolCollections.map((protocolCollection) => {
        const {
          name: label,
          last_version: { id },
        } = protocolCollection;
        return { id, label };
      })
    }
    entityName="protocol_collection"
    params={params}
    {...rest}
  />
);

export default ProtocolSearchSelect;
