/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/ImportFileMapping
 * @desc EntityImport Create Step 2: ImportFileMapping
 */

import Header from 'labstep-web/core/Header';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import Select from 'labstep-web/core/Select';
import { AGGridEntityImportExcelService } from 'labstep-web/services/agGrid/ag-grid-entity-import-excel.service';
import React, { useCallback } from 'react';
import { Table } from 'semantic-ui-react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import OptionWithPreview from './OptionWithPreview';
import styles from './styles.module.scss';
import { IEntityImportActionCreateStepsImportFileMappingProps } from './types';

export const EntityImportActionCreateStepsImportFileMapping: React.FC<
  IEntityImportActionCreateStepsImportFileMappingProps
> = ({ importData, index, onContinue, entityName }) => {
  const [selectedHeaderRow, setSelectedHeaderRow] =
    React.useState<number>(0);
  const [selectedNameColumn, setSelectedNameColumn] =
    React.useState<string>(
      AGGridEntityImportExcelService.getHeaderRowKeys(
        importData,
        0,
      )[0],
    );

  const [rowPreview, nOthers] =
    AGGridEntityImportExcelService.getRowPreview(
      importData,
      selectedHeaderRow,
    );

  const columnPreview =
    AGGridEntityImportExcelService.getColumnFirstValue(
      importData,
      selectedNameColumn,
      selectedHeaderRow,
    );

  const getRowOption = useCallback(
    (i: number) => ({
      value: i,
      label: `Row ${i + 1}`,
      preview: AGGridEntityImportExcelService.getRowPreview(
        importData,
        i,
      )[0],
    }),
    [],
  );

  const getColumnOption = useCallback(
    (column: string) => ({
      value: column,
      label: AGGridEntityImportExcelService.getCellValue(
        AGGridEntityImportExcelService.getHeaderRow(
          importData,
          selectedHeaderRow,
        )[column],
      ),
      preview: AGGridEntityImportExcelService.getColumnFirstValue(
        importData,
        column,
        selectedHeaderRow,
      ),
    }),
    [importData, selectedHeaderRow],
  );

  const nRows = AGGridEntityImportExcelService.getNRows(
    importData,
    true,
  );
  const columns = AGGridEntityImportExcelService.getHeaderRowKeys(
    importData,
    selectedHeaderRow,
  );

  return (
    <ModalWizard.Step
      index={index}
      onContinue={({ goForward }) => {
        onContinue(selectedHeaderRow, selectedNameColumn);
        goForward();
      }}
      description={
        <>
          Select the row in your file that contains the column headers
          and the column that holds the{' '}
          <i>
            {getHumanReadableEntityName(entityName, false, true)} Name
          </i>
          .
        </>
      }
      help={
        <div className={styles.help}>
          <div>
            <Header size="tiny">
              Selecting a Column Heading Row
            </Header>
            <p>
              Column headers will be matched to field names in your
              Labstep Inventory i.e. Vendor, Catalog #
            </p>
          </div>
          <div>
            <Header size="tiny">
              Selecting a{' '}
              {getHumanReadableEntityName(entityName, false, true)}{' '}
              Name Column
            </Header>
            <p>
              You must specify which column refers to the name of the
              {getHumanReadableEntityName(entityName, false, false)}.
              The name will be used to match to existing
              {getHumanReadableEntityName(entityName, true, false)}.
              If no match is found, new{' '}
              {getHumanReadableEntityName(entityName, true, false)}{' '}
              will be created.
            </p>
          </div>
        </div>
      }
    >
      <Table basic="very" data-testid="import-file-mapping">
        <Table.Body>
          <Table.Row verticalAlign="top">
            <Table.Cell width="4">
              <Select
                value={getRowOption(selectedHeaderRow)}
                label={{
                  text: 'Row Containing Column Headers',
                  position: 'top',
                }}
                options={Array.from(Array(nRows).keys()).map((_, i) =>
                  getRowOption(i),
                )}
                onChange={(
                  newValue: ReturnType<typeof getRowOption>,
                ) => setSelectedHeaderRow(newValue.value)}
                components={{ Option: OptionWithPreview }}
              />
            </Table.Cell>
            <Table.Cell width="8">
              <div className={styles.preview}>
                Data Preview
                <br />
                <b>{rowPreview}</b>
                {nOthers > 0 ? <i> + {nOthers} others</i> : ''}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell width="4">
              <Select
                label={{
                  text: `${getHumanReadableEntityName(
                    entityName,
                    false,
                    true,
                  )} Name Column`,
                  position: 'top',
                }}
                value={getColumnOption(selectedNameColumn)}
                options={columns.map((column) =>
                  getColumnOption(column),
                )}
                onChange={(
                  newValue: ReturnType<typeof getColumnOption>,
                ) => setSelectedNameColumn(newValue.value)}
                components={{ Option: OptionWithPreview }}
              />
            </Table.Cell>
            <Table.Cell width="8">
              <div className={styles.preview}>
                Data Preview
                <br />
                <b>{columnPreview}</b>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </ModalWizard.Step>
  );
};

export default EntityImportActionCreateStepsImportFileMapping;
