/**
 * Labstep
 *
 * @module components/Organization/Action/UpdateUserRolesBulk
 * @desc UpdateUserRolesBulk
 */

import React from 'react';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { capitalize } from 'lodash';
import {
  UserOrganization,
  UserOrganizationType,
} from 'labstep-web/models/user-organization.model';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import Dropdown from 'labstep-web/core/Dropdown';
import { IOrganizationActionUpdateUserRoleBulkProps } from './types';

export const OrganizationActionUpdateUserRoleBulk: React.FC<
  IOrganizationActionUpdateUserRoleBulkProps
> = ({ entities, options }) => {
  if (!entities || entities.length === 0) {
    return null;
  }

  return (
    <Dropdown
      direction="left"
      icon={null}
      trigger={
        <ActionComponent
          icon="user circle"
          text="Set Organization Role"
          type="text"
        />
      }
    >
      {Object.keys(UserOrganizationType).map((type) => (
        <EntityUpdateContainer
          key={type}
          entityName={UserOrganization.entityName}
          id={entities.map((e: any) => {
            return e.id;
          })}
        >
          {({ update }) => (
            <Dropdown.Item
              onClick={() => {
                update(
                  {
                    type,
                  },
                  options,
                );
              }}
            >
              {capitalize(type)}
            </Dropdown.Item>
          )}
        </EntityUpdateContainer>
      ))}
    </Dropdown>
  );
};
