/**
 * Labstep
 */

import { IWithAuthenticatedUserEntityInjectedProps } from 'labstep-web/containers/AuthenticatedUser/types';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { PremiumFeature } from 'labstep-web/models/organization.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { IProseMirrorCursorPosition } from 'labstep-web/prosemirror/cursor';
import { IProseMirrorCommandsElement } from 'labstep-web/prosemirror/extensions/slash/types';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { ReactElement } from 'react';

export interface IMenuCommandsProps
  extends IStateDispatchProps,
    IWithAuthenticatedUserEntityInjectedProps {
  entity: Experiment | Protocol;
  experimentWorkflow?: ExperimentWorkflow;
  protocolCollection?: ProtocolCollection;
  cursorPosition: IProseMirrorCursorPosition;
  view: EditorView;
}

export interface IMenuCommandsElementProps {
  id: IProseMirrorCommandsElement;
  icon?: IIconProps['name'];
  image?: string;
  description: string;
  key: string;
  name?: string;
  /** Only required if element is created on select */
  entityName?: string;
  component?: (props: unknown) => ReactElement;
  premium_feature?: PremiumFeature;
  onCreate?: (
    state: EditorState,
    dispatch: IStateDispatchProps['dispatch'],
  ) => void;
  label?: MenuCommandsLabel;
}

// eslint-disable-next-line no-shadow
export enum MenuCommandsLabel {
  New = 'New',
}
