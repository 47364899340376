/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import Button from 'labstep-web/core/Button';
import Form from 'labstep-web/core/Form';
import Input from 'labstep-web/core/Input';
import Label from 'labstep-web/core/Label';
import Container from 'labstep-web/components/Layout/Container';
import { IbanElement } from '@stripe/react-stripe-js';
import styles from './styles.module.scss';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'DE',
  style: IBAN_STYLE,
};

export default function IbanForm({ onSubmit, disabled }) {
  return (
    <Form className={styles.container} onSubmit={onSubmit}>
      <Form.Field inline>
        <Input
          label="Name"
          name="accountholder-name"
          placeholder="Jenny Rosen"
          required
        />
      </Form.Field>
      <Form.Field inline>
        <Input
          label="Email Address"
          name="email"
          type="email"
          placeholder="jenny.rosen@example.com"
          required
        />
      </Form.Field>
      <Form.Field>
        <Label>IBAN</Label>
        <IbanElement options={IBAN_ELEMENT_OPTIONS} />
      </Form.Field>

      <Container>
        <Button primary type="submit" disabled={disabled}>
          Set up SEPA Direct Debit
        </Button>
      </Container>

      {/* Display mandate acceptance text. */}
      <div className="mandate-acceptance">
        By providing your payment information and confirming this
        payment, you authorise (A) Rocketship Inc and Stripe, our
        payment service provider, to send instructions to your bank to
        debit your account and (B) your bank to debit your account in
        accordance with those instructions. As part of your rights,
        you are entitled to a refund from your bank under the terms
        and conditions of your agreement with your bank. A refund must
        be claimed within 8 weeks starting from the date on which your
        account was debited. Your rights are explained in a statement
        that you can obtain from your bank. You agree to receive
        notifications for future debits up to 2 days before they
        occur.
      </div>
    </Form>
  );
}
