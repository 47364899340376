import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldJupyterNotebookName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  placeholder: 'Add name',
  validation: rules.jupyter_notebook.name,
};
