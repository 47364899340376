/**
 * Labstep
 *
 * @module state/epics/organization
 */

import { Observable, concat, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  tap,
} from 'rxjs/operators';
import { StripeService } from 'labstep-web/services/stripe.service';
import { windowService } from 'labstep-web/services/window.service';
import { Action } from 'labstep-web/models/action.model';

/**
 * Redirect to stripe checkout
 */
export const organizationSubscriptionPortalActionSuccessEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_ORGANIZATION_SUBSCRIPTION_PORTAL',
    ),
    tap((action: Action) => {
      StripeService.redirectToCheckout(action.payload.session_id);
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_ORGANIZATION_SUBSCRIPTION_PORTAL_SUCCESS',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Confirm intent SEPA Debit - Success
 */
export const confirmIntentSepaDebitActionSuccessEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_CONFIRM_INTENT_SEPA_DEBIT',
    ),
    tap(() => {
      windowService.reload();
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_SUCCESS_CONFIRM_INTENT_SEPA_DEBIT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Confirm intent SEPA Debit - Fail
 */
export const confirmIntentSepaDebitActionFailEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'FAIL_CONFIRM_INTENT_SEPA_DEBIT',
    ),
    tap(() => {
      windowService.alert(
        'Could not proceed. Please email info@labstep.com.',
      );
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_FAIL_CONFIRM_INTENT_SEPA_DEBIT',
          payload: err,
        }),
        source$,
      ),
    ),
  );
