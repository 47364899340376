/**
 * Labstep
 *
 * @deprecated
 */

import * as sfApi from 'labstep-web/services/sf-api.service';
import { IOptions } from 'labstep-web/typings';

export const transferOwnershipActionType = 'transfer_ownership';

export const transferOwnershipAction = (
  entityName,
  entityId,
  groupId,
  options: IOptions,
): any =>
  sfApi.post({
    type: 'TRANSFER_OWNERSHIP',
    route: {
      custom: `app_${entityName.replace('_', '')}_transferownership`,
    },
    meta: {
      action_type: transferOwnershipActionType,
      customReducer: 1,
      normalize: entityName,
    },
    body: { entity_id: entityId, group_id: groupId },
    ...options,
  });
