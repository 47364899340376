/**
 * Labstep
 *
 * @module state/selectors/jupyter
 * @desc Selectors for Jupyter OAuth
 */

import { createSelector } from 'reselect';

/**
 * Select jupyter OAuth authorize status
 *
 * @function
 * @param  {object} state
 * @return {IStatus} status
 */
export const selectJupyterOAuthAuthorizeStatus = createSelector(
  (state: any) => state.jupyter,
  (jupyter: any) => jupyter.authorize,
);

/**
 * Select jupyter get link status
 *
 * @function
 * @param  {object} state
 * @return {IStatus} status
 */
export const selectJupyterGetLinkStatus = createSelector(
  (state: any) => state.jupyter,
  (jupyter: any) => jupyter.get_link,
);

/**
 * Select jupyter run status
 *
 * @function
 * @param  {object} state
 * @return {IStatus} status
 */
export const selectJupyterRunStatus = createSelector(
  (state: any) => state.jupyter,
  (jupyter: any) => jupyter.run,
);
