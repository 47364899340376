/**
 * Labstep
 *
 * @module containers/JupyterOAuth
 * @desc Sends OAuth authorize request with logged user JWT token
 */

import { jupyterOAuthAuthorize } from 'labstep-web/state/actions/jupyter';
import { selectJupyterOAuthAuthorizeStatus } from 'labstep-web/state/selectors/jupyter';
import React from 'react';
import { connect } from 'react-redux';
import {
  WithJupyterOAuthInjectedProps,
  WithJupyterOAuthMapDispatchToProps,
  WithJupyterOAuthMapStateToProps,
} from './types';

const mapStateToProps: any = (
  state,
): WithJupyterOAuthMapStateToProps => ({
  status: selectJupyterOAuthAuthorizeStatus(state),
});

const mapDispatchToProps = (
  dispatch,
): WithJupyterOAuthMapDispatchToProps => ({
  authorize(
    responseType: string,
    redirectURI: string,
    clientId: string,
    state: string,
  ) {
    dispatch(
      jupyterOAuthAuthorize(
        responseType,
        redirectURI,
        clientId,
        state,
      ),
    );
  },
});

export const withJupyterOAuth = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithJupyterOAuthInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
