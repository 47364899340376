/**
 * Labstep
 *
 * @module models/automation
 * @desc Typescript export class for EntityState
 */

import { Type } from 'class-transformer';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Entity } from './entity.model';
import { Group } from './group.model';
import { User } from './user.model';

export class Automation extends Entity {
  public static readonly entityName = 'automation';

  public static readonly entityClass = 'automation' as const;

  public get entityName(): string {
    return Automation.entityName;
  }

  public constructor(data: Partial<Automation> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string;

  public type!: string;

  public trigger_log_type!: string;

  public filter!: Record<string, unknown>;

  public action!: string;

  public payload!: Record<string, unknown>;

  @Type(() => Group)
  public owner!: Group;

  @Type(() => User)
  public author!: User;

  get key(): string {
    return `${this.type}:${this.action}`;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
