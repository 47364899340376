/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show/Right
 * @desc ExperimentWorkflow Show Right
 */

import CommentShow from 'labstep-web/components/Comment/Show';
import EntityTimeMachine from 'labstep-web/components/Entity/TimeMachine';
import ExperimentWorkflowExperimentManager from 'labstep-web/components/Experiment/Manager';
import ExperimentWorkflowCommentHeader from 'labstep-web/components/ExperimentWorkflow/CommentHeader';
import RightPane from 'labstep-web/components/Layout/RightPane';
import MetadataManagerList from 'labstep-web/components/Metadata/Manager/List';
import MetadataShow, {
  MetadataShowName,
} from 'labstep-web/components/Metadata/Show';
import MetadataShowThread, {
  MetadataShowThreadName,
} from 'labstep-web/components/Metadata/Show/Thread';
import ProtocolStepShowThread from 'labstep-web/components/ProtocolStep/Show/Thread';
import ProtocolValueShow, {
  ProtocolValueShowName,
} from 'labstep-web/components/ProtocolValue/Show';
import SignatureManager from 'labstep-web/components/Signature/Manager';
import ThreadForm, {
  ThreadFormViewComponent,
} from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants/icons';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import Pane from 'labstep-web/screens/ExperimentWorkflow/Show/Center/Pane';
import { navigation, routing } from 'labstep-web/services/navigation';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import ExperimentWorkflowShowRightDevices from './Devices';
import ExperimentWorkflowShowRightExperimentWorkflowLinks from './ExperimentWorkflowLinks';
import ExperimentWorkflowShowRightMetadatas from './Metadatas';
import { ExperimentWorkflowShowRightEntityStateWorkflow } from './StateWorkflow';
import ExperimentWorkflowShowRightValues from './Values';
import { IScreensExperimentWorkflowShowRightProps } from './types';

const checkReferrer = (
  location: RouteComponentProps['location'],
  route: string,
  params: { [key: string]: any },
) =>
  (location.state as any)?.referrer?.pathname ===
  routing[route](params);

const ScreensExperimentWorkflowShowRight: React.FC<
  IScreensExperimentWorkflowShowRightProps
> = ({ experimentWorkflow, activeExperimentWorkflowRoute }) => {
  const experimentShowRoute = navigation.get('experiment_show');
  const experimentActive = useRouteMatch(experimentShowRoute);
  const hasSignatureWorkflowFeatureEnabled = useHasFeatureFlagEnabled(
    'signature_workflow',
  );

  const subPages = [
    {
      name: 'Protocols',
      count: experimentWorkflow.experiment_count,
      icon: ICONS.experiment.primary,
      route: {
        to: 'experiment_workflow_show_workflow',
        params: { id: experimentWorkflow.id },
      },
      component: (
        <div className="experiment-workflow-protocols">
          <ExperimentWorkflowExperimentManager
            experimentWorkflow={experimentWorkflow}
          />
        </div>
      ),
    },
    {
      name: 'Inventory',
      count: experimentWorkflow.protocol_value_count,
      icon: ICONS.protocol_value.primary,
      route: {
        to: 'experiment_workflow_show_values',
        params: { id: experimentWorkflow.id },
      },
      component: (
        <ExperimentWorkflowShowRightValues
          experimentWorkflow={experimentWorkflow}
        />
      ),
      expanded: (
        <ExperimentWorkflowShowRightValues
          experimentWorkflow={experimentWorkflow}
          tableFormat
        />
      ),
    },
    {
      name: 'Data',
      icon: ICONS.metadata.default,
      count: experimentWorkflow.metadata_count,
      route: {
        to: 'experiment_workflow_show_metadata',
        params: { id: experimentWorkflow.id },
      },
      component: (
        <ExperimentWorkflowShowRightMetadatas
          experimentWorkflow={experimentWorkflow}
        />
      ),
      expanded: (
        <Pane
          right={(experiment) => (
            <MetadataManagerList
              key={experiment.id}
              draggable={false}
              entity={experiment}
              isTemplate={experiment.isTemplate}
              explanation="Add data fields to capture variable input parameters, such as incubation temperatures, or output data, such as purification yields, in a quick and structured fashion."
            />
          )}
          count={(experiment) => experiment.protocol_value_count}
          experimentWorkflow={experimentWorkflow}
        />
      ),
    },
    {
      name: 'Devices',
      count: experimentWorkflow.protocol_device_count,
      icon: ICONS.device.primary,
      route: {
        to: 'experiment_workflow_show_devices',
        params: { id: experimentWorkflow.id },
      },
      component: (
        <ExperimentWorkflowShowRightDevices
          experimentWorkflow={experimentWorkflow}
        />
      ),
    },
    {
      name: 'Activity',
      icon: 'history' as IIconProps['name'],
      route: {
        to: 'experiment_workflow_show_activity',
        params: { id: experimentWorkflow.id },
      },
      component: (
        <EntityTimeMachine
          logEntity={experimentWorkflow}
          documentEntity={experimentWorkflow.root_experiment}
        />
      ),
    },
  ];

  // Remove review and linked experiment tabs for template
  if (!experimentWorkflow.is_template) {
    subPages.splice(
      -1,
      0,
      {
        name: 'Signatures',
        count: experimentWorkflow.signatures.length,
        icon: 'edit',
        route: {
          to: 'experiment_workflow_show_signatures',
          params: { id: experimentWorkflow.id },
        },
        component: (
          <SignatureManager experimentWorkflow={experimentWorkflow} />
        ),
      },
      {
        name: 'Linked Experiments',
        count: experimentWorkflow.totalLinkCount,
        icon: ICONS.experiment_workflow.primary,
        route: {
          to: 'experiment_workflow_show_experiment_workflows',
          params: { id: experimentWorkflow.id },
        },
        component: (
          <ExperimentWorkflowShowRightExperimentWorkflowLinks
            experimentWorkflow={experimentWorkflow}
          />
        ),
      },
    );
  } else if (hasSignatureWorkflowFeatureEnabled) {
    subPages.splice(0, 0, {
      name: `${EntityStateWorkflow.getHumanReadableEntityName(
        false,
        true,
      )}`,
      icon: ICONS.entity_state_workflow.primary,
      route: {
        to: 'experiment_workflow_show_state_workflow',
        params: { id: experimentWorkflow.id },
      },
      component: (
        <ExperimentWorkflowShowRightEntityStateWorkflow
          experimentWorkflow={experimentWorkflow}
        />
      ),
    });
  }

  subPages.splice(-1, 0, {
    name: 'Notes',
    icon: ICONS.thread.primary,
    count: experimentWorkflow.comment_count,
    route: {
      to: 'experiment_workflow_show_thread',
      params: { id: experimentWorkflow.id },
    },
    component: (
      <ThreadForm
        key={JSON.stringify(experimentWorkflow.thread.id)}
        createThreadId={experimentWorkflow.thread.id}
        readThreadIds={[]}
        experimentWorkflow={experimentWorkflow}
        entityNameLabel="notes"
        header={({ comment }) => (
          <ExperimentWorkflowCommentHeader comment={comment} />
        )}
      />
    ),
    expanded: (
      <ThreadFormViewComponent
        key={JSON.stringify(experimentWorkflow.thread.id)}
        createThreadId={experimentWorkflow.thread.id}
        readThreadIds={[]}
        experimentWorkflow={experimentWorkflow}
        entityNameLabel="notes"
        header={({ comment }) => (
          <ExperimentWorkflowCommentHeader comment={comment} />
        )}
      />
    ),
  });

  const getParamFromActiveMatch = (match, param: string) => {
    return !experimentActive
      ? match.params[param]
      : activeExperimentWorkflowRoute.params[param];
  };

  const subPagesNested = [
    {
      icon: ICONS.protocol_value.primary,
      route: {
        to: 'experiment_workflow_show_value_show',
      },
      backRoute: {
        to: 'experiment_workflow_show_values',
        params: { id: experimentWorkflow.id },
      },
      component: ({ match }) => (
        <ProtocolValueShow
          id={getParamFromActiveMatch(match, 'childId')}
        />
      ),
      name: ({ match }) => (
        <ProtocolValueShowName
          id={getParamFromActiveMatch(match, 'childId')}
        />
      ),
    },
    {
      icon: ICONS.metadata.primary,
      route: {
        to: 'experiment_workflow_show_metadata_show',
      },
      backRoute: {
        to: 'experiment_workflow_show_metadata',
        params: { id: experimentWorkflow.id },
      },
      component: ({ match }) => (
        <MetadataShow
          id={getParamFromActiveMatch(match, 'childId')}
          parentEntityName={Experiment.entityName}
        />
      ),
      name: ({ match }) => (
        <MetadataShowName
          id={getParamFromActiveMatch(match, 'childId')}
        />
      ),
    },
    {
      icon: ICONS.thread.primary,
      route: {
        to: 'experiment_workflow_show_thread_show',
      },
      backRoute: {
        to: 'experiment_workflow_show_thread',
        params: { id: experimentWorkflow.id },
      },
      component: ({ match }) => (
        <CommentShow id={getParamFromActiveMatch(match, 'childId')} />
      ),
      name: () => 'Thread',
    },
    {
      icon: ICONS.thread.primary,
      route: {
        to: 'experiment_workflow_show_metadata_show_thread',
      },
      backRoute: ({ location }) => ({
        to: checkReferrer(
          location,
          'experiment_workflow_show_thread',
          { id: experimentWorkflow.id },
        )
          ? 'experiment_workflow_show_thread'
          : 'experiment_workflow_show_metadata_show',
        params: { id: experimentWorkflow.id },
      }),
      component: ({ match }) => (
        <MetadataShowThread
          id={getParamFromActiveMatch(match, 'childId')}
        />
      ),
      name: ({ match }) => (
        <MetadataShowThreadName
          id={getParamFromActiveMatch(match, 'childId')}
        />
      ),
    },
    {
      icon: ICONS.thread.primary,
      route: {
        to: 'experiment_workflow_show_step_show_thread',
      },
      backRoute: {
        to: 'experiment_workflow_show_thread',
        params: { id: experimentWorkflow.id },
      },
      component: ({ match }) => (
        <ProtocolStepShowThread
          id={getParamFromActiveMatch(match, 'childId')}
        />
      ),
      name: () => 'Notes',
    },
    {
      icon: ICONS.thread.primary,
      route: {
        to: 'experiment_workflow_show_metadata_show_thread_show',
      },
      backRoute: ({ location }) => ({
        to: checkReferrer(
          location,
          'experiment_workflow_show_thread',
          { id: experimentWorkflow.id },
        )
          ? 'experiment_workflow_show_thread'
          : 'experiment_workflow_show_metadata_show_thread',
        params: { id: experimentWorkflow.id },
      }),
      component: ({ match }) => (
        <CommentShow
          id={getParamFromActiveMatch(match, 'threadId')}
        />
      ),
      name: () => 'Thread',
    },
    {
      icon: ICONS.thread.primary,
      route: {
        to: 'experiment_workflow_show_step_show_thread_show',
      },
      backRoute: {
        to: 'experiment_workflow_show_step_show_thread',
        params: { id: experimentWorkflow.id },
      },
      component: ({ match }) => (
        <CommentShow
          id={getParamFromActiveMatch(match, 'threadId')}
        />
      ),
      name: () => 'Thread',
    },
  ];

  const mainPage = {
    route: {
      to: 'experiment_workflow_show',
      params: { id: experimentWorkflow.id },
    },
  };

  // If active page, stay visible during transition to experiment show
  const addExperimentShow = (p) => {
    if (
      navigation.get(p.route.to) ===
      activeExperimentWorkflowRoute?.path
    ) {
      return { ...p, paths: [experimentShowRoute] };
    }
    return p;
  };

  return (
    <RightPane
      {...addExperimentShow(mainPage)}
      subPages={subPages.map((p) => addExperimentShow(p))}
      subPagesNested={subPagesNested.map((p) => addExperimentShow(p))}
    />
  );
};

export default ScreensExperimentWorkflowShowRight;
