/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader
 * @desc Default DataGrid ColumnHeader
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React, { useRef } from 'react';
import { useDoubleHeaderStyle } from './hooks';
import styles from './styles.module.scss';
import { IDataGridColumnHeaderProps } from './types';

export const DataGridColumnHeader: React.FC<
  IDataGridColumnHeaderProps
> = ({ onClick, displayName, mergeAs, icon, cornerIcon, action }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const style = useDoubleHeaderStyle(ref, mergeAs);

  // Centered styling if action only
  const isActionOnly = !displayName;

  return (
    <div
      ref={ref}
      className={classnames(styles.defaultContainer, {
        [styles.clickable]: !!onClick,
        [styles.actionContainer]: isActionOnly,
      })}
      style={style}
      onClick={onClick}
    >
      {!isActionOnly && (
        <div className={styles.title}>
          {icon && <Icon name={icon} cornerIcon={cornerIcon} />}{' '}
          {displayName}
        </div>
      )}
      {action}
    </div>
  );
};

export default DataGridColumnHeader;
