/**
 * Labstep
 *
 * @module screens/Group/Show/Center/User
 * @desc Group Users Center screen
 */

import GroupActionAddUser from 'labstep-web/components/Group/Action/AddUser';
import GroupActionShare from 'labstep-web/components/Group/Action/Share';
import ShareLinkInvitationModal from 'labstep-web/components/ShareLinkInvitation/Modal';
import UsersMultiList from 'labstep-web/components/User/List/Multi';
import UserTable from 'labstep-web/components/User/Table';
import UserGroupActionMenu from 'labstep-web/components/UserGroup/Action/Menu';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import { Group } from 'labstep-web/models/group.model';
import ScreensUserGroupMasterCenter from 'labstep-web/screens/UserGroup/Master/Center';
import { Action } from 'labstep-web/components/UserGroup/Action/Menu/types';
import React from 'react';
import Flex from 'labstep-web/core/Flex';
import { UserGroup } from 'labstep-web/models/user-group.model';
import {
  IGroupUsersMasterCenterContainerProps,
  IGroupUsersMasterCenterProps,
} from './types';

export const GroupUsersMasterCenter: React.FC<
  IGroupUsersMasterCenterProps
> = ({ group, tableFormat }) => (
  <Flex grow column>
    {group.canInvite && <ShareLinkInvitationModal entity={group} />}
    <ScreensUserGroupMasterCenter
      searchKey="search_query"
      tableFormat={tableFormat}
      searchBarProps={{
        placeholder: `Search current ${Group.getHumanReadableEntityName()} members`,
      }}
      params={{ group_id: group.id }}
      action={
        group.canInvite ? (
          <GroupActionShare
            actionComponentProps={{
              type: 'button',
            }}
            group={group}
          />
        ) : (
          <GroupActionAddUser group={group} />
        )
      }
    >
      {({ entities, ...rest }) =>
        tableFormat ? (
          <UserTable
            {...(rest as any)}
            actionMenu={({ entity }: { entity: UserGroup }) => (
              <UserGroupActionMenu
                userGroup={entity}
                actions={[Action.delete_user_group]}
              />
            )}
            userGroups={entities}
          />
        ) : (
          <UsersMultiList
            actionMenu={({ userGroup }) => (
              <UserGroupActionMenu
                userGroup={userGroup}
                actions={[Action.delete_user_group]}
              />
            )}
            userGroups={entities}
            id="group-list"
          />
        )
      }
    </ScreensUserGroupMasterCenter>
  </Flex>
);

const GroupUsersMasterCenterContainer: React.FC<
  IGroupUsersMasterCenterContainerProps
> = (props) => (
  <Mobile>
    {({ match }) => (
      <GroupUsersMasterCenter {...props} tableFormat={!match} />
    )}
  </Mobile>
);

export default GroupUsersMasterCenterContainer;
